import { observable, action, runInAction, configure } from 'mobx';
import { toast } from 'react-toastify';
import BaseStore from './BaseStore';
import api from '@API';
import { SHARE_TYPE } from '@COMMON/constant'
import { strings } from '@I18N/i18n';

configure({ enforceActions: 'always' })

class SubscribeStore extends BaseStore {
  @observable mySubscription = []

  @action getMySubscription = async () => {
    try {
      const mySubscription = await api.getMySubscription()
      runInAction(() => {
        this.mySubscription = mySubscription
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action subscripe = async (payload, toastSuccessText) => {
    try {
      const { TagId: subscribeId } = payload
      await api.subscribe(payload)
      runInAction(() => {
        this.mySubscription = [...this.mySubscription, subscribeId]
      })
      toast.success(toastSuccessText || strings('common.subscription_tip'));
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action unSubscripe = async payload => {
    try {
      const { TagId: subscribeId } = payload
      await api.unsubscribe(payload)
      runInAction(() => {
        this.mySubscription = this.mySubscription.filter(id => id !== subscribeId)
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }
}

export default new SubscribeStore()
