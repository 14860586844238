/* eslint-disable */
import moment from 'moment';

export default function initMoment(language) {
  if (moment.locale() !== language && language === 'zh') {
    moment.defineLocale('zh', {
      months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
        '_'
      ),
      monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
        '_'
      ),
      weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
      weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
      weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
      longDateFormat: {
        LT: 'Ah点mm分',
        LTS: 'Ah点m分s秒',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MMMD日',
        LLL: 'YYYY年MMMD日Ah点mm分',
        LLLL: 'YYYY年MMMD日ddddAh点mm分',
        l: 'YYYY-MM-DD',
        ll: 'YYYY年MMMD日',
        lll: 'YYYY年MMMD日Ah点mm分',
        llll: 'YYYY年MMMD日ddddAh点mm分'
      },
      meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
      meridiemHour(hour, meridiem) {
        if (hour === 12) {
          hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' || meridiem === '上午') {
          return hour;
        }
        if (meridiem === '下午' || meridiem === '晚上') {
          return hour + 12;
        }
        // '中午'
        return hour >= 11 ? hour : hour + 12;
      },
      meridiem(hour, minute, isLower) {
        const hm = hour * 100 + minute;
        if (hm < 600) {
          return '凌晨';
        }
        if (hm < 900) {
          return '早上';
        }
        if (hm < 1130) {
          return '上午';
        }
        if (hm < 1230) {
          return '中午';
        }
        if (hm < 1800) {
          return '下午';
        }
        return '晚上';
      },
      calendar: {
        sameDay() {
          return this.minutes() === 0 ? '[今天]Ah[点整]' : '[今天]LT';
        },
        nextDay() {
          return this.minutes() === 0 ? '[明天]Ah[点整]' : '[明天]LT';
        },
        lastDay() {
          return this.minutes() === 0 ? '[昨天]Ah[点整]' : '[昨天]LT';
        },
        nextWeek() {
          let startOfWeek;
          let prefix;
          startOfWeek = moment().startOf('week');
          prefix =
            this.unix() - startOfWeek.unix() >= 7 * 24 * 3600 ? '[下]' : '[本]';
          return this.minutes() === 0
            ? `${prefix}dddAh点整`
            : `${prefix}dddAh点mm`;
        },
        lastWeek() {
          let startOfWeek;
          let prefix;
          startOfWeek = moment().startOf('week');
          prefix = this.unix() < startOfWeek.unix() ? '[上]' : '[本]';
          return this.minutes() === 0
            ? `${prefix}dddAh点整`
            : `${prefix}dddAh点mm`;
        },
        sameElse: 'LL'
      },
      ordinalParse: /\d{1,2}(日|月|周)/,
      ordinal(number, period) {
        switch (period) {
          case 'd':
          case 'D':
          case 'DDD':
            return `${number}日`;
          case 'M':
            return `${number}月`;
          case 'w':
          case 'W':
            return `${number}周`;
          default:
            return number;
        }
      },
      relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        m: '1 分钟',
        mm: '%d 分钟',
        h: '1 小时',
        hh: '%d 小时',
        d: '1 天',
        dd: '%d 天',
        M: '1 个月',
        MM: '%d 个月',
        y: '1 年',
        yy: '%d 年'
      },
      week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
      }
    });
  }

  moment.locale(language);
}
