import { observable, action, runInAction, configure, computed } from 'mobx';
import { toast } from 'react-toastify';
import BaseStore from './BaseStore';
import api from '@API';
import { SHARE_TYPE } from '@COMMON/constant'

configure({ enforceActions: 'always' });

class GroupStore extends BaseStore {
  @observable groupDetail = {};

  @observable skip = 0;

  @observable perPage = 10;

  @observable canLoadFollowGroup = true

  @observable followGroupList = [];

  @observable groupP8Id = ''

  @computed get skipFollowGroupList() {
    return this.followGroupList.length
  }

  @action getFollowGroupList = async ({ mId, initial }) => {
    if (!this.canLoadFollowGroup) {
      return
    }
    const skip = initial ? 0 : this.skipFollowGroupList
    !skip ? this.dataInitialLoading() : this.dataLoading()
    try {
      const data = await api.getFollowedGroups({ skip, take: this.perPage, mId })
      runInAction(() => {
        this.followGroupList = !skip ? data : [...this.followGroupList, ...data]
        this.canLoadFollowGroup = data?.length >= this.perPage
      })
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action async getGroupDetail(gId) {
    this.dataInitialLoading();
    try {
      if (gId !== this.groupP8Id) {
        runInAction(() => {
          this.groupDetail = {}
        })
      }
      const data = await api.getGroupDetail({ gId });
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.Group)
        this.groupDetail = data;
        this.groupP8Id = gId
        this.dataSuccess();
      });
    } catch (error) {
      this.dataError();
    }
  }

  @action followGroup = async GroupIdToFollow => {
    try {
      await api.followGroup({ GroupIdToFollow });
      runInAction(() => {
        if (this.groupDetail.p8Id === GroupIdToFollow) {
          this.groupDetail.FollowingGroup = true;
          const {
            groupDetail: { FollowerNumber }
          } = this;
          this.groupDetail.FollowerNumber = FollowerNumber + 1;
        }
        this.followGroupList = this.followGroupList.map(item => (item.Institution?.Id === GroupIdToFollow ? { ...item, FollowedByMe: true } : item))
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  @action unFollowGroup = async GroupIdToUnfollow => {
    try {
      await api.unFollowGroup({ GroupIdToUnfollow });
      runInAction(() => {
        if (this.groupDetail.p8Id === GroupIdToUnfollow) {
          this.groupDetail.FollowingGroup = false;
          const {
            groupDetail: { FollowerNumber }
          } = this;
          this.groupDetail.FollowerNumber = FollowerNumber - 1;
        }
        this.followGroupList = this.followGroupList.map(item => (item.Institution?.Id === GroupIdToUnfollow ? { ...item, FollowedByMe: false } : item))
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  };
}

export default new GroupStore();
