import React, { PureComponent } from 'react'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './LoadingSpinner.scss';

export default class LoadingSpinner extends PureComponent {
  render() {
    const className = this.props.isLive ? 'LiveLoading' : 'LoadingSpinner'
    return (
      <div className={className}>
        <Loader
          type='Oval'
          color='#69E0E4'
          height='40'
          width='40'
        />
      </div>
    );
  }
}
// https://github.com/liya19/github-client/blob/36a62433d5edc30db3dcbcf237cfb6cfa5671061/src/Components/Loading.js
