import { observable, action } from 'mobx';
import { strings, getLanguage } from '@I18N/i18n'
import { SCREEN_STATUS, PIER_ICON, SHARE_TYPE } from '@COMMON/constant';
import wxShare from '@UTILS/wechat'
import { getWxSecondShareInfoData } from '@UTILS/wechatShareUtil'

export default class BaseStore {
  @observable screenStatus = '';

  @action
  dataLoading() {
    this.screenStatus = SCREEN_STATUS.LOADING;
  }

  @action
  dataInitialLoading() {
    this.screenStatus = SCREEN_STATUS.INITIAL_LOADING;
  }

  @action
  dataNull() {
    this.screenStatus = SCREEN_STATUS.NULL;
  }

  @action
  dataError() {
    this.screenStatus = SCREEN_STATUS.ERROR;
  }

  @action
  dataSuccess() {
    this.screenStatus = '';
  }

  @action
  waitingBackEnd = () => {
    // Toast.loading('Loading...', 0);
  };

  @action hideToast = () => {
    // Toast.hide();
  };

  shareDesc = {
    [SHARE_TYPE.Guide]: strings('common.share_guide_title'),
    [SHARE_TYPE.Course]: strings('common.share_course_title'),
    [SHARE_TYPE.Activity]: strings('common.share_activity_title'),
    [SHARE_TYPE.Opportunity]: strings('common.share_opportunity_title'),
    [SHARE_TYPE.Profile]: strings('common.share_profile_title'),
    [SHARE_TYPE.MedWord]: strings('common.share_word_title'),
  }

  @action setWeChatShareInfo = (data, shareType) => {
    const defaultShareInfo = {
      title: strings('common.app_name'),
      desc: this.shareDesc[shareType],
      link: window.location.href,
      imgUrl: PIER_ICON.SHARE_CARD_COVER,
      success: () => console.log('Your activity has been share successfully'),
      cancel: err => console.log('share failed', err)
    };
    const param = {
      defaultShareInfo,
      data,
      shareType,
      language: getLanguage(),
    }
    const { shareFriendsInfo, shareCircleInfo } = getWxSecondShareInfoData(param);
    wxShare(shareFriendsInfo, shareCircleInfo);
  }
}
