import appStore from './AppStore';
import authStore from './AuthStore';
import routingStore from './RoutingStore';
import userStore from './UserStore';
import feedsStore from './FeedsStore';
import connectionStore from './ConnectionStore';
import opportunityStore from './OpportunityStore';
import commentStore from './CommentStore';
import groupStore from './GroupStore';
import guideStore from './GuideStore';
import courseStore from './CourseStore';
import subjectUserStore from './SubjectUserStore'
import conferenceStore from './ConferenceStore'
import medWordStore from './MedWordStore'
import customAcademyStore from './CustomAcademyStore'
import eventStore from './EventStore'
import subscribeStore from './SubscribeStore'
import bookmarkStore from './BookmarkStore'
import categoryStore from './CategoryStore'
import medicineStore from './MedicineStore'
import secondCategoryStore from './SecondCategoryStore'
// import measure from './measure';
// import entity from './entity';
// import org from './org';
// import score from './score';
// import manager from './manager';
// import resultmanager from './result_manager';
// import resultteam from './result_team';
// import generate from './generate';
// import scan from './scan';
// import check from './check';
// import report from './report';

const store = {
  appStore,
  authStore,
  routingStore,
  userStore,
  feedsStore,
  connectionStore,
  opportunityStore,
  commentStore,
  groupStore,
  guideStore,
  courseStore,
  subjectUserStore,
  conferenceStore,
  medWordStore,
  customAcademyStore,
  eventStore,
  subscribeStore,
  bookmarkStore,
  categoryStore,
  secondCategoryStore,
  medicineStore,
  // measure,
  // entity,
  // org,
  // score,
  // manager,
  // resultmanager,
  // resultteam,
  // generate,
  // scan,
  // check,
  // report
};

export default store;
