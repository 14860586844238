/*
console.log('DBCONFIG ', process.env.Default_Activity_ID);
console.log('DBCONFIG ', process.env.API_HOST);
console.log('DBCONFIG ', process.env.OSS_HOST);
console.log('DBCONFIG ', process.env.OSS_BUCKET);
console.log('DBCONFIG ', process.env.OSS_ENDPOINT);
*/
const { OSS_HOST, VIDEO_OSS_HOST, OSS_BUCKET, OSS_ENDPOINT, OSS_VIDEO_BUCKET, BAIDU_ANALY_ID, UMENG_ANALY_ID } = process.env;

export default {
  baseUrl: process.env.API_HOST,
  ossHost: OSS_HOST,
  staticMedWordAudioUrl: `${OSS_HOST}/static/audio/medword/`,
  staticMedWordImageUrl: `${OSS_HOST}/static/img/medword/`,
  staticImgUrl: `${OSS_HOST}/static/img/certificates/`,
  staticAvatarUrl: `${OSS_HOST}/static/img/users/`,
  staticChatImageUrl: `${OSS_HOST}/static/img/chat/`,
  staticPostImageUrl: `${OSS_HOST}/static/img/post/`,
  staticGroupImageUrl: `${OSS_HOST}/static/img/groups/`,
  staticShare: `${OSS_HOST}/static/img/share/`,
  staticGroupDetailImageUrl: `${OSS_HOST}/static/img/groups/image/`,
  staticGroupDetailVideoUrl: `${OSS_HOST}/static/img/groups/video/`,
  staticPdfUrl: `${OSS_HOST}/static/docs/guide/`,
  staticAcademyIcon: `${OSS_HOST}/static/img/icon/`,
  staticOpportunity: `${OSS_HOST}/static/img/opportunity/`,
  courseVideoUrl: `${VIDEO_OSS_HOST}/course/video/`,
  courseVideoPDFUrl: `${VIDEO_OSS_HOST}/course/docs/video/`,
  coursePreviewUrl: `${VIDEO_OSS_HOST}/course/preview/`,
  ossBucket: OSS_BUCKET,
  ossVideoBucket: OSS_VIDEO_BUCKET,
  ossEndPoint: OSS_ENDPOINT || 'https://oss-cn-hangzhou.aliyuncs.com',
  defaultActivityId: process.env.Default_Activity_ID,
  staticBanner: `${OSS_HOST}/static/img/banner/`,
  shareInviteIcon: 'http://prodimg.p88health.com/static/img/share/wechat.png',
  baiduAnalyId: BAIDU_ANALY_ID,
  umengAnalyId: UMENG_ANALY_ID
};
