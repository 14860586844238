import { observable, action, runInAction, autorun } from 'mobx';
import { APP_TOKEN_KEY } from '@COMMON/constant';
import appStore from './AppStore';
import routingStore from './RoutingStore';
import { parserParams } from '@UTILS/dingtalk';
import api from '@API';

class UserStore {
  @observable currentUser;

  @observable loadingUser;

  @observable updatingUser;

  @observable updatingUserErrors;

  constructor() {
    autorun(() => {
      let isSameAppkey = true
      if (window.location.search && window.location.search.indexOf('appkey') > 0) {
        const params = parserParams(window.location.search);
        isSameAppkey = params.appkey === appStore.userAppKey
      }

      if (appStore.token && isSameAppkey) {
        this.pullUser();
      }
    });
  }

  @action setCurrentUser(data) {
    this.currentUser = data;
  }

  @action async pullUser() {
    this.loadingUser = true;
    const { pathname } = window.location
    const disabledLogin = pathname.indexOf('fileCareView') > 0
    if (disabledLogin) return
    try {
      const data = await api.getCurrentUser()
      runInAction(() => {
        this.currentUser = data;
        appStore.setPermission(data?.UserContext?.PermissionsHash)
        appStore.setBindPhone(
          data?.UserContext?.MemberRoleInGroup !== 'AnonymousMember'
        );
        appStore.setAppLoaded()
      });
    } catch (err) {
      runInAction(() => {
        appStore.clearToken()
        routingStore.push('/login');
      });
    } finally {
      runInAction(() => {
        this.loadingUser = false;
      });
    }
  }

  // @action
  // getUserInfo = () => {
  //   const token = window.localStorage.getItem(APP_TOKEN_KEY)
  //   console.log('read token', token)
  //   if (token) {
  //     return;
  //   }
  //   this.loadingUser = true;
  //   api.getCurrentUser()
  //     .then(
  //       action('fetchSuccess', data => {
  //         console.log(data)
  //         this.currentUser = data
  //         appStore.setBindPhone(data?.UserContext?.MemberRoleInGroup === 'AnonymousMembe')
  //       }),
  //       action('fetchError', err => {
  //         console.log('Fetch error', err)
  //         this.error = {
  //           message: err
  //         }
  //       })
  //     )
  //     .catch(
  //       action(err => {
  //         console.log('login error', err)
  //         this.error = {
  //           message: err.message
  //         }
  //         // throw err;
  //       })
  //     )
  //     .finally(
  //       action(() => {
  //         this.loadingUser = false;
  //       })
  //     );
  // };

  // @action updateUser(newUser) {
  //   this.updatingUser = true;
  //   return api.save(newUser)
  //     .then(action(({ user }) => { this.currentUser = user; }))
  //     .finally(action(() => { this.updatingUser = false; }))
  // }

  @action forgetUser() {
    this.currentUser = undefined;
  }
}

export default new UserStore();
