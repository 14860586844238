import { observable, action, runInAction, configure, computed } from 'mobx';
import api from '@API';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' });

class MedicineStore extends BaseStore {
  @observable medicines = [];

  @observable medicineDetail = null;

  @observable text = '[]';

  @observable canLoadCategories = true;

  @observable canLoadMedicines = true;

  @observable perPage = 15;


  @computed get skipMedicines() {
    return this.medicines.length;
  }

  @action resetMedicinesSt = () => {
    if (this.st) {
      this.st = ''
      this.medicines = [];
      this.canLoadMedicines = true;
    }
  };

  @action resetMedicines = st => {
    this.medicines = [];
    this.canLoadMedicines = true;
  };

  @action setParamsText = text => {
    this.dataInitialLoading();
  };

  @action searchMedicines = (payload, st) => {
    this.st = st;
    this.canLoadMedicines = true;
    this.getMedicines(true, true, payload);
  };

  @action getMedicines = async (isSearch, initial, payload = {}) => {
    try {
      if (!this.canLoadMedicines) return;

      const skip = initial ? 0 : this.skipMedicines;
      !skip ? this.dataInitialLoading() : this.dataLoading();
      const fetchApi = isSearch ? api.searchMedicine : api.getMedicineByCategoryId;
      const data = await fetchApi({ skip, take: this.perPage, ...payload, st: this.st });
      const { Medicines = [] } = data;
      runInAction(() => {
        this.medicines = !skip ? Medicines : [...this.medicines, ...Medicines];
        this.canLoadMedicines = Medicines?.length >= this.perPage;
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  };

  @action getMedicineDetail = async mId => {
    try {
      this.dataInitialLoading();
      const data = await api.getMedicineDetail({ mId });
      runInAction(() => {
        this.medicineDetail = data;
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  };
}

export default new MedicineStore();
