import axios from 'axios';
import { http } from '@pier88health/p8h-common';
import DBConfig from '@CONFIG';
import { getToken } from '@UTILS/authority';
// const completeUrl = `/svc${url}`;
// const completeUrl = `http://localhost:8888/svc${url}`;
http.init({
  // eslint-disable-next-line no-template-curly-in-string
  // baseUrl: 'http://localhost:8888/svc',
  baseUrl: `${DBConfig.baseUrl}/svc`,
  axios,
  errorKey: 'error',
  getToken: () => getToken()
});
const { fetchRequest } = http;

const apiService = {
  loginH5: fetchRequest('/User/H5Login', 'POST'),
  dingLogin: fetchRequest('/PubSts/DingTalkLogin', 'GET'),
  getWeChatSignature: fetchRequest('/PubSts/GetWeChatSignature', 'GET'),
  getRequestContact: fetchRequest('/Application/RequestContact', 'GET'),
  getUserAward: fetchRequest('/AwardAdmin/GetAwardSummary', 'GET'),

  getZoomusSignature: fetchRequest('/PubSts/GetZoomusSignature', 'GET'),

  getCurrentUser: fetchRequest('/Profile/LandingProfile', 'GET'),
  existFeaturedItems: fetchRequest('/Profile/ExistFeaturedItems', 'GET'),
  getGroupDetail: fetchRequest('/Group/GetGroup', 'GET'),

  requestAuthCodeBind: fetchRequest(
    '/Profile/RequestAuthCodePhoneBind',
    'POST'
  ),
  inviteRequestAuthCode: fetchRequest('/User/RequestAuthCode', 'POST'),
  inviteSignUpByPhone: fetchRequest('/User/SignUpByPhone', 'POST'),
  viewVoucher: fetchRequest('/User/ViewVoucher', 'GET'),
  loginOrRegisterByPhone: fetchRequest('/User/LoginOrRegisterByPhone', 'POST'),
  updateUserFeedLanguages: fetchRequest('/Profile/UpdateUserFeedLanguages', 'POST'),
  loginOrRegisterByEmail: fetchRequest('/User/LoginOrRegisterByEmail', 'POST'),

  logout: fetchRequest('/User/Logout', 'POST'),
  authCode: fetchRequest('/User/ReqSelfRegByPhone', 'POST'),
  registerUserByActionTokenId: fetchRequest(
    '/User/RegisterUserByActionTokenId',
    'GET'
  ),
  getUser: fetchRequest('/User/GetUser', 'POST'),

  getSpecialties: fetchRequest('/Specialty/GetSpecialties', 'GET'),
  getSubSpecialties: fetchRequest('/Specialty/GetSubSpecialties', 'GET'),
  getDepartments: fetchRequest('/DepartmentLookup/GetDepartments', 'GET'),
  getDiseaseList: fetchRequest('/Topic/Lookup', 'GET'),
  getSubDepartments: fetchRequest('/DepartmentLookup/GetSubDepartments', 'GET'),
  getHospitals: fetchRequest('/GroupLookup/GetHospitals', 'GET'),
  getSchools: fetchRequest('/GroupLookup/GetSchools', 'GET'),
  getPositions: fetchRequest('/User/GetPositionOptions', 'GET'),
  getChinaDegrees: fetchRequest('/User/GetChinaDegrees', 'GET'),
  getUSADegrees: fetchRequest('/User/GetUSADegrees', 'GET'),
  getMyRepGroup: fetchRequest('/Opportunity/GetMyRepGroup', 'GET'),
  getEventsListBySpotCode: fetchRequest('/OpEvent/GetEventsListBySpotCode', 'GET'),
  getPubEventsListBySpotCode: fetchRequest('/PubOpEvent/GetEventsListBySpotCode', 'GET'),

  getNewsFeed: fetchRequest('/FeedItem/GetNewsFeed', 'GET'),
  getMainFeed: fetchRequest('/FeedItem/GetMainFeed', 'GET'),
  getH5MainFeed: fetchRequest('/FeedItem/GetH5MainFeed', 'GET'),
  getNewsFeedByTagId: fetchRequest('/FeedItem/GetNewsFeedByTagId', 'GET'),
  getFeedItemDetails: fetchRequest('/FeedItem/GetFeedItemDetails', 'GET'),
  getItemsBySubjectuserMId: fetchRequest('/FeedItem/GetItemsBySubjectuserMId', 'GET'),
  getCoursesBySubjectuserMId: fetchRequest('/Course/GetCoursesBySubjectuserMId', 'GET'),
  getGuidesBySubjectuserMId: fetchRequest('/Guide/GetGuidesBySubjectuserMId', 'GET'),
  submitReport: fetchRequest('/FeedItem/Report', 'POST'),

  getLikesGroup: fetchRequest('/FeedItem/GetLikes', 'GET'),
  likePost: fetchRequest('/FeedItem/Like', 'POST'),
  unLikePost: fetchRequest('/FeedItem/Unlike', 'POST'),
  commentPost: fetchRequest('/FeedItem/Comment', 'POST'),
  sharePost: fetchRequest('/FeedItem/Share', 'POST'),
  shareToChatSessions: fetchRequest('/FeedItem/ShareToChatSessions', 'POST'),
  shareWithFriends: fetchRequest('/FeedItem/ShareWithFriends', 'POST'),
  translate: fetchRequest('/FeedItem/Translate', 'POST'),
  shareTranslate: fetchRequest('/FeedItem/ShareTranslate', 'POST'),
  deleteMyComment: fetchRequest('/Comment/DeleteMyComment', 'POST'),
  commentTranslate: fetchRequest('/Comment/Translate', 'POST'),
  getComments: fetchRequest('/FeedItem/GetComments', 'GET'),
  getActivities: fetchRequest('/FeedItem/GetMemberActivity', 'GET'),
  getFriendNumber: fetchRequest('/Connection/GetFriendNumber', 'GET'),
  getAwardSummary: fetchRequest('/Award/GetAwardSummary', 'GET'),
  getGroupOpportunities: fetchRequest(
    '/Opportunity/GetGroupOpportunities',
    'GET'
  ),
  getPublicationSummary: fetchRequest('/Publication/GetPublicationSummary', 'GET'),
  getGroupNewsFeed: fetchRequest('/FeedItem/GetGroupNewsFeed', 'GET'),

  followGroup: fetchRequest('/Connection/FollowGroup', 'POST'),
  unFollowGroup: fetchRequest('/Connection/UnfollowGroup', 'POST'),
  getSummary: fetchRequest('/Connection/GetSummary', 'GET'),
  searchConnection: fetchRequest('/Connection/Search', 'GET'),
  follow: fetchRequest('/Connection/Follow', 'POST'),
  followTopic: fetchRequest('/Connection/FollowTopic', 'POST'),
  unfollow: fetchRequest('/Connection/Unfollow', 'POST'),
  getFollowers: fetchRequest('/Connection/GetFollowers', 'GET'),
  getFollowees: fetchRequest('/Connection/GetFollowees', 'GET'),
  isFollowedByMe: fetchRequest('/Connection/IsFollowedByMe', 'GET'),
  getFriends: fetchRequest('/Connection/GetFriends', 'GET'),
  addFriend: fetchRequest('/Connection/AddFriend', 'POST'),
  delFriend: fetchRequest('/Connection/Defriend', 'POST'),
  getMyFriendRequest: fetchRequest('/Connection/GetMyFriendRequest', 'GET'),
  accept: fetchRequest('/Connection/Accept', 'POST'),
  ignore: fetchRequest('/Connection/Ignore', 'POST'),
  skipFriends: fetchRequest('/Connection/Skip', 'POST'),
  block: fetchRequest('/Connection/Block', 'POST'),
  unblock: fetchRequest('/Connection/Unblock', 'POST'),
  getFollowSuggests: fetchRequest('/Connection/GetFollowSuggests', 'GET'),
  getFriendSuggests: fetchRequest('/Connection/GetFriendSuggests', 'POST'),
  subscribeCourse: fetchRequest('/Connection/Subscribe', 'POST'),
  checkFriendsByPhoneContacts: fetchRequest(
    '/Connection/CheckFriendsByPhoneContacts',
    'POST'
  ),

  getProfile: fetchRequest('/Profile/GetUserProfile', 'GET'),
  getLandingProfile: fetchRequest('/Profile/LandingProfile', 'GET'),
  updateMyProfile: fetchRequest('/Profile/UpdateMyProfile', 'POST'),
  updateName: fetchRequest('/Profile/SetMyFullName', 'POST'),
  updateUserType: fetchRequest('/Profile/SetMyUserType', 'POST'),
  setMyAffliation: fetchRequest('/Profile/SetMyAffliation', 'POST'),
  setMySchool: fetchRequest('/Profile/SetMySchool', 'POST'),
  deleteMyCertificate: fetchRequest('/Profile/DeleteMyCertificate', 'POST'),
  setInAppStatus: fetchRequest('/Profile/SetInAppStatus', 'POST'),
  verifyUserPassword: fetchRequest('/Profile/VerifyUserPassword', 'POST'),
  resetUserPassword: fetchRequest('/Profile/ResetUserPassword', 'POST'),

  saveEducation: fetchRequest('/Experience/SaveEducation', 'POST'),
  saveProfession: fetchRequest('/Experience/SaveProfession', 'POST'),
  removeEducation: fetchRequest('/Experience/RemoveEducation', 'POST'),
  removeProfession: fetchRequest('/Experience/RemoveProfession', 'POST'),

  getUserEducations: fetchRequest('/Experience/GetUserEducations', 'GET'),
  getUserProfessions: fetchRequest('/Experience/GetUserProfessions', 'GET'),
  getEducation: fetchRequest('/Experience/GetEducation', 'GET'),
  getProfession: fetchRequest('/Experience/GetProfession', 'GET'),

  enterSessionById: fetchRequest('/Chat/EnterSessionById', 'POST'),
  enterSessionByMemberIds: fetchRequest(
    '/Chat/EnterSessionByMemberIds',
    'POST'
  ),
  createMessage: fetchRequest('/Chat/CreateMessage', 'POST'),
  getMessages: fetchRequest('/Chat/GetMessages', 'GET'),
  getSessions: fetchRequest('/Chat/GetSessions', 'GET'),
  clearHistory: fetchRequest('/Chat/ClearHistory', 'POST'),
  addParticipants: fetchRequest('/Chat/AddParticipants', 'POST'),
  removeParticipants: fetchRequest('/Chat/RemoveParticipants', 'POST'),
  muteSession: fetchRequest('/Chat/MuteSession', 'POST'),
  unmuteSession: fetchRequest('/Chat/UnmuteSession', 'POST'),
  leaveSession: fetchRequest('/Chat/LeaveSession', 'POST'),
  searchMembersToAdd: fetchRequest('/Chat/SearchMembersToAdd', 'GET'),
  getMembersToAdd: fetchRequest('/Chat/GetMembersToAdd', 'GET'),

  getUptodateCredential: fetchRequest('/Member/GetUptodateCredential', 'GET'),

  getBellAlerts: fetchRequest('/Notification/GetBellAlerts', 'GET'),
  getMyOutstandingNumbers: fetchRequest(
    '/Profile/GetMyOutstandingNumbers',
    'GET'
  ),
  archiveBellAlert: fetchRequest('/Notification/ArchiveBellAlert', 'POST'),

  submitAppFeedback: fetchRequest('/AppFeedback/SubmitFeedback', 'POST'),


  getOpportunityTypes: fetchRequest('/Opportunity/GetOpportunityTypes', 'GET'),
  createOpportunity: fetchRequest('/Opportunity/Save', 'POST'),
  getMyOpportunities: fetchRequest('/Opportunity/GetMyOpportunities', 'GET'),
  getOpportunityDetails: fetchRequest('/Opportunity/GetOpportunityDetails', 'GET'),
  getOpportunityFeed: fetchRequest('/Opportunity/GetOpportunityFeed', 'GET'),
  getOpportunitySuggests: fetchRequest(
    '/Opportunity/GetOpportunitySuggests',
    'GET'
  ),

  getOutbounds: fetchRequest('/Application/GetOutbounds', 'GET'),
  getInbounds: fetchRequest('/Application/GetInbounds', 'GET'),
  getApplicationDetails: fetchRequest(
    '/Application/GetApplicationDetails',
    'GET'
  ),
  getDraftOpportunity: fetchRequest('/Opportunity/GetDraftOpportunity', 'GET'),
  // recycleOpportunity: fetchRequest('/Opportunity/Recycle', 'POST'),
  publishOpportunity: fetchRequest('/Opportunity/Publish', 'POST'),
  submitApplication: fetchRequest('/Application/Submit', 'POST'),
  getApplicantComments: fetchRequest('/Application/GetComments', 'GET'),
  addApplicantComment: fetchRequest('/Application/Comment', 'POST'),
  makeOffer: fetchRequest('/Application/MakeOffer', 'POST'),
  repDecline: fetchRequest('/Application/RepDecline', 'POST'),
  getDeclineReasons: fetchRequest('/Application/GetDeclineReasons', 'Get'),
  getDeclineOfferReasons: fetchRequest(
    '/Application/GetDeclineOfferReasons',
    'Get'
  ),
  acceptOffer: fetchRequest('/Application/AcceptOffer', 'POST'),
  declineOffer: fetchRequest('/Application/DeclineOffer', 'POST'),
  requestOssToken: fetchRequest('/Sts/RequestOssToken', 'GET'),
  getSpecialtyList: fetchRequest('/Specialty/GetSpecialtiesByName', 'GET'),
  getDepartmentsAndSubDepartments: fetchRequest(
    '/DepartmentLookup/GetDepartmentsAndSubDepartmentsByName',
    'GET'
  ),
  getDepartmentsByName: fetchRequest(
    '/DepartmentLookup/GetDepartmentsByName',
    'GET'
  ),
  getIcdList: fetchRequest('/Icd/GetIcdDataByName', 'GET'),
  getFakeUsers: fetchRequest('/Impersonate/GetFakeUsers', 'GET'),
  getCoursewareTopics: fetchRequest('/Topic/GetCoursewareTopics', 'GET'),
  getGuideDetail: fetchRequest('/Guide/GetGuide', 'GET'),
  getGuideList: fetchRequest('/Guide/GetGuideList', 'GET'),
  getCourses: fetchRequest('/Course/GetCourses', 'GET'),
  getCourse: fetchRequest('/Course/Browse', 'GET'),
  getRelatedCourses: fetchRequest('/Course/GetRelatedCourses', 'GET'),
  getSummaryById: fetchRequest('/PubLearn/GetSummaryById', 'GET'),
  getFeaturedDepartments: fetchRequest('/Guide/GetFeaturedDepartments', 'GET'),
  getSecondaryDeptTags: fetchRequest('/Guide/GetSecondaryDeptTags', 'GET'),
  getGuidesByTag: fetchRequest('/Guide/GetGuidesByTag', 'GET'),
  addBookmark: fetchRequest('/Bookmark/AddBookmark', 'POST'),
  unbookmark: fetchRequest('/Bookmark/Unbookmark', 'POST'),
  getBookmarks: fetchRequest('/Bookmark/GetBookmarks', 'GET'),
  countRelevantDataByTagId: fetchRequest('/Tag/CountRelevantDataByTagId', 'GET'),
  getTagDetails: fetchRequest('/Tag/GetTagDetails', 'GET'),
  getFollowedGroups: fetchRequest('/Connection/GetFollowedGroups', 'GET'),
  getFollowedTopics: fetchRequest('/Connection/GetFollowedTags', 'GET'),
  followTag: fetchRequest('/Connection/FollowTag', 'POST'),
  unFollowTag: fetchRequest('/Connection/UnfollowTag', 'POST'),
  getRelatedMedWords: fetchRequest('/MedWord/GetRelatedMedWords', 'GET'),
  getMedWordDetail: fetchRequest('/MedWord/GetMedWordDetail', 'GET'),
  getDailyMedWords: fetchRequest('/MedWord/GetDailyMedWords', 'GET'),

  onboardEnterpriseUsers: fetchRequest('/Provision/OnboardEnterpriseUsers', 'POST'),
  getRelatedOpportunities: fetchRequest('/Opportunity/GetRelatedOpportunities', 'GET'),
  getTagDetailByName: fetchRequest('/Tag/GetTagDetailByName', 'GET'),
  getOpportunitiesByTagId: fetchRequest('/Opportunity/GetOpportunitiesByTagId', 'GET'),

  getUserCreatedAndSharedFeeds: fetchRequest('/FeedItem/GetUserCreatedAndSharedFeeds', 'GET'),
  getUserActivity: fetchRequest('/FeedItem/GetUserActivity', 'GET'),
  getGroupRankings: fetchRequest('/Group/GetGroupRankings', 'GET'),
  getMeetupDetails: fetchRequest('/Meetup/GetMeetupDetails', 'GET'),
  getMeetups: fetchRequest('/Meetup/GetMeetups', 'GET'),
  getAcademy: fetchRequest('/Academy/GetAcademy', 'GET'),
  subscribe: fetchRequest('/Connection/Subscribe', 'POST'),
  unsubscribe: fetchRequest('/Connection/Unsubscribe', 'POST'),
  getMySubscription: fetchRequest('/Connection/GetMySubscription', 'GET'),
  getAcademyExperts: fetchRequest('/Academy/GetAcademyExperts', 'GET'),
  getCourseByAllTagIds: fetchRequest('/Course/GetCourseByAllTagIds', 'POST'),
  getGuidesByAllTagIds: fetchRequest('/Guide/GetGuidesByAllTagIds', 'POST'),
  getNewsFeedByAllTagIds: fetchRequest('/FeedItem/GetNewsFeedByAllTagIds', 'POST'),
  getExpertArticles: fetchRequest('/Academy/GetExpertArticles', 'POST'),
  // medicine
  getMedicineDetail: fetchRequest('/Medicine/GetMedicineDetail', 'GET'),
  getPrimaryMedCategory: fetchRequest('/Medicine/GetPrimaryMedCategory', 'GET'),
  getSecondMedCategoryByPid: fetchRequest('/Medicine/GetSecondMedCategoryByPid', 'GET'),
  getMedicineByCategoryId: fetchRequest('/Medicine/GetMedicineByCategoryId', 'GET'),
  searchMedicine: fetchRequest('/Medicine/MedicineSearch', 'GET'),
  play: fetchRequest('/Course/Play', 'POST'),
};

export default apiService;
