/* eslint-disable */
import moment from 'moment';
import { dataFetch, date as dateUtil } from '@pier88health/p8h-common'
import { strings } from '@I18N/i18n';
import initMoment from './initMoment';
import { KOL_TYPE, LanguageEnum, LIVE_SUB_BROAD_CAST_TYPE, COURSE_FEATURE_TYPE } from '@COMMON/constant';

export  const getSkipNumber = (currentPage, take) => (currentPage - 1) * take;

export function addZeroForDate(m) {
  if (m < 10) return `0${m}`
  return `${m}`
}

export function GetRequest(search) {
  const url = search; //获取url中"?"符后的字串
  const theRequest = new Object();
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}

export function getNameFromObj(obj, language = 'zh') {
  if (obj && obj.Name) {
    return dataFetch.getObjProperty(obj.Name, language)
  }
  return '';
}

export function dateFormat(time, language) {
  initMoment(language);
  const date = new Date(time);
  const nowDate = moment().date();
  const prevDate = moment(date).date();
  const now = moment().valueOf();
  const prev = moment(date).valueOf();
  if (now - prev < 86400000 && nowDate === prevDate) {
    // 同一天，显示时分
    return dateUtil.dateFormat(time, 'hh:mm');
  }
  if (now - prev > 604800000) {
    // 超过7天
    return dateUtil.getFormatDate(time, language)
  }
  // 1天到7天前
  return moment(date)
    .startOf('hour')
    .fromNow();
}

export function renderCount(data, language) {
  if (!data) return
  const count = data.toString()
  if (count === '') {
    return ''
  }
  const len = count.length
  if (len <= 3) {
    return count
  }
  return `${(count / 1000).toFixed(1)}K`
}

export function startEndDateFormat(startDate, endDate, language) {
  let startTime = '';
  let endTime = '';
  if (language === 'en') {
    const startDt = new Date(startDate).toUTCString();
    const endDt = new Date(endDate).toUTCString();
    startTime = `${startDt.slice(5, 11)}, ${startDt.slice(12, 16)}`;
    endTime = `${endDt.slice(5, 11)}, ${endDt.slice(12, 16)}`;
  } else {
    startTime = dateUtil.dateFormat(startDate, 'yyyy/MM/dd');
    endTime = dateUtil.dateFormat(endDate, 'yyyy/MM/dd');
  }
  return `${startTime} - ${endTime}`;
}

export function i18nAddressFormat({ country, state, city }) {
  let text = strings('common.addressFormat', {
    country: country || '',
    state: state || '',
    city: city || ''
  });
  text = text.replace(/[ ,]{2}/g, ' ,').replace(/^[ ,]*|[ ,]*$/g, '');
  return text;
}

export function getUrl(str, jumpDirect) {
  const reg = /(http:\/\/|https:\/\/|Http:\/\/|Https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  if (jumpDirect) {
    return str.replace(reg, "<a href='$1$2'>$1$2</a>");
  }
  return str.replace(reg, "<a data-href='$1$2' class='articleExternalLink'>$1$2</a>");
}

export function isKolUser(identities) {
  const  isKOL = Array.isArray(identities) && !!identities.length && identities[0]?.Type === KOL_TYPE.KOL;
  const  IconFileName = isKOL?identities[0]?.IconFileName:'';
  return{
    isKOL,
    IconFileName
  }
}

export const renderCountNumber = (count, language) => {
  if (Number.isNaN(count) || !count) {
    return 0
  }
  if (count <= 1000) {
    return count
  }
  if (count < 10000) {
    return `${Math.floor(count / 1000)},${String(count).substring(1)}`
  }
  if (language === 'zh') {
    return `${parseInt(count / 10000, 10)}万`
  }
  return `${(count / 1000).toFixed(1)}K`
}

export const getDateTime = ({ start, end, isHourMinuteSelected = false, language }) => {
  const startDate = dateUtil.getFormatDate(start, language)
  const endDate = dateUtil.getFormatDate(end, language)
  return isHourMinuteSelected
    ? `${startDate} ${dateUtil.dateFormat(start, 'hh:mm')} - ${dateUtil.dateFormat(end, 'hh:mm')}`
    : `${startDate} - ${endDate}`
}

export function checkCourseStatus({ type, start, end, fileName, isProtected }) {
  const currentTime = new Date().getTime()
  const isBroadCast = type === LIVE_SUB_BROAD_CAST_TYPE.Live
  const isFeatured = end ? isProtected && currentTime > end : isProtected
  if (isFeatured && !isBroadCast) return COURSE_FEATURE_TYPE.Feature

  if (isBroadCast) {
    if (start > currentTime) return LIVE_SUB_BROAD_CAST_TYPE.Upcoming
    if (currentTime > end) return LIVE_SUB_BROAD_CAST_TYPE[!fileName ? 'Playback' : 'Video']
    return LIVE_SUB_BROAD_CAST_TYPE.Live
  }
  return LIVE_SUB_BROAD_CAST_TYPE.Video
}

export function renderLiveTime(date, language) {
  initMoment(language);
  if (!date) return null
  const weeks = moment.weekdaysShort()
  const months = moment.monthsShort()
  const current = new Date(date)
  const week = weeks[current.getDay()]
  const month = months[current.getMonth()]
  const day = current.getDate()
  const hourAndMin = moment(date).format('HH:mm')
  const isChina = current.getTimezoneOffset() === -480
  return `${strings('academy.upcoming_time', { week, month, day, hourAndMin })}${isChina ? '' : ' UTC+8'}`
}

export function renderVideoTime(seconds = 0) {
  const sec = seconds % 60
  const min = parseInt(seconds / 60, 10) % 60
  const hour = parseInt(seconds / 60 / 60, 10)
  return `${addZeroForDate(hour)}:${addZeroForDate(min)}:${addZeroForDate(sec)}`
}
