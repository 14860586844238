import { observable, action, computed, runInAction, configure } from 'mobx';
import { toast } from 'react-toastify';
import api from '@API';
import { FEATURED } from '@COMMON/constant';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' })

class EventStore extends BaseStore {
  @observable perPage = 10;

  @observable canLoadMoreHotTopic = true;

  @observable homeHotTopicList = [];

  @computed get skipHotTopic() {
    return this.homeHotTopicList.length;
  }

  @action getHotTopicList = async initial => {
    try {
      const canLoadMore = initial ? true : this.canLoadMoreHotTopic
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipHotTopic
      skip ? this.dataLoading() : this.dataInitialLoading();
      const dataList = await api.getEventsListBySpotCode({
        code: FEATURED.H5HotTopic,
        take: this.perPage,
        skip,
      })
      runInAction(() => {
        this.homeHotTopicList = skip === 0 ? dataList : [...this.homeHotTopicList, ...dataList]
        this.canLoadMoreHotTopic = dataList.length >= this.perPage
      })
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }
}

export default new EventStore();
