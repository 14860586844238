import { observable, action, runInAction } from 'mobx';
import BaseStore from './BaseStore';
import api from '@API';

class CommentStore extends BaseStore {
  @observable perPage = 20;

  @observable total = 0;

  @observable feedComments = [];

  @observable canLoadMore = true;

  @observable skip = 0;

  @action clearComments() {
    this.canLoadMore = true;
    this.feedComments = [];
    this.skip = 0;
  }

  @action async comment(params) {
    try {
      this.waitingBackEnd();
      const data = await api.commentPost(params);
      runInAction(() => {
        this.hideToast();
        this.total = this.total + 1;
        this.feedComments = [data, ...this.feedComments];
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action async deleteMyComment(CommentId) {
    try {
      await api.deleteMyComment({ CommentId });
      runInAction(() => {
        this.total = this.total - 1;
        this.feedComments = this.feedComments.filter(
          item => item.p8Id !== CommentId
        );
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action async getFeedComments(fId) {
    try {
      if (!this.canLoadMore) {
        return;
      }
      this.skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Comments } = await api.getComments({
        skip: this.skip,
        take: this.perPage,
        fId
      });
      runInAction(() => {
        this.feedComments = this.skip === 0 ? Comments : [...this.feedComments, ...Comments];
        this.total = Total;
        this.skip = this.feedComments.length;
        this.canLoadMore = this.feedComments.length < Total;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.dataSuccess();
    }
  }

  @action async commentTranslate(params) {
    try {
      const { CommentId, LangCode } = params;
      const data = await api.commentTranslate({
        CommentId,
        LangCode
      });
      runInAction(() => {
        this.feedComments = this.feedComments.map(item => {
          if (item.p8Id === CommentId) {
            return Object.assign(item, data);
          }
          return item;
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default new CommentStore();
