import DBConfig from '@CONFIG';

export const APP_TOKEN_KEY = 'pier-admin-token';
export const USER_APP_KEY = 'pier-app-key';
export const PERMISSION_KEY = 'pier-permssion'
export const DownloadUrl = 'https://itunes.apple.com/cn/app/id1440367223';
export const DownAndroidLoadUrl = 'https://sj.qq.com/myapp/detail.htm?apkName=com.pier88health.app';
export const OpenQQAppUrl = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.pier88health.app';
export const DownLoadAppUrlAndroid = 'https://pier-prod.oss-cn-hangzhou.aliyuncs.com/static/package/MedLinc-production_release.apk';

export const PASSWORD_REG = /^[!@#$%^&*()_+-={},./;'<>~`?/0-9A-Za-z]{6,16}$/

export const PERMISSION_TYPE = {
  ViewNewsFeed: 'NewsFeed',
  ViewOpportunity: 'ViewOpportunity',
  ViewCourse: 'ViewCourse',
  ViewGuide: 'ViewGuide',
  PhoneBind: 'PhoneBind',
  SwitchH5Module: 'SwitchH5Module',
  ViewConference: 'ViewConference',
  ViewMedEnglish: 'ViewMedEnglish',
  ViewCustomAcademy: 'ViewCustomAcademy',
}

export const SOURCE = {
  m: 'm',
  care: 'care',
}

export const FEED_LANGUAGES = {
  International: 'International',
  Chinese: 'Chinese',
  English: 'English',
}

export const Country = {
  USA: 'USA',
  CHINA: 'China'
};

export const CREATOR_TYPE = {
  Individual: 'Individual',
  Institution: 'Institution'
};

export const UserTypeEnums = {
  Physician: 'Physician',
  Student: 'Student',
  Pharmacist: 'Pharmacist',
  Recruiter: 'Recruiter',
  HospitalManagement: 'HospitalManagement',
  Other: 'Other'
};

export const UserStatusEnums = {
  PendingVerify: 'PendingVerify',
  Registered: 'Registered',
  Tentative: 'Tentative',
  Verified: 'Verified',
  FailedVerify: 'FailedVerify'
};

export const ContentStatusEnums = {
  Reported: 'Reported',
  Blocked: 'Blocked',
  Active: 'Active'
};

export const LanguageEnum = {
  zh: 'zh',
  en: 'en',
  und: 'und'
};

export const SEARCH_SELECT_TYPES = {
  TOPIC: 'Topic',
  USER: 'User',
  GROUP: 'Group',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  SPECIALITY: 'SPECIALITY',
  ICDIDS: 'ICDIDS',
  DEPARTMENT: 'DEPARTMENT',
  COURSEWARETOPIC: 'CourseWareTopic',
  HOSPITAL: 'HOSPITAL',
  MEMBER: 'Member',
};

export const SCREEN_STATUS = {
  INITIAL_LOADING: 'INITIAL_LOADING_STATUS',
  LOADING: 'LOADING_STATUS',
  NULL: 'NULL_STATUS',
  ERROR: 'ERROR_STATUS'
};

export const EDUCATION_TYPE = {
  Internship: 'Internship',
  Residency: 'Residency',
  ChiefResidency: 'ChiefResidency',
  Fellowship: 'Fellowship',
  School: 'School'
};

export const KOL_TYPE = {
  Default: 'Default',
  KOL: 'Expert'
}

export const PIER_ICON = {
  SHARE_CARD_COVER: 'http://prodimg.p88health.com/static/img/share/share_card_cover.png',
}
export const SHARE_WAY = {
  wechatFriends: 2,
  wechatCircle: 3
};
export const SHARE_TYPE = {
  Activity: 'activity',
  Profile: 'profile',
  Opportunity: 'opportunity',
  Guide: 'guide',
  Course: 'course',
  Summary: 'summary',
  MedWord: 'medWord',
  CustomAcademy: 'customAcademy',
  TagPageList: 'tagPageList',
  Conference: 'conference',
  Invite: 'invite',
  Group: 'group'
};

export const LIST_PLACEHOLDER = {
  ACTIVITY: 'ACTIVITY',
  OPPORTUNITY: 'OPPORTUNITY',
  COURSE: 'COURSE',
  GUIDE: 'GUIDE',
  ARTICLE: 'ARTICLE',
  USER: 'USER',
  HOT_TOPIC: 'HOT_TOPIC',
  MEETUP: 'MEETUP',
  MEDWORD: 'MEDWORD',
}

export const OSS_AVATAR_SMALL = 'x-oss-process=image/resize,w_100'
export const FeedEntityType = {
  FriendSuggests: 'FriendSuggests',
  Post: 'Post',
  OpportunitySuggests: 'OpportunitySuggests',
  GroupSuggests: 'GroupSuggests',
  Article: 'Article',
}
export const shareIdMap = {
  activity: 'FeedItemId',
  profile: 'MemberId',
  UserId: 'UserId',
  opportunity: 'p8Id',
  guide: 'p8Id',
  course: 'p8Id',
  summary: 'p8Id',
  medWord: 'p8Id',
};
export const SHARE_IMG_TYPE = {
  Profile: 'profileImgUrl',
  Course: 'courseImgUrl',
  Activity: 'activityImgUrl',
  Opportunity: 'opportunityImgUrl',
  MedWord: 'medWordImgUrl',
  CustomAcademy: 'customAcademyImgUrl',
  TagPageList: 'tagPageListImgUrl',
  Conference: 'conferenceImgUrl',
  Invite: 'inviteImgUrl',
  Default: 'defaultImgUrl',
  Group: 'group'
};
export const ImgUrlMap = {
  activity: `${DBConfig.staticPostImageUrl}`,
  profile: `${DBConfig.staticAvatarUrl}`,
  opportunity: `${DBConfig.staticGroupImageUrl}`,
  course: `${DBConfig.coursePreviewUrl}`,
  medWord: `${DBConfig.staticMedWordImageUrl}`,
  customAcademy: `${DBConfig.staticAcademyIcon}`,
  tagPageList: `${DBConfig.staticAcademyIcon}`,
};

export const FEATURED_TYPE = {
  ARTICLE: 'ARTICLE',
  COURSE: 'COURSE',
  GUIDE: 'GUIDE',
}

export const OPEN_APP_ROUTE = {
  '/activity/': '/activityLink/',
  '/feedItemDetail/': '/activityLink/',
  '/guide/': '/guideLink/',
  '/guideDetail/': '/guideLink/',
  '/course/': '/courseLink/',
  '/courseDetail/': '/courseLink/',
  '/summary/': '/summaryLink/',
  '/userProfile/': '/userProfileLink/',
  '/friendDetail/': '/friendDetail/',
  '/opportunity/': '/opportunityLink/',
  '/opportunityDetail/': '/opportunityLink/',
  '/conferenceDetail/': '/conferenceLink/',
  '/medWordDetail/': '/medWordLink/',
  '/coronavirus': '/customAcademyLink/',
  '/customAcademy': '/customAcademyLink/',
  '/covid-19-information-center': '/customAcademyLink/',
  '/covid-19': '/customAcademyLink/',
}

export const ROUTE_REG = /\/[A-Za-z0-9-_]+\/?/ig

export const REGISTER_ROUTER = '/register'

export const APP_PAGE = {
  FEEDS: 'feeds',
  MESSAGE: 'message',
  NETWORK: 'network',
  PROFILE: 'profile',
  CHATROOM: 'chatRoom',
  ACADEMY: 'academy',
  FRIEND_DETAIL: 'FRIEND_DETAIL',
  GROUPDETAIL: 'groupDetail',
  APPLYDETAIL: 'applyDetail',
  AUTHENTICATION: 'authenticationPage',
  FEED_ITEM_DETAIL: 'feedItemDetail',
  ACTIVITY: 'feedItemDetail',
  EDIT_BASE_INFO: 'editBaseInfo',
  COURSE_DETAIL: 'courseDetail',
  GUIDE_DETAIL: 'guideDetail',
  CREATE_OPPORTUNITY: 'createOpportunity',
  EDIT_GROUP: 'editGroup',
  TAG_PAGE_LIST: 'tagPageList',
  ADD_FEED: 'addFeed',
  OPPORTUNITY: 'opportunityList',
  OPPORTUNITY_DETAIL: 'opportunityDetail',
  MED_WORD_DETAIL: 'medWordDetail',
  MED_WORD_LIST: 'medWordList',
  COURSE_LIST_WITH_TAG: 'courseListWithTag',
  GUIDE_LIST_WITH_TOPIC: 'guideListWithTopic',
  CONFERENCE_DETAIL: 'conferenceDetail',
  UPTODATE: 'uptodate',
  ARTICLE_LIST: 'articleList',
  MEDICINE_CATEGORY: 'selectMedicine',
  PDF_VIEWER: 'pdfViewer',
  FILE_CARE_VIEW: 'fileCareView'
}

export const NAV_ITEMS = {
  FEED: 'FEED',
  OPPORTUNITY: 'OPPORTUNITY',
  COURSE: 'COURSE',
  GUIDE: 'GUIDE',
  CUSTOM_ACADEMY: 'CUSTOM_ACADEMY',
  MEETUP: 'MEETUP'
}

export const WEB_ROUTE = {
  feedList: {
    name: 'feedList',
    type: NAV_ITEMS.FEED
  },
  feedItemDetail: {
    name: 'feedItemDetail',
    type: NAV_ITEMS.FEED
  },
  opportunityList: {
    name: 'opportunityList',
    type: NAV_ITEMS.OPPORTUNITY,
  },
  opportunityIndex: {
    name: 'opportunityIndex',
    type: NAV_ITEMS.OPPORTUNITY,
  },
  opportunityDetail: {
    name: 'opportunityDetail',
    type: NAV_ITEMS.OPPORTUNITY,
  },
  guideList: {
    name: 'guideList',
    type: NAV_ITEMS.GUIDE,
  },
  guideDetail: {
    name: 'guideDetail',
    type: NAV_ITEMS.GUIDE,
  },
  courseList: {
    name: 'courseList',
    type: NAV_ITEMS.COURSE,
  },
  courseDetail: {
    name: 'courseDetail',
    type: NAV_ITEMS.COURSE,
  },
  courseSearchList: {
    name: 'courseSearchList',
    type: NAV_ITEMS.COURSE,
  },
  customAcademy: {
    name: 'customAcademy',
    type: NAV_ITEMS.CUSTOM_ACADEMY,
  },
  coronavirus: {
    name: 'coronavirus',
    type: NAV_ITEMS.CUSTOM_ACADEMY,
  },
  'covid-19-information-center': {
    name: 'covid-19-information-center',
    type: NAV_ITEMS.CUSTOM_ACADEMY,
  },
  'covid-19': {
    name: 'covid-19',
    type: NAV_ITEMS.CUSTOM_ACADEMY,
  },
}

export const APP_ROUTE_IN_H5 = {
  [APP_PAGE.COURSE_LIST_WITH_TAG]: 'courseList',
  [APP_PAGE.GUIDE_LIST_WITH_TOPIC]: 'guideList',
  [APP_PAGE.TAG_PAGE_LIST]: 'tagPageList',
  [APP_PAGE.OPPORTUNITY]: 'opportunityList',
  [APP_PAGE.GROUPDETAIL]: 'groupDetail',
  [APP_PAGE.FEED_ITEM_DETAIL]: 'feedItemDetail',
  [APP_PAGE.OPPORTUNITY_DETAIL]: 'opportunityDetail',
  [APP_PAGE.COURSE_DETAIL]: 'courseDetail',
  [APP_PAGE.GUIDE_DETAIL]: 'guideDetail',
  [APP_PAGE.MED_WORD_DETAIL]: 'medWordDetail',
  [APP_PAGE.MED_WORD_LIST]: 'medWordList',
  [APP_PAGE.CONFERENCE_DETAIL]: 'conferenceDetail',
  [APP_PAGE.ARTICLE_LIST]: 'articleList',
  [APP_PAGE.MEDICINE_CATEGORY]: 'medicineCategoryList',
}

export const FEATURED = {
  Academy001: 'Academy001',
  HomeBanner: 'HomeBanner',
  Courseware: 'Courseware',
  HomeGrid: 'HomeGrid',
  CourseShortcuts: 'CourseShortcuts',
  OpportunityBanner: 'OpportunityBanner',
  OpportunityGrid: 'OpportunityGrid',
  H5HotTopic: 'H5HotTopic',
}

export const RANK_TYPE = {
  Education: 'Education',
  Hospital: 'Hospital'
}

export const SCENERY_TYPE = {
  Video: 'Video',
  Picture: 'Picture'
}

export const AUDIO_PLAY_STATUS = {
  init: 'init',
  playing: 'playing',
  done: 'done',
}

export const AUDIO_PLAYER_IMG_TYPE = {
  default: 'default',
  white: 'white',
}

export const buildAudio = audio => `${DBConfig.staticMedWordAudioUrl}${audio}`

export const buildAudioImage = image => `${DBConfig.staticMedWordImageUrl}${image}`

export const COURSE_TAB = {
  Introduction: 'introduction',
  Clips: 'clips',
  Courseware: 'Courseware',
  Script: 'Script',
}

export const MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const BROAD_CAST_TYPE = {
  Record: 'Record',
  Live: 'Live',
}

export const LIVE_SUB_BROAD_CAST_TYPE = {
  Video: 'Video',
  Upcoming: 'Upcoming',
  Live: 'Live',
  Playback: 'Playback',
}

export const COURSE_FEATURE_TYPE = {
  Feature: 'Feature'
}
