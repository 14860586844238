export const ACTIVITY_TYPE = {
  Create: 'Create',
  Comment: 'Comment',
  Like: 'Like',
  Share: 'Share'
};

export const ENTITY_TYPE = {
  FriendSuggests: 'FriendSuggests',
  Post: 'Post',
  OpportunitySuggests: 'OpportunitySuggests',
  GroupSuggests: 'GroupSuggests',
  Article: 'Article'
};

export const FeedEntityType = {
  FriendSuggests: 'FriendSuggests',
  Post: 'Post',
  OpportunitySuggests: 'OpportunitySuggests',
  GroupSuggests: 'GroupSuggests',
  Article: 'Article',
  Opportunity: 'Opportunity',
  Course: 'Course',
  Guide: 'Guide',
  Feed: 'Feed',
  User: 'User'
};

export const ACTIVITY_TABS_TYPE = {
  POST: 'post',
  COMMENT: 'comment',
  LIKE: 'like',
};

export const COPYRIGHT_TYPE = {
  Default: 'Default',
  Reprinted: 'Reprinted',
  Original: 'Original',
};

export const DefaultTake = 10
