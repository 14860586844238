import { observable, action, runInAction, computed, toJS, configure } from 'mobx';
import { toast } from 'react-toastify';
import { SHARE_TYPE } from '@COMMON/constant';
import BaseStore from './BaseStore';
import api from '@API';
import { TAGNAME, RANK_TYPE } from '@COMMON/opportunity'
import { FeedEntityType } from '@COMMON/feeds'

configure({ enforceActions: 'always' })
class OpportunityStore extends BaseStore {
  @observable list = [];

  @observable detailData = {};

  @observable opportunityTypes = [];

  @observable skip = 0;

  @observable perPage = 10;

  @observable canLoadMore = true;

  @observable selectedType = '';

  @observable groupOpportunities = [];

  @observable canLoadMoreOpportunity = true;

  @observable groupOpportunityTotal = 0;

  @observable loadingRelated = false;

  @observable relatedOpportunityList = [];

  @observable opportunityNewsFeed = []

  @observable newsSkip = 0

  @observable newsCanloadMore = true

  @observable hospitalList = []

  @observable canLoadHospitalList = true

  @observable canLoadEducationList = true

  @observable educationList = []

  @observable homeData = [];

  @observable tagOpportunityList = []

  @observable canLoadTagOpporList = true

  @computed get skipTagOpportunityList() {
    return this.tagOpportunityList?.length
  }

  @computed get skipHospitalList() {
    return this.hospitalList.length
  }

  @computed get skipEducationList() {
    return this.educationList.length
  }

  // 进修资讯TagId，需运营提供ID
  @observable tagId = ''

  @computed get skipOpportunity() {
    return this.groupOpportunities.length;
  }

  @action getOpportunitiesByTagId = async (tId, initial, refresh = true) => {
    if (refresh && !this.canLoadTagOpporList) return
    const skip = initial ? 0 : this.skipTagOpportunityList
    !skip ? this.dataInitialLoading() : this.dataLoading()
    try {
      const { Opportunities: data } = await api.getOpportunitiesByTagId({ tId, skip, take: this.perPage })
      runInAction(() => {
        this.tagOpportunityList = !skip ? data : [...this.tagOpportunityList, ...data]
        this.canLoadMoreOpportunity = data?.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action selectOpportunityType(type) {
    if (this.selectedType === type) {
      return;
    }
    this.skip = 0;
    this.list = [];
    this.selectedType = type;
    this.canLoadMore = true;
    this.getOpportunityFeed();
  }

  @action getHomeData = async take => {
    try {
      this.dataInitialLoading();
      const { Opportunities } = await api.getOpportunityFeed({
        skip: 0,
        take,
      })
      runInAction(() => {
        this.homeData = Opportunities
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action submitApplication = async OpportunityId => {
    try {
      await api.submitApplication({ OpportunityId });
      runInAction(() => {
        const applySuccess = item => ({
          ...item,
          IApplied: item.p8Id === OpportunityId ? true : item.IApplied,
          ApplicantCount:
            item.p8Id === OpportunityId
              ? item.ApplicantCount + 1
              : item.ApplicantCount
        });
        toast.success('已申请，打开"医者无界"可查看申请结果');
        this.detailData.ICanApply = false;
        this.detailData.IApplied = true;
        this.list = this.list.map(applySuccess);
        this.groupOpportunities = this.groupOpportunities.map(applySuccess);
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  @action getRankingList = async params => {
    const { rankingType } = params
    const funName = `get${rankingType}List`
    this[funName]?.(params)
  }

  @action getHospitalList = async ({ rankingType, initial }) => {
    if (!this.canLoadHospitalList) {
      return
    }
    const skip = initial ? 0 : this.skipHospitalList
    skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const responstData = await api.getGroupRankings({ skip, rankingType, take: this.perPage })
      runInAction(() => {
        this.hospitalList = !skip ? responstData : [...this.hospitalList, ...responstData]
        this.canLoadHospitalList = responstData.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getEducationList = async ({ rankingType, initial }) => {
    if (!this.canLoadEducationList) {
      return
    }
    const skip = initial ? 0 : this.skipEducationList
    skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const responstData = await api.getGroupRankings({ skip, rankingType, take: this.perPage })
      runInAction(() => {
        this.educationList = !skip ? responstData : [...this.educationList, ...responstData]
        this.canLoadEducationList = responstData.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getTagIdForOpportunityNewsFeed = async () => {
    this.dataInitialLoading();
    try {
      const response = await api.getTagDetailByName({ tagName: TAGNAME })
      const { p8Id } = response
      runInAction(() => {
        this.tagId = p8Id
        this.getNewsFeedById(p8Id)
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getNewsFeedById = async tagId => {
    if (!this.newsCanloadMore) {
      return;
    }
    this.newsSkip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const dataList = await api.getNewsFeedByTagId({ entityType: FeedEntityType.Article, tagId: this.tagId, skip: this.newsSkip, take: this.perPage })
      runInAction(() => {
        const opportunityNewsFeed = !this.newsSkip ? dataList : [...this.opportunityNewsFeed, ...dataList]
        this.opportunityNewsFeed = opportunityNewsFeed
        this.newsSkip = opportunityNewsFeed.length
        this.newsCanloadMore = opportunityNewsFeed.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action async getOpportunityFeed() {
    if (!this.canLoadMore) {
      return;
    }
    this.skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const { Opportunities } = await api.getOpportunityFeed({
        skip: this.skip,
        take: this.perPage,
        tp: this.selectedType
      });
      runInAction(() => {
        const newList = this.skip === 0 ? Opportunities : [...this.list, ...Opportunities];
        this.list = newList;
        this.skip = newList.length;
        this.canLoadMore = Opportunities.length >= this.perPage;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.dataSuccess();
    }
  }

  @action getRelatedOpportunities = async oId => {
    try {
      this.loadingRelated = true
      const { Opportunities } = await api.getRelatedOpportunities({ oId });
      runInAction(() => {
        this.relatedOpportunityList = Opportunities;
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      runInAction(() => {
        this.loadingRelated = false
      })
    }
  }

  @action async getOpportunityTypes() {
    try {
      const data = await api.getOpportunityTypes();
      runInAction(() => {
        this.opportunityTypes = data;
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action async getGroupOpportunities(gId, initial, refresh) {
    if (!refresh && !this.canLoadMoreOpportunity) {
      return;
    }
    const skip = initial ? 0 : this.skipOpportunity
    !skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const { Opportunities, Total } = await api.getGroupOpportunities({
        skip,
        take: this.perPage,
        gId
      });
      runInAction(() => {
        this.groupOpportunities = !skip ? Opportunities : [...this.groupOpportunities, ...Opportunities];
        this.groupOpportunityTotal = Total;
        this.canLoadMoreOpportunity = Opportunities?.length >= this.perPage;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.dataSuccess();
    }
  }

  @action async getOpportunityDetail(oId) {
    try {
      this.dataInitialLoading();
      const data = await api.getOpportunityDetails({ oId });
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.Opportunity)
        this.detailData = data;
        this.dataSuccess();
      });
    } catch (error) {
      this.dataError();
    }
  }

  @action addBookmark = async id => {
    try {
      const { p8Id } = await api.addBookmark({
        EntityType: 'Opportunity',
        EntityId: id,
      })
      runInAction(() => {
        this.detailData.BookmarkId = p8Id
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action unbookmark = async BookmarkId => {
    try {
      await api.unbookmark({
        BookmarkId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = null
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }
}

export default new OpportunityStore();
