/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import api from '@API'
import { strings } from '@I18N/i18n';
import ImagePlaceholder from '@COMS/Placeholder/ImagePlaceholder'
import '@STYLES/InviteRegister.scss'
import { SHARE_TYPE } from '@COMMON/constant';

const InviteRegister = WrappedLogin => {
  @inject('appStore', 'authStore', 'connectionStore')
  @observer
  class InviteView extends Component {
    state = {
      loading: false,
      src: ''
    }

    componentDidMount() {
      this.setViewVoucher()
      this.startImageLoadingProcess()
      this.props.connectionStore.setWeChatShareInfo({}, SHARE_TYPE.Invite)
    }

    startImageLoadingProcess = async () => {
      this.setState({ loading: true })
      try {
        const uri = await this.loadImage(require('@IMAGES/invite_register_bg@3x.png'))
        this.setState({ loading: false, src: uri })
      } catch (error) {
        toast.error(strings('register.loadimg_error'))
      }
    }

    loadImage = async src => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src;
        img.decode !== undefined
          ? img.decode().then(() => {
            resolve(img.src)
          }).catch(() => {
            reject(new Error('An Error occurred while trying to decode an image'))
          })
          : img.onload = () => {
            resolve(img.src)
          }
      })
    }

    setViewVoucher = async () => {
      try {
        await api.viewVoucher({ vId: this.props.match?.params?.id })
        this.forceUpdate()
      } catch (error) {
        this.forceUpdate()
        toast.error(error.message || error);
      }
    }

    renderImage = () => {
      const img = new Image()
      img.classList.add('invite-bg')
      img.src = require('@IMAGES/invite_register_bg@3x.png')

      return !img.complete ? <ImagePlaceholder /> : document.querySelector('.routeContainer')?.children[1]?.prepend(img)
    }

    render() {
      const { loading, src } = this.state

      return (
        <div className='invite-wrapped'>
          <img src={require('@IMAGES/invite_grain_bg.png')} alt='' className='invite-grain_bg' />
          {/* {this.renderImage()} */}
          {loading ? <ImagePlaceholder /> : (src && <img src={src} alt='' className='invite-bg' />)}
          <div className='invite-container'>
            <div className='invite-form-bg' />
            <div className='invite-form'>
              <WrappedLogin
                {...this.props}
                submitText={strings('register.submit_text')}
                registerTipText={strings('register.register_tip_now')}
                isInvite
                canShowTip={false}
                canShowProblem={false}
                onPressSubmit={this.props.authStore.inviteSignUpByPhone}
                emailLogin={this.props.authStore.emailLogin}
                getAuthCode={this.props.authStore.inviteRequestAuthCode}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  return InviteView
}

export default InviteRegister
