export const CATEGORY = {
  homeGrid: '首页九宫格（金刚区）',
  homeBanner: '首页banner',
  homeContent: {
    name: '首页信息流卡片',
    label: {
      course: '视频',
      feed: '动态',
      hotTopic: '热门话题',
      guide: '医学指南',
      meetup: '医学会议'
    }
  },
  medicine: {
    name: '疼痛用药手册',
    label: {
      searchBar: '搜索栏',
      banner: 'Banner',
      medicinePandect: '药物总论',
      category: '一级分类',
      secondCategory: '二级分类',
      medicineList: '药物列表',
    }
  },
  feedDetail: '文章详情页',
  courseDetail: '视频详情页',
  guideRedeem: '指南兑换',
  conferenceDetail: '会议详情页',
  navOpenApp: '页面顶部APP唤起按钮',
  event: '运营位',
  subscribeLive: '立即预约',
  zoomLive: {
    mobile: '手机点击直播',
    pc: '进入视频直播',
    login: '未登录点直播'
  },
  customAcademy: '专科学院'
}

export const ACTION = {
  click: '点击',
  openApp: '唤起App'
}
