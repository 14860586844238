/* eslint-disable no-undef,prefer-rest-params */
import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { strings } from '@I18N/i18n';
import './index.scss'

Modal.setAppElement('#root')

const style = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 9
  }
}

export default function ModalComponent({ children, ...rest }) {
  return (
    <Modal className='H5Modal' style={style} shouldCloseOnOverlayClick {...rest}>
      {children}
    </Modal>
  )
}

export function alert(title, message, config) {
  const actions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [{ text: strings('common.confirm') }]
  const div = document.createElement('div')
  function close() {
    ReactDOM.unmountComponentAtNode(div);
    if (div && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  function onPress(onClick) {
    onClick && onClick()
    close()
  }

  ReactDOM.render(React.createElement(
    Modal,
    { isOpen: true, className: 'H5Alert', style, onRequestClose: close },
    <div className='modalAlert'>
      <h3>{title || strings('common.tips')}</h3>
      <div className='alertBody'>{message}</div>
      <div className='alertBottom'>
        {actions.map(btn => {
          const { onClick, text, btnStyle } = btn
          return (
            <a key={text} style={btnStyle} onClick={() => { onClick ? onPress(onClick) : close() }}>{text}</a>
          )
        })}
      </div>
    </div>
  ), div);
}
