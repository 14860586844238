/* eslint-disable */
// https://github.com/farawaaay/UpickReact/blob/9d4a486099a4dd2571211177a4645f89a195bc5a/src/containers/Entry.page.js
import DBConfig from '@CONFIG';
import browserEnv from './browserEnv';
import { getToken } from '@UTILS/authority';

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
}

export const setCustom = () => {
  let channel
  channel = getCookie('channel');
  if (channel) {
    setCustomVar('channel', channel);
  }
  setCustomVar('browserEnv', browserEnv.isInWeixin() ? 'weixin' : 'app');
}

export const initAnalytics = history => {
  window._hmt = window._hmt || []; // eslint-disable-line 
  (function () { // eslint-disable-line 
    var hm = document.createElement("script"); // eslint-disable-line 
    hm.src = "//hm.baidu.com/hm.js?" + DBConfig.baiduAnalyId; // eslint-disable-line 
    var s = document.getElementsByTagName("script")[0]; // eslint-disable-line 
    s.parentNode.insertBefore(hm, s); // eslint-disable-line 
  })();
  (function () { // eslint-disable-line 
    var hm = document.createElement("script"); // eslint-disable-line 
    hm.src = "https://source.zoom.us/1.7.4/lib/vendor/jquery.min.js" // eslint-disable-line 
    var s = document.getElementsByTagName("script")[0]; // eslint-disable-line 
    s.parentNode.insertBefore(hm, s); // eslint-disable-line 
  })();


  //友盟统计
  window._czc = window._czc || []
  window._czc.push(["_setAccount", DBConfig.umengAnalyId]);
  window._czc.push(['_setAutoPageview', false]);
  (function () {
    var hm = document.createElement('script');
    hm.src = 'https://s95.cnzz.com/z_stat.php?id=' + DBConfig.umengAnalyId + '&web_id=' + DBConfig.umengAnalyId;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  })();

  _hmt.push(['_trackEvent', 'newApp', 'initApp']);
  _czc.push(['_trackEvent', 'newApp', 'initApp']);

  setCustom()

  history.listen(location => {
    // console.log('tracking page view: ', location.pathname);
    if(window['_hmt']) { // eslint-disable-line
      _hmt.push(['_trackPageview', location.pathname]); // eslint-disable-line
      _czc.push(['_trackPageview', location.pathname]); // eslint-disable-line
    } else {
      console.error('没有初始化统计代码');
    }
  });
};

export function trackPageView(page, prePage) {
  window._hmt.push(['_trackPageview', page]);
  window._czc.push(['_trackPageview', page]);
}

export function trackEvent(category, action = 'click', label, value) {
  // _hmt.push(['_trackEvent', 'video', 'play', 'Hey Jude'])
  window._hmt.push(['_trackEvent', category, action, label, value]);
  window._czc.push(['_trackEvent', category, action, label, value])
}

export function trackEventValue(name, value, type = 'react') {
  if (typeof value === 'object') {
    window._hmt.push(['_trackEvent', type, 'set_value', name]);
  } else {
    window._hmt.push(['_trackEvent', type, 'set_value', name, value]);
    window._czc.push(['_trackEvent', name, value, type])
  }
}


export function setCustomVar(key, value) {
  let baiduIndexMap = {
    channel: 1,
    browserEnv: 2,
    isLogin: 3,
  };
  window._czc.push(["_setCustomVar","has_token", getToken() ? 'yes' : 'no',1]);
  window._hmt.push(["_setCustomVar","has_token", getToken() ? 'yes' : 'no',1]);
  window._hmt.push(['_setCustomVar', baiduIndexMap[key] ? baiduIndexMap[key] : 5, key, value, 2]);
  window._czc.push(['_setCustomVar', baiduIndexMap[key] ? baiduIndexMap[key] : 5, key, value, 2]);
}
