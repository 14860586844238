/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '@STYLES/Login.scss';

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  if (show) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <a onClick={handleClose} className="closeModal" />
      </section>
    </div>
  );
};

export default Modal;
