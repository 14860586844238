import * as dd from 'dingtalk-jsapi';
import 'whatwg-fetch';
import queryString from 'query-string';

const CORPID = 'ding114b51efdcf384d435c2f4657eb6378f'
const ZJSRM_CORPID = 'ding1ac57d592aa1cbb235c2f4657eb6378f'

const APP_KEY_CORPID_MAPING = {
  '1f3aa96e-6579-4dc0-a2e6-245d62c10a81': 'ding114b51efdcf384d435c2f4657eb6378f', //  Pier Dev
  'a886d813-7a3d-4b8f-8cb5-16b550c42b76': 'ding114b51efdcf384d435c2f4657eb6378f', //  Pier Prod
  '0aa1762a-e6b8-4228-8383-f75cb5f0a434': 'ding1ac57d592aa1cbb235c2f4657eb6378f' //  ZJSRM Prod
}

export const isDingTalkBrowser = !(dd.env.platform === 'notInDingTalk')

// export const isZJSRM = () => {
//   const href = window?.location?.href
//   return href.indexOf('zjsrm') >= 0
// }

const checkAppKey = (key = '') => {
  return key.replace('{', '').replace('}', '')// '{0aa1762a-e6b8-4228-8383-f75cb5f0a434}'
}

export const parserParams = searchStr => {
  const preParams = queryString.parse(searchStr);
  let { appkey } = preParams
  appkey = checkAppKey(appkey)
  return { ...preParams, appkey }
}

export const requestCodeDebug = appkey => {
  return new Promise((resolve, reject) => {
    dd.ready(() => {
      // console.log('CORPID', APP_KEY_CORPID_MAPING[appkey])
      dd.runtime.permission.requestAuthCode({
        corpId: APP_KEY_CORPID_MAPING[appkey],
        onSuccess: info => {
          dd.device.notification.alert({
            message: `钉钉成功信息: ${info.code}, ${appkey}`,
            title: '成功',
            buttonName: '确定'
          });
          resolve(info.code);
        },
        onFail: err => {
          dd.device.notification.alert({
            message: `钉钉错误信息: ${APP_KEY_CORPID_MAPING[appkey]?.substring(0, 6)}, ${appkey?.substring(0, 6)}, ${JSON.stringify(err)}`,
            title: '出错了',
            buttonName: '确定'
          });
          reject(err)
        }
      });
    });
  });
};

export const requestCode = appkey => {
  return new Promise((resolve, reject) => {
    dd.ready(() => {
      // console.log('CORPID', APP_KEY_CORPID_MAPING[appkey])
      dd.runtime.permission.requestAuthCode({
        corpId: APP_KEY_CORPID_MAPING[appkey],
        onSuccess: info => {
          // console.log(info.code)
          resolve(info.code);
        },
        onFail: err => {
          dd.device.notification.alert({
            message: `钉钉错误信息: ${APP_KEY_CORPID_MAPING[appkey]?.substring(0, 6)}, ${appkey?.substring(0, 6)}, ${JSON.stringify(err)}`,
            title: '出错了',
            buttonName: '确定'
          });
          reject(err)
        }
      });
    });
  });
};
