import { observable, action, computed, reaction, runInAction } from 'mobx'
import { APP_TOKEN_KEY, USER_APP_KEY, PERMISSION_KEY } from '@COMMON/constant';
import routingStore from './RoutingStore';
import api from '@API';

function storageObj(obj) {
  const checkedIdStr = JSON.stringify(obj);
  window.localStorage.setItem(PERMISSION_KEY, checkedIdStr);
}

function getStorageObj(obj) {
  if (window.localStorage.getItem(PERMISSION_KEY)) {
    const objAfter = JSON.parse(window.localStorage.getItem(PERMISSION_KEY))
    return objAfter
  }
  return {}
}

class AppStore {
  @observable loading = false;

  @observable tip = '载入中...';

  @observable appName = 'MedLinc';

  @observable userAppKey = window.localStorage.getItem(USER_APP_KEY);;

  @observable token = window.localStorage.getItem(APP_TOKEN_KEY);

  @observable isBindPhone = false;

  @observable isShowModal = false;

  @observable appLoaded = false;

  @observable permissionsHash = getStorageObj();

  @observable canSetNavStatus = false

  @observable navType = ''

  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem(APP_TOKEN_KEY, token);
        } else {
          window.localStorage.removeItem(APP_TOKEN_KEY);
        }
      }
    );
    reaction(
      () => this.userAppKey,
      appkey => {
        if (appkey) {
          window.localStorage.setItem(USER_APP_KEY, appkey);
        } else {
          window.localStorage.removeItem(USER_APP_KEY);
        }
      }
    );
    reaction(
      () => this.permissionsHash,
      permissionsHash => {
        if (permissionsHash) {
          storageObj(permissionsHash)
        } else {
          window.localStorage.removeItem(PERMISSION_KEY);
        }
      }
    );
  }

  @action setToken(token, redirect) {
    this.token = token;
    window.localStorage.setItem(APP_TOKEN_KEY, token);
    if (redirect) {
      routingStore.push(redirect)
    }
  }

  @action setUserAppKey(appkey) {
    this.userAppKey = appkey;
  }

  @action setBindPhone(isBind) {
    this.isBindPhone = isBind;
  }

  @action resetBindPhone() {
    this.isBindPhone = false;
  }

  @action setAppLoaded(load = true) {
    this.appLoaded = load;
  }

  @action setBindPhoneModal(isShow) {
    this.isShowModal = isShow;
  }

  @action setPermission(permissions) {
    this.permissionsHash = Object.assign(this.permissionsHash || {}, permissions || {})
    storageObj(permissions);
  }

  @action
  async clearToken() {
    try {
      await api.logout();
      runInAction(() => {
        this.setToken(null)
        this.permissionsHash = null
        this.userAppKey = null
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action
  updateLoading(loading) {
    this.loading = loading;
  }

  @action
  updateTip(tip) {
    this.tip = tip;
  }

  @action
  setNavStatus(status, navType) {
    this.canSetNavStatus = status
    this.navType = navType
  }

  // @action
  // do_import(path) {
  //     try {
  //         this.updateLoading(true);

  //         if (!!window.EventSource) {
  //             var source = new EventSource('/api/v1/db_import');
  //             source.onmessage = function(e) {
  //                 console.log('source.onmessage',e);
  //                 // let o = JSON.parse(e);
  //                 // if(!!o["data"]) {
  //                 //     this.updateTip('已完成'+o["data"]+'%...');
  //                 // }
  //                 // if(!!o["message"]) {
  //                 //     notification.open({ message: '操作提示', description: o["message"]});
  //                 // }
  //             };
  //             source.onerror = function(e) {
  //                 console.error("EventSource failed.");
  //                 source.close();
  //             };
  //         }

  //         console.log(path);

  //         axios({
  //             method:'post',
  //             url:'/api/v1/db_import',
  //             data:qs.stringify({path},{arrayFormat:'brackets'}),
  //             headers:{ 'Content-Type': 'application/x-www-form-urlencoded' }
  //         }).then(resp => {
  //             console.log('models','generate','generate',resp.data);
  //             notification.open({ message: '操作提示', description: '已导入完成'});
  //             this.updateTip('载入中');
  //             this.updateLoading(false);
  //             this.load();
  //         }).catch(e => {
  //             throw e;
  //         });
  //     } catch(e) {
  //         console.error(e);
  //         notification.open({ message: '操作提示', description: e.message||'发生错误'});
  //         this.updateLoading(false);
  //     }
  // }

  // @action
  // do_export(path) {
  //     try {
  //         this.updateLoading(true);

  //         if (!!window.EventSource) {
  //             var source = new EventSource('/api/v1/db_export');
  //             source.onmessage = function(e) {
  //                 console.log('**********',e);
  //                 let o = JSON.parse(e);
  //                 if(!!o["data"]) {
  //                     this.updateTip('已完成'+o["data"]+'%...');
  //                 }
  //             }
  //         }

  //         console.log(path);

  //         axios({
  //             method:'post',
  //             url:'/api/v1/org_export',
  //             data:qs.stringify({path},{arrayFormat:'brackets'}),
  //             headers:{ 'Content-Type': 'application/x-www-form-urlencoded' }
  //         }).then(resp => {
  //             console.log('models','generate','generate',resp.data);
  //             notification.open({ message: '操作提示', description: '已导出完成'});
  //             this.updateTip('载入中');
  //             this.updateLoading(false);
  //         }).catch(e => {
  //             throw e;
  //         });
  //     } catch(e) {
  //         console.error(e);
  //         notification.open({ message: '操作提示', description: e.message||'发生错误'});
  //         this.updateLoading(false);
  //     }
  // }
}

export default new AppStore();
