import { observable, action, computed, runInAction, autorun, transaction } from 'mobx';
// import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import api from '@API';
// import { setToken } from '@UTILS/authority';
import routingStore from './RoutingStore';
import appStore from './AppStore';
import userStore from './UserStore';
import { parserParams } from '@UTILS/dingtalk';

const AnonymousMember = 'AnonymousMember';
// const ExampleClientKey = '760b4840-6299-11e9-af8c-c9d0051b8f43'

class AuthStore {
  actionTokenId = '';

  bindPhoneCallBack;

  callBackParam;

  @observable inProgress = false;

  @observable error = {
    message: ''
  };

  @observable values = {
    appkey: '',
    userid: '',
    name: '',
    organization: ''
  };

  constructor() {
    const { pathname } = window.location
    const isLoginRouter = pathname.indexOf('login') > 0
    const disabledLogin = pathname.indexOf('fileCareView') > 0
    if (!appStore.token && !isLoginRouter && !disabledLogin) {
      let params
      let langParams
      const { location: { search } } = window
      if (search && search.indexOf('appkey') > 0) {
        params = parserParams(search);
      }
      if (search?.indexOf('lang') > 0) {
        langParams = parserParams(search);
      }
      const { appkey, userid, redirect } = params || {}
      const { lang } = langParams || {}
      this.verify(appkey, userid, redirect, '', lang)
    }
    autorun(() => {
      if (!appStore.isShowModal) {
        this.bindPhoneCallBack = undefined;
        this.callBackParam = undefined;
      }
    });
  }

  @action setAppKey(appkey) {
    this.values.appkey = appkey;
  }

  @action setAccount(userid) {
    this.values.userid = userid;
  }

  @action setOrganization(organization) {
    this.values.organization = organization;
  }

  @action setErrorMessage(error) {
    this.error = {
      message: error.message || error
    };
  }

  @action reset() {
    this.values.appkey = '';
    this.values.userid = '';
    this.values.name = '';
    this.values.organization = '';
    this.error.message = '';
  }

  @action setBindPhoneCallBack(callback, param) {
    this.bindPhoneCallBack = callback;
    this.callBackParam = param;
  }

  @action
  inviteRequestAuthCode = async PhoneNumber => {
    if (!PhoneNumber) return
    try {
      const data = await api.inviteRequestAuthCode({ PhoneNumber })
      runInAction(() => {
        this.registerActionTokenId = data.ActionTokenId;
        this.registerPhoneNumber = PhoneNumber
      });
    } catch (error) {
      this.setErrorMessage(error);
      this.registerActionTokenId = '';
      toast.error(error.measure || error);
    }
  }

  @action
  inviteSignUpByPhone = async (AuthCode, VoucherId, callBack) => {
    if (!AuthCode) {
      toast.error('验证码为空');
      return;
    }
    try {
      const params = {
        PhoneNumber: this.registerPhoneNumber,
        ActionTokenId: this.registerActionTokenId,
        AuthCode,
        VoucherId
      }
      appStore.setAppLoaded(false)
      const response = await api.inviteSignUpByPhone(params)
      toast.success('注册成功')
      callBack && callBack()
    } catch (error) {
      appStore.setAppLoaded()
      toast.error(error.message || error);
    }
  }

  @action
  requestAuthCode = async PhoneNumber => {
    if (!PhoneNumber) {
      return;
    }
    try {
      const data = await api.requestAuthCodeBind({ PhoneNumber });
      runInAction(() => {
        this.actionTokenId = data.ActionTokenId;
        this.bindPhoneNumber = PhoneNumber;
      });
    } catch (err) {
      this.setErrorMessage(err);
      this.actionTokenId = '';
      toast.error(err.message || err);
    }
  };

  @action
  modalBindPhone = async AuthCode => {
    if (!AuthCode) {
      toast.error('验证码为空');
      return;
    }
    try {
      const data = await api.loginOrRegisterByPhone({
        ActionTokenId: this.actionTokenId,
        PhoneNumber: this.bindPhoneNumber,
        AuthCode,
        AcquireChannel: 'H5'
      });
      runInAction(() => {
        appStore.setBindPhone(true);
        appStore.setBindPhoneModal(false);
        appStore.setToken(data.UserToken);
        userStore.setCurrentUser(data);
        this.bindPhoneCallBack && this.bindPhoneCallBack(this.callBackParam);
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  @action
  emailLogin = async (params, callBack) => {
    try {
      const data = await api.loginOrRegisterByEmail(params);
      if (params?.AcquireChannel === 'Invite') {
        toast.success('注册成功')
        callBack?.()
        return
      }
      runInAction(() => {
        appStore.setBindPhone(true);
        appStore.setBindPhoneModal(false);
        appStore.setToken(data.UserToken);
        userStore.setCurrentUser(data);
        this.bindPhoneCallBack && this.bindPhoneCallBack(this.callBackParam);
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action updateUserFeedLanguages = async FeedLanguages => {
    try {
      const data = await api.updateUserFeedLanguages({
        FeedLanguages
      });
      runInAction(() => {
        userStore.setCurrentUser(data);
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  // @action
  // bindPhone = async AuthCode => {
  //   console.log('BindPhone action', AuthCode)
  //   if (!AuthCode) {
  //     toast.error('验证码为空');
  //     return;
  //   }
  //   try {
  //     const data = await api.bindPhone({ ActionTokenId: this.actionTokenId, AuthCode })
  //     runInAction(() => {
  //       appStore.setBindPhone(true)
  //       appStore.setBindPhoneModal(false)
  //       toast.success(data)
  //     })
  //   } catch (error) {
  //     toast.error(error.message || error)
  //   }
  // }

  @action
  verify = async (appkey, userid, redirect, name, feedlang) => {
    this.inProgress = true;
    // appStore.updateLoading(true);
    // console.log('start to verify', appkey, userid, redirect, name);
    try {
      const data = await api.loginH5({ ClientKey: appkey, ExternalId: userid, UserName: name, FeedLanguage: feedlang })
      // console.log('Login H5', data);
      // if (userid) {
      //   toast.error(data.UserToken)
      // }
      runInAction(() => {
        appStore.setPermission(data?.UserContext?.PermissionsHash || {})
        appStore.setToken(data.UserToken, redirect);
        appStore.setUserAppKey(appkey);
        userStore.setCurrentUser(data);
        this.values = {
          appkey,
          userid,
          name: data?.UserPersonal?.DisplayName?.zh,
          organization: data?.UserContext?.CurrentGroupName?.zh
        };
        // if (userid) {
        //   toast.error('Done')
        // }
      });
    } catch (err) {
      runInAction(() => {
        console.log('Fetch error', err);
        this.setErrorMessage('login error');
      });
      toast.error(err.message || err);
    } finally {
      // if (userid) {
      //   toast.error('finally')
      // }
      runInAction(() => {
        appStore.setAppLoaded()
        this.inProgress = false;
      });
    }
  };
}

export default new AuthStore();
