/* eslint-disable no-script-url */
import React, { PureComponent } from 'react';
import Config from '@CONFIG';
import { UserStatusEnums, UserTypeEnums } from '@COMMON/constant';
import { loadAvatarImage } from '@UTILS/avatarSource';
import { setOssImageSize } from '@UTILS/utils'
import '@STYLES/Avatar.scss';

const ossSize = setOssImageSize(70, 70, 'png')

class Avatar extends PureComponent {
  static defaultProps = {
    staticUrl: Config.staticAvatarUrl,
    defaultImg: require('@ASSETS/image/icon_sculpture@3x.png'),
    pressDisabled: true,
    type: UserTypeEnums.Physician
  };

  constructor(props) {
    super(props);
    const { staticUrl, id, type, avatarVersion } = props;
    const defaultAvatar = this.getDefaultAvatar(id, type);
    this.state = {
      src: avatarVersion ? `${staticUrl}${id}.jpg${ossSize}` : defaultAvatar
    };
  }

  componentWillReceiveProps(nextProps) {
    const { type, id } = nextProps;
    const { staticUrl } = this.props;
    if (nextProps.avatarVersion && nextProps.avatarVersion !== this.props.avatarVersion) {
      this.setState({
        src: `${staticUrl}${nextProps.id}.jpg${ossSize}`
      });
    }
    if (!nextProps.avatarVersion) {
      this.setState({
        src: this.getDefaultAvatar(id, type)
      });
    } else if (nextProps.id !== this.props.id) {
      this.setState({
        src: `${staticUrl}${nextProps.id}.jpg${ossSize}`
      });
    }
  }

  getDefaultAvatar = (id, type) => {
    const { defaultImg } = this.props;
    return id && type ? loadAvatarImage(type, id) : defaultImg;
  };

  noFind = () => {
    const { defaultImg } = this.props;
    this.setState({
      src: defaultImg
    });
  };

  render() {
    const {
      href,
      pressDisabled,
      style,
      memberId,
      userStatus,
      onPress
    } = this.props;
    const { src } = this.state;
    return (
      <a
        href={
          pressDisabled ? 'javascript:;' : href || `/userProfile/${memberId}`
        }
        className='imgLinkContainer'
        style={style}
        onClick={onPress}
      >
        <img className='avatarImg' src={src} onError={this.noFind} alt='' />
        {userStatus === UserStatusEnums.Verified && (
          <img
            className='verifiedIcon'
            src={require('@ASSETS/image/icon_vip_mini@3x.png')}
            alt=''
          />
        )}
      </a>
    );
  }
}

export default Avatar;
