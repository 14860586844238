import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process error
    return {
      hasErrored: true,
    };
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <div>
          <h1>
            There was an error with this listing.
            <Link to='/'>Click here</Link>
            to go back to the home page or wait 5 seconds.
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;