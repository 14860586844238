import ReactDOM from 'react-dom';
import initRem from '@UTILS/initRem';
import { checkIsMobile } from '@UTILS/utils';
import webRoutes from './routes/webRoutes';
import './App.scss'
import VConsole from 'vconsole'

console.log(process.env.TARGET_ENV)
if (checkIsMobile() && process.env.TARGET_ENV !== 'production') {
  window.vconsole = new VConsole()
}

const routes = webRoutes();

initRem();

document.body.addEventListener('touchstart', () => {});

ReactDOM.render(routes, document.getElementById('root'));
