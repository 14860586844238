import { observable, action, runInAction, configure } from 'mobx';
import { toast } from 'react-toastify';
import api from '@API';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' })

class SubjectUserStore extends BaseStore {
  @observable subjectUserDetail = {};

  @action getSubjectUser = async mId => {
    try {
      const data = await api.getProfile({ mId });
      runInAction(() => {
        this.subjectUserDetail = data;
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }
}

export default new SubjectUserStore()
