import { observable, action, runInAction, configure, computed } from 'mobx';
import api from '@API';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' })

class BookmarkStore extends BaseStore {
  @observable bookmarkList = [];

  @observable canLoadBookmarkList = true

  @observable perPage = 10

  @computed get skipBookmarkList() {
    return this.bookmarkList.length
  }

  @action resetGetBookmarks = type => {
    this.bookmarkList = []
    this.canLoadBookmarkList = true
  }

  @action getBookmarks = async (payload = {}) => {
    try {
      if (!this.canLoadBookmarkList) return

      const skip = this.skipBookmarkList
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getBookmarks({ skip, take: this.perPage, ...payload })

      runInAction(() => {
        this.bookmarkList = !skip ? data : [...this.bookmarkList, ...data]
        this.canLoadBookmarkList = data?.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }
}

export default new BookmarkStore()
