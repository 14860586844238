/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { strings, getLanguage } from '@I18N/i18n';
import { OPEN_APP_ROUTE, WEB_ROUTE, NAV_ITEMS, LanguageEnum, ROUTE_REG, SOURCE, APP_PAGE } from '@COMMON/constant'
import Avatar from '@COMS/ShareWeb/Avatar';
import { openApp, queryString } from '@UTILS/utils';
import { trackEvent } from '@UTILS/analysis'
import { CATEGORY, ACTION } from '@COMMON/analysisConstant';
import '@STYLES/Nav.scss';

const hideNavPage = [APP_PAGE.S]

const menuMap = [{
  title: strings('home.news'),
  router: '/feedList',
  type: NAV_ITEMS.FEED
}, {
  title: strings('home.guide'),
  router: '/guideList',
  type: NAV_ITEMS.GUIDE
}, {
  title: strings('home.video_course'),
  router: '/courseList',
  type: NAV_ITEMS.COURSE
}, {
  title: strings('home.meetup'),
  router: '/meetup',
  type: NAV_ITEMS.MEETUP
}, {
  title: strings('home.COVID'),
  router: '/covid-19',
  type: NAV_ITEMS.CUSTOM_ACADEMY
}]
const iconMap = [{
  common: require('@IMAGES/nav_mbl_feed@3x.png'),
  active: require('@IMAGES/nav_mbl_feed_active@3x.png')
}, {
  common: require('@IMAGES/nav_mbl_guide@3x.png'),
  active: require('@IMAGES/nav_mbl_guide_active@3x.png')
}, {
  common: require('@IMAGES/nav_mbl_video@3x.png'),
  active: require('@IMAGES/nav_mbl_video_active@3x.png')
}, {
  common: require('@IMAGES/nav_mbl_conferences@3x.png'),
  active: require('@IMAGES/nav_mbl_conferences_active@3x.png')
}, {
  common: require('@IMAGES/nav_mbl_cnov@3x.png'),
  active: require('@IMAGES/nav_mbl_cnov_active@3x.png')
}]

export function NavList({ onClickItem, activeIndex, showIcon }) {
  return menuMap.map((item, index) => {
    const { router, title } = item;
    const isActive = index === activeIndex
    const icon = isActive ? iconMap[index].active : iconMap[index].common;
    return (
      <li key={title}>
        <a
          className={`${isActive && 'active'}`}
          onClick={() => {
            onClickItem && onClickItem(router, index, title);
          }}
        >
          {showIcon && <img src={icon} alt='' />}
          {title}
        </a>
      </li>
    );
  });
}


const getInitialIndex = () => {
  const COVID = '9ef90990-677f-11ea-9ba1-41b6bf2471ab'
  const routeName = window.location.pathname.split('/')[1]
  const queryParams = queryString()
  if ((routeName === WEB_ROUTE.customAcademy.name) && queryParams.aId && queryParams.aId !== COVID) {
    return null
  }

  return menuMap.findIndex(item => {
    return item.type === WEB_ROUTE[routeName]?.type
    || item.router === `/${queryParams.redirect}`
  })
}

@inject('userStore', 'appStore', 'authStore')
@withRouter
@observer
export default class WebNav extends Component {
  constructor(props) {
    super(props);
    const initialIndex = getInitialIndex()
    this.state = {
      isCollapsed: false,
      activeIndex: initialIndex,
      showForm: false,
      showMblForm: false,
      language: getLanguage(),
      mblTitle: menuMap[initialIndex]?.title || strings('common.app_name'),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { appStore: { canSetNavStatus, navType } } = props
    if (canSetNavStatus) {
      props.appStore.setNavStatus(false, '')
      return {
        activeIndex: navType,
        mblTitle: menuMap[navType]?.title
      }
    }
    return null
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onPopState, false)
  }

  componentWillUnmount() {
    window.removeEventListener('removeEventListener', this.onPopState, false)
  }

  onPopState = e => {
    const pathname = e.target?.location?.pathname
    const index = menuMap.findIndex(item => item.router === pathname)
    if (index > 0) {
      this.setState({
        activeIndex: index,
        mblTitle: menuMap[index]?.title
      })
      return
    }
    this.setState({
      activeIndex: 0,
      mblTitle: strings('common.app_name')
    })
  }

  onClickToggle = () => {
    const { isCollapsed } = this.state;
    const width = document.body.clientWidth;
    if (width > 840) {
      return;
    }
    this.navList.style.height = isCollapsed ? '0px' : `${menuMap.length * 50 + 16}px`
    this.setState({
      isCollapsed: !isCollapsed
    });
  };

  onClickNavItem = (router, index) => {
    const {
      history: { push }
    } = this.props;
    this.navList.style.height = '0px'
    this.setState({
      isCollapsed: false,
      activeIndex: index
    });
    router && push(router);
  };

  onClickMblNavItem = (router, index, title) => {
    this.setState({
      mblTitle: title
    });
    this.onClickNavItem(router, index);
  };

  onClickSearchShow = () => {
    const { showForm } = this.state;
    this.setState({
      showForm: !showForm
    });
  };

  onClickMblSearch = () => {
    const { showMblForm } = this.state;
    this.setState({
      showMblForm: !showMblForm,
      isCollapsed: false
    });
  };

  getCanOpenAppStatus = () => {
    const { pathname } = window.location
    const shortRoute = pathname.match(ROUTE_REG) || []
    return Object.keys(OPEN_APP_ROUTE).includes(shortRoute[0])
  }

  onEnterBaseInfo = () => {
    const { appStore: { isBindPhone }, appStore, authStore } = this.props
    if (isBindPhone) {
      this.props.history.push('/baseInfo')
    } else {
      appStore.setBindPhoneModal(true);
      authStore.setBindPhoneCallBack(() => {
        window.location.reload()
      });
    }
  }

  onClickLogin = () => {
    this.props.appStore.setBindPhoneModal(true)
  }

  renderAvatar = () => {
    const {
      userStore: { currentUser = {} },
      appStore: { isBindPhone },
    } = this.props;
    const { language } = this.state
    const { Type, AvatarVersion, p8Id, Status } = currentUser;
    const notLoginImage = LanguageEnum.en === language ? require('@ASSETS/image/notLoginEn@3x.png') : require('@ASSETS/image/notLogin@3x.png')
    if (isBindPhone) {
      return (
        <Avatar
          type={Type}
          avatarVersion={AvatarVersion}
          id={p8Id}
          userStatus={Status}
          onPress={this.onEnterBaseInfo}
        />
      )
    }
    return (
      <a className='notLogin'>
        <img src={notLoginImage} alt='' onClick={this.onClickLogin} />
      </a>
    )
  }

  onOpenApp = () => {
    const { pathname } = window.location
    trackEvent(CATEGORY.navOpenApp, ACTION.openApp, pathname)
    openApp()
  }

  render() {
    const {
      isCollapsed,
      activeIndex,
      showForm,
      showMblForm,
      mblTitle,
    } = this.state;
    const {
      userStore: { currentUser = {} },
      permissionsHash: { DownloadApp },
      appStore: { isBindPhone },
    } = this.props;
    const { s } = queryString()
    const routeName = window.location.pathname.split('/')[1]
    if ([APP_PAGE.FEED_ITEM_DETAIL, APP_PAGE.ACTIVITY, APP_PAGE.PDF_VIEWER, APP_PAGE.FILE_CARE_VIEW].includes(routeName) && [SOURCE.m, SOURCE.care].includes(s)) {
      return null
    }
    const { Type, AvatarVersion, p8Id, Status } = currentUser;
    const canShowOpenApp = this.getCanOpenAppStatus()
    return (
      <nav className='navBarContainer'>
        <div className='navBar'>
          <a className='brand' href='/'>
            <img src={require('@IMAGES/top_logo@3x.png')} alt='' />
            <span className='brandName'>{strings('common.app_name')}</span>
          </a>
          <ul className={`navList ${showForm ? 'hideNavList' : ''}`}>
            <NavList
              activeIndex={activeIndex}
              onClickItem={this.onClickNavItem}
            />
          </ul>
          <form className={`navForm ${showForm ? 'showNavForm' : ''}`}>
            <input type='text' />
            <a className='navSearchClose' onClick={this.onClickSearchShow} />
            <a className='navSearchSubmit' />
          </form>
          <div className='navRight'>
            {/* <a
              className={`navSearchBtn ${showForm ? 'hide' : ''}`}
              onClick={this.onClickSearchShow}
            />
            <a className='navMessageBtn' /> */}
            {this.renderAvatar()}
          </div>
        </div>
        <div className='mblNavBar'>
          <a href='/'>
            <img
              src={require('@IMAGES/top_logo@3x.png')}
              alt=''
              className='mblLogo'
            />
          </a>
          <div className='mblNavRight'>
            <a className='mblBrand' onClick={this.onClickToggle}>
              <span
                className={`mblBrandName ${isCollapsed ? 'isCollapsed' : ''}`}
              >
                {mblTitle}
              </span>
            </a>
            {/* <div className='mblNavIcons'>
              <a onClick={this.onClickMblSearch} />
              <a className='messageIcon' />
            </div> */}
            <div className='openAppAndAvatar'>
              {canShowOpenApp && <span className='openAppBtn' onClick={this.onOpenApp}>{strings('common.openApp')}</span>}
              {this.renderAvatar()}
            </div>
          </div>
          <form className={`mblNavForm ${showMblForm ? 'showMblForm' : ''}`}>
            <div className='inputContainer'>
              <input type='text' />
            </div>
            <div className='btnGroup'>
              <a />
              <a className='closeIcon' onClick={this.onClickMblSearch} />
            </div>
          </form>
        </div>
        <ul className={`mblNav ${isCollapsed && 'show'}`} ref={c => { this.navList = c }}>
          <NavList
            activeIndex={activeIndex}
            onClickItem={this.onClickMblNavItem}
            showIcon
          />
        </ul>
        <div
          className={`mblNavScreen ${isCollapsed && 'show'}`}
          onClick={this.onClickToggle}
        />
      </nav>
    );
  }
}
