import React from 'react'
import ContentLoader from 'react-content-loader'

export default function ImagePlaceholder() {
  return (
    <ContentLoader
      height={260}
      width={375}
      speed={2}
      primaryColor='#f7f7f7'
      secondaryColor='#f2f2f2'
    >
      <rect x='5' y='5' rx='5' ry='5' width='365' height='260' />
    </ContentLoader>
  )
}
