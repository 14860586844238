import { observable, action, runInAction, configure, computed } from 'mobx';
import { toast } from 'react-toastify'
import { SHARE_TYPE, FEATURED } from '@COMMON/constant';
import BaseStore from './BaseStore';
import api from '@API';
import SubjectUserStore from './SubjectUserStore';

configure({ enforceActions: 'always' })

const DEFAULT_TAG_TAKE = 20

class CourseStore extends BaseStore {
  perPage = 10;

  @observable courseList = [];

  @observable canLoadMore = true;

  @observable detailData = undefined;

  @observable subjectUser = undefined;

  @observable relatedCourses = [];

  @observable tId = '';

  @observable skip = 0;

  @observable courseTagList = [];

  @observable searchCourseList = [];

  @observable canLoadMoreSearch = true;

  @observable homeData = [];

  @observable tagCourseList = []

  @observable canLoadTagCourseList = true

  @computed get skipTagCourseList() {
    return this.tagCourseList.length
  }

  @computed get skipSearch() {
    return this.searchCourseList.length;
  }

  @action getCourseByTagId = async (tId, initial, refresh) => {
    if (refresh && !this.canLoadTagCourseList) return
    const skip = initial ? 0 : this.skipTagCourseList
    !skip ? this.dataInitialLoading() : this.dataLoading();
    try {
      const { Courses: data } = await api.getCourses({ tId, skip, take: this.perPage })
      runInAction(() => {
        this.tagCourseList = !skip ? data : [...this.tagCourseList, ...data]
        this.canLoadTagCourseList = data.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action searchCourse = async (st, initial) => {
    try {
      const canLoadMore = initial || this.canLoadMoreSearch
      if (!canLoadMore) { return }
      const skip = initial ? 0 : this.skipSearch
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Courses } = await api.getCourses({
        skip,
        take: this.perPage,
        st,
      })
      runInAction(() => {
        this.searchCourseList = skip === 0 ? Courses : [...this.searchCourseList, ...Courses];
        this.canLoadMoreSearch = this.searchCourseList.length < Total;
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action setCurrentTag = tId => {
    this.tId = tId
  }

  @action getCourseList = async (initial, st = '') => {
    try {
      const canLoadMore = initial ? true : this.canLoadMore
      if (!canLoadMore) { return }
      const skip = initial ? 0 : this.skip
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Courses } = await api.getCourses({
        skip,
        take: this.perPage,
        tId: this.tId,
        st
      })
      runInAction(() => {
        this.courseList = skip === 0 ? Courses : [...this.courseList, ...Courses];
        this.skip = this.courseList.length;
        this.canLoadMore = this.courseList.length < Total;
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getHomeData = async take => {
    try {
      this.dataInitialLoading();
      const { Courses } = await api.getCourses({
        skip: 0,
        take,
      })
      runInAction(() => {
        this.homeData = Courses
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getCourseDetail = async param => {
    try {
      this.dataInitialLoading()
      const data = await api.getCourse(param)
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.Course)
        this.detailData = data
        this.subjectUser = undefined
        const { SubjectUser = {} } = data
        const { MemberId } = SubjectUser
        if (MemberId) {
          this.getSubjectUser(MemberId)
        }
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }

  @action getRelatedCourses = async param => {
    try {
      const data = await api.getRelatedCourses(param)
      runInAction(() => {
        const { Courses } = data
        this.relatedCourses = Courses
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }

  @action getSubjectUser = async MemberId => {
    try {
      await SubjectUserStore.getSubjectUser(MemberId)
      runInAction(() => {
        const { subjectUserDetail } = SubjectUserStore
        this.subjectUser = subjectUserDetail
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action addBookmark = async id => {
    try {
      const { p8Id } = await api.addBookmark({
        EntityType: 'Course',
        EntityId: id,
      })
      runInAction(() => {
        this.detailData.BookmarkId = p8Id
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action unbookmark = async BookmarkId => {
    try {
      await api.unbookmark({
        BookmarkId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = null
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action handleFollowSubjectUser = memberId => {
    if (this.detailData.SubjectUser?.MemberId === memberId) {
      const {
        detailData: {
          SubjectUser: { FollowedByMe }
        }
      } = this;
      this.detailData.SubjectUser.FollowedByMe = !FollowedByMe;
    }
  }

  @action getCourseTags = async () => {
    try {
      const data = await api.getEventsListBySpotCode({
        code: FEATURED.CourseShortcuts,
        skip: 0,
        take: DEFAULT_TAG_TAKE,
      })
      runInAction(() => {
        this.courseTagList = data
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action subscribeCourse = async payload => {
    try {
      const { p8Id, titleObj } = payload
      await api.subscribeCourse({
        EntityId: p8Id,
        Type: 'Course',
        ObjectName: titleObj,
      })
      const { detailData: { IsSubscribed, SubscribeNumber } } = this
      runInAction(() => {
        this.detailData.IsSubscribed = !IsSubscribed
        this.detailData.SubscribeNumber = SubscribeNumber + 1
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }
}

export default new CourseStore()
