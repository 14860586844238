/* eslint-disable camelcase */
import { strings, initI18next } from '@I18N/i18n';
import { dataFetch } from '@pier88health/p8h-common'
import {
  PIER_ICON,
  SHARE_TYPE,
  CREATOR_TYPE,
  UserTypeEnums,
  SHARE_WAY,
  shareIdMap,
  ImgUrlMap,
  SHARE_IMG_TYPE,
  OSS_AVATAR_SMALL,
  FeedEntityType,
  LanguageEnum,
  MONTH_NAME,
  BROAD_CAST_TYPE,
} from '@COMMON/constant';
import { getByteSplitString } from '@UTILS/utils';
import { renderVideoTime } from '@UTILS/common'
import trim from 'lodash/trim';
import { getAddress } from '@COMS/Address';
import DBConfig from '@CONFIG';

const SHARE_CONTENT_MAX = 150;
export const SHARE_INFO_TYPE = {
  Guide_ALL: 'guideShareInfo_All',
  Course_ALL: 'courseShareInfo_All',
  Profile_Friends: 'profileShareInfo_Friends',
  Profile_Circle: 'profileShareInfo_Circle',
  Opportunity_Friends: 'opportunityShareInfo_Friends',
  Opportunity_Circle: 'opportunityShareInfo_Circle',
  MedWord_Friends: 'medWordShareInfo_Friends',
  MedWord_Circle: 'medWordShareInfo_Circle',
  CustomAcademy_Friends: 'customAcademyShareInfo_Friends',
  CustomAcademy_Circle: 'customAcademyShareInfo_Circle',
  Activity_Friends: 'activityShareInfo_Friends',
  Activity_Circle: 'activityShareInfo_Circle',
  TagPageList_Friends: 'tagPageListShareInfo_Friends',
  TagPageList_Circle: 'tagPageListShareInfo_Circle',
  Conference_Friends: 'conferenceShareInfo_Friends',
  Conference_Circle: 'conferenceShareInfo_Circle',
  Invite_Friends: 'inviteShareInfo_Friends',
  Invite_Circle: 'inviteShareInfo_Circle',
  Default: 'default_ALL',
  Summary_ALL: 'summaryShareInfo_All',
  GroupShareInfo_Friends: 'groupShareInfo_Friends',
  GroupShareInfo_Circle: 'groupShareInfo_Circle',
};
export const SHARE_INFO_ACTION = [
  {
    shareType: SHARE_TYPE.Guide,
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Guide_ALL
  },
  {
    shareType: SHARE_TYPE.Course,
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Course_ALL
  },
  {
    shareType: SHARE_TYPE.Profile,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.Profile_Friends
  },
  {
    shareType: SHARE_TYPE.Profile,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Profile_Circle
  },
  {
    shareType: SHARE_TYPE.Opportunity,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.Opportunity_Friends
  },
  {
    shareType: SHARE_TYPE.Opportunity,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Opportunity_Circle
  },
  {
    shareType: SHARE_TYPE.Activity,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.Activity_Friends
  },
  {
    shareType: SHARE_TYPE.Activity,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Activity_Circle
  },
  {
    shareType: SHARE_TYPE.Summary,
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Summary_ALL
  },
  {
    shareType: SHARE_TYPE.MedWord,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.MedWord_Friends
  },
  {
    shareType: SHARE_TYPE.MedWord,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.MedWord_Circle
  },
  {
    shareType: SHARE_TYPE.CustomAcademy,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.CustomAcademy_Friends
  },
  {
    shareType: SHARE_TYPE.CustomAcademy,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.CustomAcademy_Circle
  },
  {
    shareType: SHARE_TYPE.TagPageList,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.TagPageList_Friends
  },
  {
    shareType: SHARE_TYPE.TagPageList,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.TagPageList_Circle
  },
  {
    shareType: SHARE_TYPE.Conference,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.Conference_Friends
  },
  {
    shareType: SHARE_TYPE.Conference,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Conference_Circle
  },
  {
    shareType: SHARE_TYPE.Invite,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.Invite_Friends
  },
  {
    shareType: SHARE_TYPE.Invite,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.Invite_Circle
  },
  {
    shareType: SHARE_TYPE.Group,
    shareId: [SHARE_WAY.wechatFriends],
    shareInfoType: SHARE_INFO_TYPE.GroupShareInfo_Friends
  },
  {
    shareType: SHARE_TYPE.Group,
    shareId: [SHARE_WAY.wechatCircle],
    shareInfoType: SHARE_INFO_TYPE.GroupShareInfo_Circle
  },
];

export function getNameFromObj(obj, language) {
  const name = obj?.Name;
  const isOjb = Object.prototype.toString.call(name) === '[object Object]';
  return isOjb ? name[language] : name;
}
export const GetInstitution = (data, language) => {
  const { Affiliation, Education } = data;
  return data.UserType === UserTypeEnums.Student
    ? getNameFromObj(Education, language)
    : getNameFromObj(Affiliation, language);
};

const GetUserInstInfo = (data, language) => {
  const { Position } = data;
  const institution = GetInstitution(data, language);
  const position = data.UserType === UserTypeEnums.Student
    ? strings('userInfo.student')
    : Position && Position[language];
  return { preInfo: position || '', subInfo: institution || '' };
};

const GetUserDesc = (data, language) => {
  const { preInfo, subInfo } = GetUserInstInfo(data, language);
  return trim(`${preInfo} | ${subInfo}`, ' | ');
};

// eslint-disable-next-line camelcase
const getSummaryShareInfo_All = shareInfoParams => {
  const { param = {}, appName } = shareInfoParams;
  const { data = {} } = param;
  const { Title } = data;
  return {
    title: Title,
    description: appName
  };
};
// eslint-disable-next-line camelcase
const getGuideShareInfo_All = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {}, language } = param;
  const { Title = {}, Setter = {} } = data;
  return {
    title: dataFetch.getObjProperty(Title, language),
    description: dataFetch.getObjProperty(Setter, language)
  };
};
// eslint-disable-next-line camelcase
const getCourseShareInfo_All = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {}, language } = param;
  const { Title = {}, Description, BroadCastType, Files: { Files }, ViewCount } = data;
  const title = dataFetch.getObjProperty(Title, language)
  const description = dataFetch.getObjProperty(Description, language)
  if (BroadCastType === BROAD_CAST_TYPE.Live) {
    return {
      title: `${strings('academy.app_live')} ${title}`,
      description,
    }
  }
  const seconds = Files?.reduce((acc, cur) => acc + cur.Length, 0) || 0
  const mins = parseInt(seconds / 60, 10) + 1
  const time = language === LanguageEnum.zh ? renderVideoTime(seconds) : mins
  return {
    title,
    description: `${strings('academy.video_time', { time })}\n${strings('academy.video_views', { count: ViewCount })}`,
  }
};
// eslint-disable-next-line camelcase
const getProfileShareInfo_Friends = shareInfoParams => {
  const { param = {}, appendProfile } = shareInfoParams;
  const { data = {}, language } = param;
  const { DisplayName = {} } = data;
  return {
    title: dataFetch.getObjProperty(DisplayName, language) + appendProfile,
    description: GetUserDesc(data, language)
  };
};
// eslint-disable-next-line camelcase
const getProfileShareInfo_Circle = shareInfoParams => {
  const { param = {}, headProfile, appendProfile } = shareInfoParams;
  const { data = {}, language } = param;
  const { DisplayName = {} } = data;
  return {
    title: headProfile + dataFetch.getObjProperty(DisplayName, language) + appendProfile,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getOpportunityShareInfo_Friends = shareInfoParams => {
  const { param = {}, publisher } = shareInfoParams;
  const { data = {}, language } = param;
  const { Title, Institution = {} } = data;
  const { Name = {} } = Institution;
  return {
    title: Title,
    description: `${publisher}: ${dataFetch.getObjProperty(Name, language)}`
  };
};
// eslint-disable-next-line camelcase
const getOpportunityShareInfo_Circle = shareInfoParams => {
  const { param = {}, headProfile } = shareInfoParams;
  const { data = {} } = param;
  const { Title } = data;
  return {
    title: headProfile + Title,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getActivityShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {}, shareType, language } = param;
  const { Title, ContentSummary, EntityType } = data;
  const activityTitle = FeedEntityType.Article === EntityType ? Title : ContentSummary;
  const activityDescription = getActivityDescription(data, shareType, language);
  return {
    title: activityTitle,
    description: activityDescription
  };
};
// eslint-disable-next-line camelcase
const getActivityShareInfo_Circle = shareInfoParams => {
  const { param = {}, headProfile } = shareInfoParams;
  const { data = {} } = param;
  const { Title, EntityType, ContentSummary } = data;
  const title = FeedEntityType.Article === EntityType ? Title : ContentSummary;
  return {
    title: headProfile + title,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getMedWordShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {} } = param;
  const { Word, Translation } = data;
  const wordTitle = `【${strings('word.tag')}】${Word} ${Translation}`;
  const wordDescription = strings('word.description');
  return {
    title: wordTitle,
    description: wordDescription
  };
};
// eslint-disable-next-line camelcase
const getMedWordShareInfo_Circle = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {} } = param;
  const { Word, Translation } = data;
  const wordTitle = `【${strings('word.tag')}】${Word} ${Translation}`;
  return {
    title: wordTitle,
    description: ''
  };
};
const getGroupShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {}, language } = param;
  const { GroupName, Country, State, City } = data
  const address = getAddress({ city: City, country: Country, state: State, language })
  return {
    title: GroupName?.[language],
    description: address
  }
}
const getGroupShareInfo_Circle = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data = {}, language } = param;
  const { GroupName, Country, State, City } = data
  const address = getAddress({ city: City, country: Country, state: State, language })
  return {
    title: GroupName?.[language],
    description: ''
  }
}
// eslint-disable-next-line camelcase
const getCustomAcademyShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Name, Description } = {}, language } = param;
  return {
    title: `${strings('common.app_name')}｜${Name?.[language]}`,
    description: Description?.[language]
  };
};
// eslint-disable-next-line camelcase
const getCustomAcademyShareInfo_Circle = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Name } = {}, language } = param;
  return {
    title: `${strings('common.app_name')}｜${Name?.[language]}`,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getTagPageListShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Name, Description } = {}, language } = param;
  return {
    title: `${strings('common.app_name')}｜#${Name?.[language]}`,
    description: Description?.[language] || strings('common.app_name')
  };
};
// eslint-disable-next-line camelcase
const getTagPageListShareInfo_Circle = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Name } = {}, language } = param;
  return {
    title: `${strings('common.app_name')}｜#${Name?.[language]}`,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getConferenceShareInfo_Friends = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Title, StartDate, City, Country, State } = {}, language } = param;
  const address = getAddress({ city: City, country: Country, state: State, language })
  const d = new Date(StartDate)
  const s = !StartDate ? ''
    : `${strings('meetup.share_date', {
      month: language === LanguageEnum.zh ? d.getMonth() + 1 : MONTH_NAME[d.getMonth()],
      day: d.getDate(),
      address
    })}`
  return {
    title: Title,
    description: s
  };
};
// eslint-disable-next-line camelcase
const getConferenceShareInfo_Circle = shareInfoParams => {
  const { param = {} } = shareInfoParams;
  const { data: { Title } = {} } = param;
  return {
    title: `${strings('common.app_name')}｜${Title}`,
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getInviteShareInfo_Friends = () => {
  return {
    title: strings('common.share_invite_title'),
    description: strings('common.share_invite_desc')
  };
};
// eslint-disable-next-line camelcase
const getInviteShareInfo_Circle = () => {
  return {
    title: strings('common.share_invite_circle'),
    description: ''
  };
};
// eslint-disable-next-line camelcase
const getDefault_All = shareInfoParams => {
  const { data = {} } = shareInfoParams;
  const { ContentSummary } = data;
  return {
    title: ContentSummary,
    description: ContentSummary
  };
};
const getActivityDescription = (data, shareType, language) => {
  const {
    ContentSummary,
    EntityType,
    CreatorType,
    Institution = {},
    Creator = {}
  } = data;
  const { Name = {} } = Institution;
  const { DisplayName = {}, Affiliation = {} } = Creator;
  const { Name: affiliationName = {} } = Affiliation;
  if (EntityType === FeedEntityType.Article) {
    return ContentSummary;
  }
  if (shareType === SHARE_TYPE.Activity) {
    let activityDescription;
    if (CreatorType === CREATOR_TYPE.Institution) {
      activityDescription = `${dataFetch.getObjProperty(Name, language)}`;
    }
    if (CreatorType === CREATOR_TYPE.Individual) {
      activityDescription = `${dataFetch.getObjProperty(DisplayName, language)} | `;
    }
    const affName = dataFetch.getObjProperty(affiliationName, language);
    if (affName) {
      activityDescription += affName;
    }
    return activityDescription;
  }
  return ''
};
const shareInfoConfig = {
  summaryShareInfo_All: getSummaryShareInfo_All,
  guideShareInfo_All: getGuideShareInfo_All,
  courseShareInfo_All: getCourseShareInfo_All,
  profileShareInfo_Friends: getProfileShareInfo_Friends,
  profileShareInfo_Circle: getProfileShareInfo_Circle,
  opportunityShareInfo_Friends: getOpportunityShareInfo_Friends,
  opportunityShareInfo_Circle: getOpportunityShareInfo_Circle,
  activityShareInfo_Friends: getActivityShareInfo_Friends,
  activityShareInfo_Circle: getActivityShareInfo_Circle,
  medWordShareInfo_Friends: getMedWordShareInfo_Friends,
  medWordShareInfo_Circle: getMedWordShareInfo_Circle,
  customAcademyShareInfo_Friends: getCustomAcademyShareInfo_Friends,
  customAcademyShareInfo_Circle: getCustomAcademyShareInfo_Circle,
  tagPageListShareInfo_Friends: getTagPageListShareInfo_Friends,
  tagPageListShareInfo_Circle: getTagPageListShareInfo_Circle,
  conferenceShareInfo_Friends: getConferenceShareInfo_Friends,
  conferenceShareInfo_Circle: getConferenceShareInfo_Circle,
  inviteShareInfo_Friends: getInviteShareInfo_Friends,
  inviteShareInfo_Circle: getInviteShareInfo_Circle,
  groupShareInfo_Friends: getGroupShareInfo_Friends,
  groupShareInfo_Circle: getGroupShareInfo_Circle,
  default_ALL: getDefault_All
};
export const getShareInfo = param => {
  const { shareType, shareWay, language } = param;
  initI18next(language);
  const appName = strings('common.app_name');
  const headProfile = strings('common.headProfile');
  const appendProfile = strings('common.appendProfile');
  const publisher = strings('feeds.publisher');
  const shareInfoParams = {
    appName,
    headProfile,
    appendProfile,
    publisher,
    param
  };
  const shareAction = SHARE_INFO_ACTION.find(action => action.shareType === shareType && action.shareId.includes(shareWay));
  const InfoType = !shareAction
    ? SHARE_INFO_ACTION.Default
    : shareAction.shareInfoType;
  return shareInfoConfig[InfoType](shareInfoParams);
};

export const SHARE_IMG_ACTION = [
  {
    shareType: SHARE_TYPE.Profile,
    AvatarVersion: [true],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Profile
  },
  {
    shareType: SHARE_TYPE.Course,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Course
  },
  {
    shareType: SHARE_TYPE.Activity,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Activity
  },
  {
    shareType: SHARE_TYPE.Opportunity,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends],
    imgType: SHARE_IMG_TYPE.Opportunity
  },
  {
    shareType: SHARE_TYPE.MedWord,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.MedWord
  },
  {
    shareType: SHARE_TYPE.CustomAcademy,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.CustomAcademy
  },
  {
    shareType: SHARE_TYPE.TagPageList,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.TagPageList
  },
  {
    shareType: SHARE_TYPE.Conference,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Conference
  },
  {
    shareType: SHARE_TYPE.Invite,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Invite
  },
  {
    shareType: SHARE_TYPE.Group,
    AvatarVersion: [true, false],
    shareId: [SHARE_WAY.wechatFriends, SHARE_WAY.wechatCircle],
    imgType: SHARE_IMG_TYPE.Group
  },
];

const getId = (data, shareType) => {
  return data && data[shareIdMap[shareType]];
};
const getDefaultImgUrl = () => PIER_ICON.SHARE_CARD_COVER;
const getProfileImgUlr = shareImgParams => {
  const { data, shareType } = shareImgParams;
  const UserId = getId(data, shareIdMap.UserId);
  return UserId ? `${ImgUrlMap[shareType]}${UserId}.jpg` : PIER_ICON.SHARE_CARD_COVER;
};
const getCourseImgUrl = shareImgParams => {
  const { data = {}, shareType } = shareImgParams;
  const { PreviewFileName } = data;
  return PreviewFileName
    ? `${ImgUrlMap[shareType]}${PreviewFileName}`
    : `${PIER_ICON.SHARE_CARD_COVER}`;
};
const getOpportunityImg = shareImgParams => {
  const { data = {}, shareType } = shareImgParams;
  const { Institution = {} } = data;
  const { LogoFilename } = Institution;
  return LogoFilename
    ? `${ImgUrlMap[shareType]}${LogoFilename}`
    : `${PIER_ICON.SHARE_CARD_COVER}`;
};
const getMedWordImg = shareImgParams => {
  const { data = {}, shareType } = shareImgParams;
  const { ImageFileName } = data;
  return ImageFileName
    ? `${ImgUrlMap[shareType]}${ImageFileName}`
    : `${PIER_ICON.SHARE_CARD_COVER}`;
};
const getCustomAcademyImg = shareImgParams => {
  const { data: { ShareImage } = {}, shareType, language } = shareImgParams;
  const shareImage = ShareImage?.[language]
  return shareImage
    ? `${ImgUrlMap[shareType]}${shareImage}`
    : `${PIER_ICON.SHARE_CARD_COVER}`;
};
const getTagPageListImg = shareImgParams => {
  const { data: { IconFileNames } = {}, shareType, language } = shareImgParams;
  const shareImage = IconFileNames?.[language]
  return shareImage
    ? `${ImgUrlMap[shareType]}${shareImage}`
    : `${PIER_ICON.SHARE_CARD_COVER}`;
};
const getConferenceImg = () => `${DBConfig.staticShare}meetup.png`;
const getInviteImg = () => DBConfig.shareInviteIcon;
const getGroupImg = shareImgParams => {
  const { data = {} } = shareImgParams
  const { LogoFilename, LogoVersion } = data
  return LogoFilename
    ? `${DBConfig.staticGroupImageUrl}${LogoFilename}?t=${LogoVersion}?x-oss-process=image/resize,w_50,h_50`
    : PIER_ICON.SHARE_CARD_COVER
}
const getActivityImgUrl = shareImgParams => {
  const { data = {}, shareType } = shareImgParams;
  const {
    PictureFileNames = [],
    GroupId,
    DateBucket,
    CoverImageFileName = '',
    EntityType,
  } = data;
  const articleTypeScene = FeedEntityType.Article === EntityType && !!CoverImageFileName;
  const defaultScene = PictureFileNames?.length;
  let imgUrl = `${PIER_ICON.SHARE_CARD_COVER}`;

  articleTypeScene
    && (imgUrl = `${ImgUrlMap[shareType]}${GroupId}/${DateBucket}/${CoverImageFileName}`);
  defaultScene
    && (imgUrl = `${ImgUrlMap[shareType]}${GroupId}/${DateBucket}/${
      PictureFileNames[0]
    }`);
  return imgUrl;
};
const imgConfig = {
  defaultImgUrl: getDefaultImgUrl,
  profileImgUrl: getProfileImgUlr,
  courseImgUrl: getCourseImgUrl,
  activityImgUrl: getActivityImgUrl,
  opportunityImgUrl: getOpportunityImg,
  medWordImgUrl: getMedWordImg,
  customAcademyImgUrl: getCustomAcademyImg,
  tagPageListImgUrl: getTagPageListImg,
  conferenceImgUrl: getConferenceImg,
  inviteImgUrl: getInviteImg,
  group: getGroupImg,
};
export const getImgUrl = param => {
  const { data = {}, shareType, shareWay } = param;
  const { AvatarVersion } = data;
  const imgAction = SHARE_IMG_ACTION.find(
    action => action.shareType === shareType && action.AvatarVersion.includes(!!AvatarVersion) && action.shareId.includes(shareWay)
  );
  const imgType = imgAction ? imgAction.imgType : SHARE_IMG_TYPE.Default;
  return imgConfig[imgType](param);
};

export const getWxSecondShareInfoData = param => {
  const {
    defaultShareInfo = {},
    data = {},
    shareType = {},
    language = 'zh'
  } = param;
  const shareFriendsInfo = { ...defaultShareInfo };
  const shareCircleInfo = { ...defaultShareInfo };

  const shareFriendsInfoParam = {
    data,
    shareType,
    shareWay: SHARE_WAY.wechatFriends,
    language
  };
  const shareCircleInfoParam = {
    data,
    shareType,
    shareWay: SHARE_WAY.wechatCircle,
    language
  };

  const wechatShareFriendsInfo = getShareInfo(shareFriendsInfoParam);
  const wechatShareCircleInfo = getShareInfo(shareCircleInfoParam);
  const wechatShareFriendsImgUrl = getImgUrl(shareFriendsInfoParam);
  const wechatShareCircleImgUrl = getImgUrl(shareCircleInfoParam);

  const { title: fTitle, description: fDescription } = wechatShareFriendsInfo;
  const { title: cTitle, description: cDescription } = wechatShareCircleInfo;

  shareFriendsInfo.title = getByteSplitString(fTitle, SHARE_CONTENT_MAX);
  shareFriendsInfo.desc = getByteSplitString(fDescription, SHARE_CONTENT_MAX);
  wechatShareFriendsImgUrl
    && (shareFriendsInfo.imgUrl = `${wechatShareFriendsImgUrl}?${OSS_AVATAR_SMALL}`);

  shareCircleInfo.title = getByteSplitString(cTitle, SHARE_CONTENT_MAX);
  shareCircleInfo.desc = getByteSplitString(cDescription, SHARE_CONTENT_MAX);
  wechatShareCircleImgUrl
    && (shareCircleInfo.imgUrl = `${wechatShareCircleImgUrl}?${OSS_AVATAR_SMALL}`);
  return {
    shareFriendsInfo,
    shareCircleInfo
  };
};
