import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { strings } from '@I18N/i18n';
import { REGISTER_ROUTER, SOURCE } from '@COMMON/constant';
import Loading from '@COMS/Load/LoadingSpinner';
import ErrorBoundary from '@COMS/ErrorBoundary';
import { queryString } from '@UTILS/utils'
import Nav from './Nav';

import Modal from './auth/Modal';
import LoginModal from './auth/LoginModal';

import Login from './auth/Login';
import BaseLogin from './auth/BaseLogin';
import InviteRegister from './auth/InviteRegister';

const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import('./NotFound'));
const Feeds = lazy(() => import('./feeds/index'));

const ModalLogin = LoginModal(BaseLogin);
const UserLogin = Login(BaseLogin);
const InviteLogin = InviteRegister(BaseLogin)

toast.configure({
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  toastClassName: 'toast_body',
  closeButton: false
});

// AuthorizedRoute
const CustomRoute = props => {
  const { isLogin, needPermission, hasPermission } = props;
  const { pathname } = window.location
  // if (!isLogin) {
  //   return <Redirect to={`/login?redirect=${pathname}`} />;
  // }
  // if (needPermission && !hasPermission) {
  //   return <Redirect to='/' />;
  // }
  return <Route {...props} />;
};

const Summary = lazy(() => import('./Summary'));
const ActivateEmailUser = lazy(() => import('./ActivateEmailUser'));
const SafariPage = lazy(() => import('./SafariPage'));
const DownloadApp = lazy(() => import('./DownloadApp'));
const DefaultPage = lazy(() => import('./DefaultPage'));
// H5

const FeedList = lazy(() => import('./feeds/FeedList'));
const ActivityList = lazy(() => import('./feeds/ActivityList'));
const FeedLikeList = lazy(() => import('./feeds/FeedLikeList'));
const FeedDetail = lazy(() => import('./feeds/FeedDetail'));
const OpportunityList = lazy(() => import('./opportunity/OpportunityList'));
const TagPageList = lazy(() => import('./feeds/TagPageList'));
const PostComment = lazy(() => import('./feeds/PostComment'));
const InstitutionRankingList = lazy(() => import('./opportunity/InstitutionRankingList'));
const Opportunity = lazy(() => import('./opportunity'));
const OpportunityDetail = lazy(() => import('./opportunity/OpportunityDetail'));
const FriendDetail = lazy(() => import('./friend/FriendDetail'));
const FollowerList = lazy(() => import('./friend/FollowerList'));
const FriendList = lazy(() => import('./friend/FriendList'));
const FollowingList = lazy(() => import('./friend/FollowingList'));
const GroupDetail = lazy(() => import('./group'));
const GroupSceneryList = lazy(() => import('./group/GroupSceneryList'));
const GroupIntroduction = lazy(() => import('./group/GroupIntroduction'));
const GroupOpportunity = lazy(() => import('./group/GroupOpportunity'));
const GroupFeed = lazy(() => import('./group/GroupFeed'));
const ProfessionList = lazy(() => import('./profile/ProfessionList'));
const EducationList = lazy(() => import('./profile/EducationList'));
const FeaturedContent = lazy(() => import('./profile/FeaturedContent'));
const BaseInfo = lazy(() => import('./profile/BaseInfo'));
const H5DefaultPage = lazy(() => import('./H5DefaultPage'));
const GuideList = lazy(() => import('./guide/GuideList'));
const GuideDetail = lazy(() => import('./guide/GuideDetail'));
const CourseList = lazy(() => import('./course/CourseList'));
const CourseSearchList = lazy(() => import('./course/CourseSearchList'));
const CourseDetail = lazy(() => import('./course/CourseDetail'));
const PDFView = lazy(() => import('./PDFView'));
const FileCareView = lazy(() => import('./FileCareView'));
const ConferenceDetail = lazy(() => import('./conference/ConferenceDetail'));
const MedWordDetail = lazy(() => import('./word/MedWordDetail'));
const MedWordList = lazy(() => import('./word/MedWordList'));
const CustomAcademy = lazy(() => import('./customAcademy/CustomAcademy'))
const CustomListByTagIds = lazy(() => import('./customAcademy/CustomListByTagIds'))
const CustomSearch = lazy(() => import('./customAcademy/CustomSearch'))
const ArticleList = lazy(() => import('./feeds/ArticleList'))
const HotTopicList = lazy(() => import('./event/HotTopicList'))
const MedicineCategoryList = lazy(() => import('./medicine/MedicineCategoryList'))
const MedicineDetail = lazy(() => import('./medicine/MedicineDetail'))
const MedicinePandect = lazy(() => import('./medicine/MedicinePandect'))
const SecondMedCategory = lazy(() => import('./medicine/SecondMedCategory'))
const MedicineList = lazy(() => import('./medicine/MedicineList'))
const PrefacePage = lazy(() => import('./medicine/PrefacePage'))
const Meetup = lazy(() => import('./conference'));
const Bookmarks = lazy(() => import('./bookmark'));

const Live = lazy(() => import('./course/Live'));
const appContainer = { height: '100%' }

@inject('appStore')
@withRouter
@observer
class App extends Component {
  showModal = () => {
    this.props.appStore.setBindPhoneModal(true);
  };

  hideModal = () => {
    this.props.appStore.setBindPhoneModal(false);
  };

  resizeContainer = () => {
    document.querySelector('#root').style.paddingTop = 0
  }

  render() {
    const {
      appStore: { token: isLogin, permissionsHash }, location: { pathname }
    } = this.props;
    const { SwitchH5Module, NewsFeed, ViewOpportunity, ViewCourse, ViewGuide } = permissionsHash || {}
    const { s, title } = queryString()
    const isCare = s === SOURCE.care
    const isLoginRouter = window.location.pathname.indexOf('login') > 0
    if (!isLogin && !isLoginRouter && !isCare) {
      return <Loading />;
    }
    if ((isLogin && !SwitchH5Module) || pathname.includes(REGISTER_ROUTER)) {
      this.resizeContainer()
    }
    const appTitle = isCare ? title : strings('common.headtitle')
    return (
      <div style={appContainer} className='routeContainer'>
        <Helmet>
          <title>{appTitle}</title>
          <meta name='description' content={strings('common.headdesc')} />
          <meta property='og:title' content={appTitle} />
          <meta property='og:description' content={strings('common.headdesc')} />
          <meta property='og:image' content='http://prodimg.p88health.com/static/img/share/share_card_cover.png' />
        </Helmet>
        <Modal show={this.props.appStore.isShowModal} handleClose={this.hideModal}>
          <ModalLogin handleClose={this.hideModal} />
        </Modal>
        {!pathname.includes(REGISTER_ROUTER) && isLogin && SwitchH5Module && <Nav permissionsHash={permissionsHash} />}
        <Switch>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Route exact path='/notFound' component={NotFound} />
              <Route exact path='/login' component={UserLogin} />
              <Route exact path='/main' component={UserLogin} />
              <CustomRoute path='/activity/:id' component={FeedDetail} isLogin={isLogin} />
              <CustomRoute path='/guide/:id' component={GuideDetail} isLogin={isLogin} />
              <CustomRoute path='/course/:id' component={CourseDetail} isLogin={isLogin} />
              <CustomRoute path='/tagPageList/:id' component={TagPageList} isLogin={isLogin} />
              <Route path='/summary/:id' component={Summary} />
              <CustomRoute path='/userProfile/:id' component={FriendDetail} isLogin={isLogin} />
              <CustomRoute path='/opportunity/:id' component={OpportunityDetail} isLogin={isLogin} />
              <Route path='/activateEmail/:aId' component={ActivateEmailUser} />
              <Route path='/summaryLink/:id' component={SafariPage} />
              <Route path='/activityLink/:id' component={SafariPage} />
              <Route path='/opportunityLink/:id' component={SafariPage} />
              <Route path='/medWordLink/:id' component={SafariPage} />
              <Route path='/userProfileLink/:id' component={SafariPage} />
              <Route path='/guideLink/:id' component={SafariPage} />
              <Route path='/courseLink/:id' component={SafariPage} />
              <Route path='/conferenceLink/:id' component={SafariPage} />
              <Route path='/customAcademyLink' component={SafariPage} />
              <Route path='/customAcademyLink/:id' component={SafariPage} />
              <Route path='/consultationDetailLink/:id' component={SafariPage} />
              <Route path='/groupLink/:id' component={SafariPage} />
              <Route path='/authenticationLink' component={SafariPage} />
              <Route path='/pointCenterLink' component={SafariPage} />
              <Route path='/careChatLink/:id' component={SafariPage} />
              <Route path='/forwardRouter/:isDownLoad' component={SafariPage} />
              <Route path='/downloadApp' component={DownloadApp} />
              <Route path='/defaultPage' component={DefaultPage} />

              <Route path='/register/:id' component={InviteLogin} />

              {/* h5 */}
              <CustomRoute path='/feeds' component={Feeds} isLogin={isLogin} />
              <CustomRoute path='/feedList' component={FeedList} isLogin={isLogin} needPermission hasPermission={NewsFeed} />
              <CustomRoute path='/activityList/:id' component={ActivityList} isLogin={isLogin} needPermission />
              <CustomRoute path='/opportunityList' component={OpportunityList} isLogin={isLogin} needPermission hasPermission={ViewOpportunity} />
              <CustomRoute path='/institutionRankingList/:type' component={InstitutionRankingList} isLogin={isLogin} needPermission hasPermission={ViewOpportunity} />
              <CustomRoute path='/opportunityIndex' component={Opportunity} isLogin={isLogin} needPermission hasPermission={ViewOpportunity} />
              <CustomRoute path={['/guideList/:tId', '/guideList']} component={GuideList} isLogin={isLogin} needPermission hasPermission={ViewGuide} />
              <CustomRoute path={['/courseList/:tId', '/courseList']} component={CourseList} isLogin={isLogin} needPermission hasPermission={ViewCourse} />
              <CustomRoute path='/courseSearchList' component={CourseSearchList} isLogin={isLogin} needPermission hasPermission={ViewCourse} />
              <CustomRoute path='/feedLikeList/:id' component={FeedLikeList} isLogin={isLogin} />
              <CustomRoute path='/feedItemDetail/:id' component={FeedDetail} isLogin={isLogin} />
              <CustomRoute path='/friendDetail/:id' component={FriendDetail} isLogin={isLogin} />
              <CustomRoute path='/groupDetail/:id' component={GroupDetail} isLogin={isLogin} />
              <CustomRoute path='/groupSceneryList' component={GroupSceneryList} isLogin={isLogin} />
              <CustomRoute path='/groupIntroduction/:id' component={GroupIntroduction} isLogin={isLogin} />
              <CustomRoute path='/groupOpportunity/:id' component={GroupOpportunity} isLogin={isLogin} />
              <CustomRoute path='/groupFeed/:id' component={GroupFeed} isLogin={isLogin} />
              <CustomRoute path='/professionList/:id' component={ProfessionList} isLogin={isLogin} />
              <CustomRoute path='/educationList/:id' component={EducationList} isLogin={isLogin} />
              <CustomRoute path='/h5DefaultPage' component={H5DefaultPage} isLogin={isLogin} />
              <CustomRoute path='/guideDetail/:id' component={GuideDetail} isLogin={isLogin} />
              <CustomRoute path='/opportunityDetail/:id' component={OpportunityDetail} isLogin={isLogin} />
              <CustomRoute path='/courseDetail/:id' component={CourseDetail} isLogin={isLogin} />
              <CustomRoute path='/pdfViewer' component={PDFView} isLogin={isLogin} />
              <CustomRoute path='/fileCareView' component={FileCareView} isLogin={isLogin} />

              <CustomRoute path='/featuredContent/:id' component={FeaturedContent} isLogin={isLogin} />
              <CustomRoute path='/followerList/:id' component={FollowerList} isLogin={isLogin} />
              <CustomRoute path='/followingList/:id' component={FollowingList} isLogin={isLogin} />
              <CustomRoute path='/friendList/:id' component={FriendList} isLogin={isLogin} />
              <CustomRoute path='/postComment' component={PostComment} isLogin={isLogin} />
              <CustomRoute path='/baseInfo' component={BaseInfo} isLogin={isLogin} />
              <CustomRoute path='/conferenceDetail/:id' component={ConferenceDetail} isLogin={isLogin} />
              <CustomRoute path='/medWordDetail/:id' component={MedWordDetail} isLogin={isLogin} />
              <CustomRoute path={['/medWordList/:tId', '/medWordList']} component={MedWordList} isLogin={isLogin} />
              <CustomRoute path='/customListByTagIds' component={CustomListByTagIds} isLogin={isLogin} />
              <CustomRoute path='/customSearch' component={CustomSearch} isLogin={isLogin} />
              <CustomRoute path={['/customAcademy/:academyType', '/customAcademy']} component={CustomAcademy} isLogin={isLogin} />
              <CustomRoute path='/coronavirus' component={CustomAcademy} isLogin={isLogin} />
              <CustomRoute path='/covid-19-information-center' component={CustomAcademy} isLogin={isLogin} />
              <CustomRoute path='/covid-19' component={CustomAcademy} isLogin={isLogin} />
              <CustomRoute path='/hotTopicList' component={HotTopicList} isLogin={isLogin} />
              <CustomRoute path='/meetup' component={Meetup} isLogin={isLogin} />
              <CustomRoute path='/bookmarks' component={Bookmarks} isLogin={isLogin} />
              <CustomRoute path='/live' component={Live} isLogin={isLogin} />
              <CustomRoute path='/medicineCategoryList' component={MedicineCategoryList} isLogin={isLogin} />
              <CustomRoute path='/medicineDetail/:mId' component={MedicineDetail} isLogin={isLogin} />
              <CustomRoute path='/medicinePandect/:pId' component={MedicinePandect} isLogin={isLogin} />
              <CustomRoute path={['/secondMedCategory/:pId/:hasPandect/', '/secondMedCategory/:pId/']} component={SecondMedCategory} isLogin={isLogin} />
              <CustomRoute path={['/medicineList/:pId/:sId', '/medicineList/:pId', '/medicineList']} component={MedicineList} isLogin={isLogin} />
              <CustomRoute path='/prefacePage' component={PrefacePage} isLogin={isLogin} />

              {/* operator */}
              <CustomRoute path='/articleList/:tId' component={ArticleList} isLogin={isLogin} />
              <Route exact path='/' component={Home} isLogin={isLogin} />
            </Suspense>
          </ErrorBoundary>
        </Switch>
      </div>
    );
  }
}

export default App;
