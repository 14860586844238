// eslint-disable-next-line
export const OPPORTUNITY_TYPE_ENUMS = {
  ClinicalObservation: 'ClinicalObservation',
  Conference: 'Conference',
  Job: 'Job',
  Consulting: 'Consulting',
  Internship: 'Internship',
  Speech: 'Speech',
  Training: 'Training'
};
export const OPPORTUNITY_GRID_TAKE = 50
export const DEFAULT_BANNER_TAKE = 5

export const CONFERENCE = 'Conference'

export const TAGNAME = '进修攻略'

export const RANK_TYPE = {
  Education: 'Education',
  Hospital: 'Hospital'
}
