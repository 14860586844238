import React from 'react';
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'react-router-redux';
import { I18nextProvider } from 'react-i18next';
import { Route, Router, Switch, Redirect, Link } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { getI18nInstance } from '@I18N/i18n';

import store from '../models';
import App from '../pages/App';
import * as Tracker from '@UTILS/analysis'

const { routingStore } = store;
const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, routingStore);

Tracker.initAnalytics(history);

const webRoutes = () => (
  <I18nextProvider i18n={getI18nInstance()}>
    <Provider {...store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>
);

export default webRoutes;
