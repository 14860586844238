/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import * as dd from 'dingtalk-jsapi';
import { parserParams, isDingTalkBrowser, requestCodeDebug } from '@UTILS/dingtalk';
import { inject } from 'mobx-react';
import { toast } from 'react-toastify';
import { validation, dataFetch } from '@pier88health/p8h-common'
import { alert } from '@COMS/Modal';
import debounce from 'lodash.debounce';
import { strings, getLanguage } from '@I18N/i18n';
import api from '@API';
import { LanguageEnum } from '@COMMON/constant'
import '@STYLES/common.scss';
import '@STYLES/Login.scss';

const INPUT_NAME = 'phone'
const PASSWORD_MIN_LENGTH = 6

const alertStyle = { color: '#333', textAlign: 'left' }

const debugDiv = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: 50,
  height: 50,
  // backgroundColor: 'red'
};

const debugDiv2 = {
  position: 'absolute',
  right: 0,
  top: 0,
  width: 50,
  height: 50,
  // backgroundColor: 'blue'
};

@inject('appStore')
export default class BaseLogin extends Component {
  static defaultProps = {
    canShowTip: true,
    canShowProblem: true,
    submitText: strings('login.login_button'),
    registerTipText: strings('login.agree')
  }

  constructor(props) {
    super(props);
    const localShowPhone = window.sessionStorage.getItem('phone')
    const localCode = window.sessionStorage.getItem('mobileCode')
    const phone = this.props.phone || localShowPhone?.replace(/\s/g, '') || ''
    const language = getLanguage()
    this.state = {
      phone,
      mobileCode: this.props.mobileCode || localCode || '',
      count: 60,
      active: true,
      canPress: true,
      showPhone: localShowPhone || '',
      language,
      isPhoneLogin: language === LanguageEnum.zh,
      email: '',
      emailPassword: '',
      validEmail: false,
      validPassword: false,
      validPhone: validation.isPhoneNumber(phone),
      validAuthCode: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.signUpSubmit = debounce(this.signUpSubmit, 500, {
      leading: true,
      trailing: false
    });
    this.handleClick = debounce(this.handleClick, 500, {
      leading: true,
      trailing: false
    });
  }

  onKeyDown(event) {
    const { value, maxLength } = this.codeInput;
    const key = event.keyCode || event.charCode;
    const charcodestring = String.fromCharCode(event.which);
    const regex = new RegExp('^[0-9]+$');

    if (
      key === 8
      || key === 46
      || key === 13
      || key === 37
      || key === 39
      || key === 9
    ) {
      return true;
    }

    if (value.length === maxLength) {
      event.preventDefault();
      return false;
    }

    if (!regex.test(charcodestring)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onDebugDingAuthCode = async () => {
    if (!isDingTalkBrowser) {
      toast.info('NoInDingDing')
      return;
    }
    const params = parserParams(this.props.location.search)
    try {
      const { appkey: appKey } = params
      const authCode = await requestCodeDebug(appKey);
      toast.info(authCode)
      const userinfo = await api.dingLogin({ authCode, appKey });
      toast.info(JSON.stringify(userinfo), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  onDebugClick = async () => {
    const params = parserParams(this.props.location.search)
    const { appkey } = params
    const isDing = isDingTalkBrowser ? 'true' : 'false'
    const msg = `${isDing}, ${dd.env.platform}, ${window?.location?.search}, ${window?.location?.href}`;
    toast.info(msg, {
      position: toast.POSITION.BOTTOM_CENTER
    })
  }

  showProblem = () => {
    this.props.appStore.setBindPhoneModal(false);
    alert(strings('login.problems'), (
      <div style={alertStyle}>
        <div className='alertClear'>
          {`${strings('login.problem1_pre')}"`}
          <a onClick={this.onClear}>{strings('common.clear')}</a>
          {`"${strings('login.problem1_last')}`}
        </div>
        <div className='problem2'>
          {strings('login.problem2_pre')}
        </div>
        <div>
          <div>{strings('login.hotline')}</div>
          <a href='tel:400-600-9959'>400-600-9959</a>
        </div>
        <div className='alertEmail'>
          <div>{strings('login.e-mail')}</div>
          <a href='mailto:customer@pier88health.com'>
            customer@pier88health.com
          </a>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={debugDiv} onClick={this.onDebugClick} />
          <div style={debugDiv2} onClick={this.onDebugDingAuthCode} />
        </div>
      </div>
    ), [{ text: strings('common.close') }])
  };

  onClear = () => {
    toast.success(strings('login.clear_cache'))
    this.props.appStore.clearToken();
    window.location.href = '/login?reset=true';
  }

  clearPhone = () => {
    this.setState({
      phone: '',
      showPhone: '',
      validPhone: false,
    });
    window.sessionStorage.setItem('phone', '')
    this.inputPhone.focus();
  };

  clearEmail = () => {
    this.setState({
      email: '',
      validEmail: false,
    });
    this.email.focus();
  };

  clearEmailPassword = () => {
    this.setState({
      emailPassword: '',
      validPassword: false,
    });
    this.emailPassword.focus();
  };

  handleClick = () => {
    const { getAuthCode } = this.props
    if (!this.state.active) {
      return;
    }
    let { count } = this.state;
    this.codeInput.focus();
    const timer = setInterval(() => {
      this.setState({ count: count--, active: false }, () => {
        if (count === 0) {
          clearInterval(timer);
          this.setState({
            active: true,
            count: 60
          });
        }
      });
    }, 1000);
    getAuthCode && getAuthCode(this.state.phone)
    // this.props.authStore.requestAuthCode(this.state.phone);
  };

  signUpSubmit = () => {
    const isValidated = this.checkIsValid()
    if (!isValidated) return
    this.setState({
      canPress: false
    })
    const { isPhoneLogin, mobileCode, email, emailPassword } = this.state;
    const { onPressSubmit, emailLogin, isInvite } = this.props
    const Id = this.props.match?.params?.id
    if (isPhoneLogin) {
      onPressSubmit && onPressSubmit(mobileCode, Id, this.pushDownLoad)
    } else {
      emailLogin({
        UserName: email,
        Password: emailPassword,
        AcquireChannel: isInvite ? 'Invite' : 'H5'
      }, this.pushDownLoad)
    }
    setTimeout(() => {
      this.setState({
        canPress: true
      })
    }, 1000)
    // this.props.authStore.modalBindPhone(mobileCode);
  };

  pushDownLoad = () => {
    this.props.history.push('/downloadApp')
  }

  handleClose = () => {
    this.props.handleClose();
  };

  switchType = () => {
    const { isPhoneLogin } = this.state
    this.setState({
      isPhoneLogin: !isPhoneLogin,
    })
  }

  onEmailChange = ({ target }) => {
    const { value } = target;
    if (validation.isChinese(value)) {
      toast.error(strings('login.illegalEmail'))
    }
    const valid = !!value && validation.isEmail(value)
    this.setState({
      email: value,
      validEmail: valid,
    })
  }

  onEmailPwdChange = ({ target }) => {
    const { value } = target;
    const valid = !!value && value.length >= PASSWORD_MIN_LENGTH
    this.setState({
      emailPassword: value,
      validPassword: valid,
    })
  }

  checkIsValid = () => {
    const { isPhoneLogin, validEmail, validPhone, validAuthCode, validPassword } = this.state
    if (isPhoneLogin) {
      return validPhone && validAuthCode;
    }
    return validEmail && validPassword
  }

  handleInputChange({ target }) {
    const { phone, showPhone } = this.state
    const { name, value } = target;
    let showPhoneNumber = ''
    let realPhone = ''

    let val = value
    const isPhone = name === INPUT_NAME
    if (isPhone) {
      realPhone = val.replace(/\s/g, '')
      showPhoneNumber = dataFetch.formatPhoneNumber(realPhone) || ''
      val = realPhone
    }
    window.sessionStorage.setItem([name], value)
    const newState = {
      showPhone: isPhone ? showPhoneNumber : showPhone,
      [name]: val,
      canPress: true,
    }
    if (isPhone) {
      Object.assign(newState, {
        validPhone: validation.isPhoneNumber(val)
      })
    } else {
      Object.assign(newState, {
        validAuthCode: value.length === 4
      })
    }
    this.setState(newState);
  }

  render() {
    const {
      phone, mobileCode, count, active, canPress, showPhone, isPhoneLogin, email, emailPassword, validPhone, language
    } = this.state;
    const activeCode = validPhone && active
    const isValidated = this.checkIsValid()
    const { canShowTip, canShowProblem, submitText, registerTipText } = this.props
    return (
      <div className='user_register'>
        <div className='login_content'>
          {canShowTip && (
            <div>
              <h2 className='loginTitle'>
                <a className='active'>
                  {isPhoneLogin ? strings('login.phone_login') : strings('login.email_login')}
                </a>
                <span />
                <a onClick={this.switchType}>
                  {isPhoneLogin ? strings('login.email_login') : strings('login.phone_login')}
                </a>
              </h2>
              <p className='bindDesc'>{isPhoneLogin ? strings('login.phoneTips') : strings('login.emailTips')}</p>
            </div>
          )}

          {isPhoneLogin ? (
            <div>
              <div className='phone_number fromItem'>
                <span>+86</span>
                <div className='inputContainer'>
                  <input
                    ref={c => {
                      this.inputPhone = c;
                    }}
                    type='tel'
                    placeholder={strings('register.input_phone_number')}
                    maxLength='13'
                    pattern='^-?[0-9]\d*\.?\d*$'
                    name='phone'
                    value={showPhone}
                    onChange={this.handleInputChange}
                  />
                </div>
                <a
                  className={`clearInput ${!phone && 'hidden'}`}
                  onClick={this.clearPhone}
                />
              </div>
              <div className='vscode_message'>
                <div className='inputContainer'>
                  <input
                    ref={c => {
                      this.codeInput = c;
                    }}
                    type='tel'
                    placeholder={strings('register.input_verification_code')}
                    maxLength='4'
                    onKeyDown={this.onKeyDown}
                    name='mobileCode'
                    value={mobileCode}
                    onChange={this.handleInputChange}
                  />
                </div>
                <a
                  onClick={this.handleClick}
                  className={`${!activeCode && 'disabled'}`}
                >
                  {active ? (
                    <span className={`${validPhone ? 'activeText' : ''}`}>{strings('register.get_auth_code')}</span>
                  ) : (
                    <span>{`${count}s ${strings('register.retransmit')}`}</span>
                  )}
                </a>
              </div>
            </div>
          ) : (
            <div>
              <div className='fromItem'>
                <div className='inputContainer'>
                  <input
                    ref={c => {
                      this.email = c;
                    }}
                    placeholder={strings('register.input_email')}
                    name='email'
                    value={email}
                    onChange={this.onEmailChange}
                    maxLength={60}
                  />
                </div>
                <a
                  className={`clearInput ${!email && 'hidden'}`}
                  onClick={this.clearEmail}
                />
              </div>
              <div className='emailPassword fromItem'>
                <div className='inputContainer'>
                  <input
                    ref={c => {
                      this.emailPassword = c;
                    }}
                    type='password'
                    placeholder={strings('register.input_password')}
                    name='password'
                    value={emailPassword}
                    onChange={this.onEmailPwdChange}
                  />
                </div>
                <a
                  className={`clearInput ${!emailPassword && 'hidden'}`}
                  onClick={this.clearEmailPassword}
                />
              </div>
            </div>
          )}

          <a
            className={`loginBtn ${isValidated && canPress && 'active'}`}
            onClick={this.signUpSubmit}
          >
            {submitText}
          </a>
        </div>
        <div className='loginBottom'>
          <p>
            {registerTipText}
            <a target='_blank' href={`/policy/agreement-${language}.html`}>{strings('login.terms')}</a>
            {strings('login.and')}
            <a href={`/policy/privacy-${language}.html`}>{strings('login.policy')}</a>
          </p>
          {canShowProblem && (
            <a className='problemLink' onClick={this.showProblem}>
              {strings('login.problems')}
            </a>
          )}
        </div>
        {/*<div style={{ width: '80%', color: 'red' }}>{error.message}</div>*/}
      </div>
    );
  }
}
