import { observable, action, runInAction, configure, computed } from 'mobx'
import { toast } from 'react-toastify'
import BaseStore from './BaseStore';
import api from '@API';
import { SHARE_TYPE } from '@COMMON/constant';

const DEFAULT_TAKE = 10

configure({ enforceActions: 'always' })

class MedWordStore extends BaseStore {
  @observable detailData = undefined;

  @observable wordList = [];

  @observable canLoadMoreWord = true;

  @observable relatedMedWords = [];

  @observable audioPlayingLink = '';

  @computed get skipWordList() {
    return this.wordList.length
  }

  @action getMedWordDetail = async param => {
    try {
      this.dataInitialLoading()
      const data = await api.getMedWordDetail(param)
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.MedWord)
        this.detailData = data
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }

  @action getRelatedMedWords = async param => {
    try {
      const data = await api.getRelatedMedWords(param)
      runInAction(() => {
        const { MedWords } = data
        this.relatedMedWords = MedWords
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }

  @action addBookmark = async id => {
    try {
      const { p8Id } = await api.addBookmark({
        EntityType: 'MedWord',
        EntityId: id,
      })
      runInAction(() => {
        this.detailData.BookmarkId = p8Id
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action unbookmark = async BookmarkId => {
    try {
      await api.unbookmark({
        BookmarkId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = null
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action setAudioPlayingLink = link => {
    runInAction(() => {
      this.audioPlayingLink = link || ''
    })
  }

  @action getMedWordList = async (initial = false, params = {}) => {
    try {
      if (initial || this.canLoadMoreWord) {
        const skip = initial ? 0 : this.skipWordList
        !skip ? this.dataInitialLoading() : this.dataLoading()
        const data = await api.getDailyMedWords({ skip, take: DEFAULT_TAKE, ...params })
        const { MedWords = [] } = data || {}

        runInAction(() => {
          this.wordList = !skip ? MedWords : [...this.wordList, ...MedWords]
          this.canLoadMoreWord = MedWords.length >= DEFAULT_TAKE
        })
      }
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }
}

export default new MedWordStore()
