import { observable, action, runInAction, configure, computed } from 'mobx';
import api from '@API';
import { SHARE_TYPE } from '@COMMON/constant'
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' })

class ConferenceStore extends BaseStore {
  @observable detailData = undefined;

  @observable meetupList = []

  @observable canLoadmeetupList = true

  @observable perPage = 10

  @computed get skipMeetupList() {
    return this.meetupList.length
  }

  @action getMeetupList = async (payload, initial) => {
    try {
      if (!(initial || this.canLoadmeetupList)) return

      const skip = initial ? 0 : this.skipMeetupList
      !skip ? this.dataInitialLoading() : this.dataLoading()
      const data = await api.getMeetups({ ...payload, skip, take: this.perPage })
      const { Meetups } = data
      runInAction(() => {
        this.meetupList = !skip ? Meetups : [...this.meetupList, ...Meetups]
        this.canLoadmeetupList = Meetups?.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getGuideDetail = async mId => {
    try {
      this.dataInitialLoading()
      const data = await api.getMeetupDetails({ mId })
      const { Meetup = {} } = data || {}
      runInAction(() => {
        this.setWeChatShareInfo(Meetup, SHARE_TYPE.Conference)
        this.detailData = data
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }
}

export default new ConferenceStore()
