/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { parserParams, isDingTalkBrowser, requestCode } from '@UTILS/dingtalk';
import { inject, observer } from 'mobx-react';
// import * as dd from 'dingtalk-jsapi';
import routingStore from '@MOELS/RoutingStore';
import { toast } from 'react-toastify';
import { strings } from '@I18N/i18n';
import Loading from '@COMS/Load/LoadingSpinner';
import api from '@API';

const Login = WrappedLogin => {
  @inject('appStore', 'authStore')
  @observer
  class loginView extends Component {
    constructor(props) {
      super(props)
      const params = parserParams(props.location.search)
      const { reset } = params
      this.state = {
        reset
      }
    }

    componentDidMount() {
      // toast.success(dd.env.platform)
      const { appStore: { token, userAppKey } } = this.props
      const params = parserParams(this.props.location.search)
      const { appkey, redirect = '/', reset } = params
      if (reset) return
      if (!appkey && !token) {
        this.props.appStore.setAppLoaded();
      }
      if (token && userAppKey === appkey) {
        routingStore.replace(redirect)
      } else {
        this.login(params)
      }
    }

    checkIsPublicForDingTalk = appkey => {
      return appkey === 'f48d7422-4ef5-4bb3-96bd-7d48a181a849'
    }

    getThridUserId = async (orgUserid, orgName, appKey) => {
      let thridUserId = orgUserid;
      let thridName = orgName
      // toast.success(isDingTalkBrowser ? 'True' : 'False')
      if (isDingTalkBrowser) {
        try {
          const authCode = await requestCode(appKey);
          const { userid, name } = await api.dingLogin({ authCode, appKey });
          if (!userid) {
            toast.error(strings('login.ding_userid_error'))
          }
          thridUserId = userid
          thridName = name
          // toast.error(thridUserId)
        } catch (error) {
          toast.error(error.message || error)
        }
      }
      return { thridUserId, thridName }
    }

    login = async params => {
      const { appkey, redirect = '/', userid, name = '', lang } = params
      if (this.checkIsPublicForDingTalk(appkey)) {
        this.props.authStore.verify(appkey, '', redirect, '', lang);
        return
      }
      const { thridUserId, thridName } = await this.getThridUserId(userid, name, appkey)
      if (isDingTalkBrowser && !thridUserId) {
        this.props.appStore.setAppLoaded(true);
        toast.error(strings('login.empty_userid_error'))
        return
      }
      this.props.authStore.verify(appkey, thridUserId, redirect, thridName, lang);
    }

    render() {
      const { authStore: { requestAuthCode } } = this.props
      if (!this.props.appStore.appLoaded && !this.state.reset) {
        return <Loading />;
      }
      return <WrappedLogin onPressSubmit={this.props.authStore.modalBindPhone} getAuthCode={this.props.authStore.requestAuthCode} {...this.props} />;
    }
  }
  return loginView;
};
export default Login;
