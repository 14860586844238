/* eslint-disable class-methods-use-this */
import { observable, action, computed, runInAction, configure } from 'mobx';
import { toast } from 'react-toastify';
import { SHARE_TYPE } from '@COMMON/constant';
import api from '@API';
import BaseStore from './BaseStore';
import CommentStore from './CommentStore';
import UserStore from './UserStore';
import SubjectUserStore from './SubjectUserStore';

configure({ enforceActions: 'always' })

class FeedsStore extends BaseStore {
  @observable page = 1;

  @observable perPage = 10;

  @observable data = [];

  @observable likeList = [];

  @observable detailData = {};

  @observable canLoadMore = true;

  @observable canLoadLikeList = true;

  @observable groupFeedTotal = 0;

  @observable canLoadMoreFeed = true;

  @observable groupNewsFeed = [];

  @observable canLoadActivities = true;

  @observable activityList = [];

  @observable canLoadCommentFeeds = true;

  @observable userCommentActivityList = [];

  @observable canLoadLikeFeeds = true;

  @observable userLikeActivityList = [];

  @observable homeData = [];

  @observable canLoadFollowTopics = true

  @observable followTopicList = []

  @observable tagDetail = {}

  @observable tagTabData = {}

  @observable canLoadTagFeed = true

  @observable tagFeedList = []

  @observable tagDetailLoading = true

  @observable featuredExist = {}

  @observable subjectArticles = [];

  @observable subjectCourses = [];

  @observable subjectGuides = [];

  @observable subjectArticleTotal = this.perPage;

  @observable subjectCoursesTotal = this.perPage;

  @observable subjectGuideTotal = this.perPage;

  @observable lastTab = '';

  @observable articleListByTagIds = []

  @observable canLoadArticleListByTagIds = true

  @computed get skipArticleListByTagIds() {
    return this.articleListByTagIds.length;
  }

  @computed get skipCourse() {
    return this.subjectCourses.length;
  }

  @computed get canLoadCourses() {
    return this.subjectCourses.length < this.subjectCoursesTotal;
  }

  @computed get skipGuide() {
    return this.subjectGuides.length;
  }

  @computed get canLoadCuides() {
    return this.subjectGuides.length < this.subjectGuideTotal;
  }

  @computed get skipArticle() {
    return this.subjectArticles.length;
  }

  @computed get canLoadArticles() {
    return this.subjectArticles.length < this.subjectArticleTotal;
  }

  @computed get skipTagFeedList() {
    return this.tagFeedList.length
  }

  @computed get skipFollowTopicList() {
    return this.followTopicList.length
  }

  @computed get skipFeed() {
    return this.groupNewsFeed.length;
  }

  @computed get skipLike() {
    return this.likeList.length;
  }

  @computed get skip() {
    return this.data.length;
  }

  @computed get skipActivities() {
    return this.activityList.length;
  }

  @computed get skipCommentFeeds() {
    return this.userCommentActivityList.length;
  }

  @computed get skipLikeFeeds() {
    return this.userLikeActivityList.length;
  }

  @action getItemById(id) {
    return this.data.find(value => value.FeedItemId === id);
  }

  @action setLastTab = type => {
    this.lastTab = type;
  }

  @action getNewsFeedByAllTagIds = async (payload, initial, searchTerm) => {
    try {
      const canLoad = initial ? true : this.canLoadArticleListByTagIds
      if (!canLoad) return
      const skip = initial ? 0 : this.skipArticleListByTagIds
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getNewsFeedByAllTagIds({ ...payload, Skip: skip, Take: this.perPage, SearchTerm: searchTerm })
      runInAction(() => {
        this.articleListByTagIds = !skip ? data : [...this.articleListByTagIds, ...data]
        this.canLoadArticleListByTagIds = data?.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getFeaturedContent = async mId => {
    try {
      const exist = await api.existFeaturedItems({ mId });
      runInAction(() => {
        const { ExistArticle, ExistCourse, ExistGuide } = exist
        this.featuredExist = { ExistArticle, ExistCourse, ExistGuide }
        this.getInitTabData(mId)
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getInitTabData = mId => {
    const getFeaturedDataMap = {
      ExistArticle: this.getArticlesBySubjectuserMId,
      ExistCourse: this.getCoursesBySubjectuserMId,
      ExistGuide: this.getGuidesBySubjectuserMId,
    }
    const keys = Object.keys(this.featuredExist)
    const values = Object.values(this.featuredExist)
    const firstTrueIndex = values.findIndex(value => value === true)
    if (firstTrueIndex !== -1) {
      getFeaturedDataMap[keys[firstTrueIndex]](mId)
    }
  }

  @action getArticlesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadArticles
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipArticle
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, FeedItems } = await api.getItemsBySubjectuserMId({
        mId,
        take: this.perPage,
        skip,
      });
      runInAction(() => {
        this.subjectArticleTotal = Total;
        this.subjectArticles = skip === 0 ? FeedItems : [...this.subjectArticles, ...FeedItems];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action getCoursesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadCourses
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipCourse
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Courses } = await api.getCoursesBySubjectuserMId({
        mId,
        take: this.defaultTake,
        skip,
      });
      runInAction(() => {
        this.subjectCoursesTotal = Total;
        this.subjectCourses = skip === 0 ? Courses : [...this.subjectCourses, ...Courses];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action getGuidesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadCuides
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipGuide
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Guides } = await api.getGuidesBySubjectuserMId({
        mId,
        take: this.defaultTake,
        skip,
      });
      runInAction(() => {
        this.subjectGuideTotal = Total;
        this.subjectGuides = skip === 0 ? Guides : [...this.subjectGuides, ...Guides];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }


  @action getTagTabDetails = async tId => {
    this.dataInitialLoading();
    try {
      const [tagDetail, tagTabData] = await Promise.all([api.getTagDetails({ tId }), api.countRelevantDataByTagId({ tId })])
      runInAction(() => {
        this.tagDetail = tagDetail
        this.tagTabData = tagTabData
        this.tagDetailLoading = false
      })
    } catch (error) {
      this.dataError();
    }
  }

  @action getNewsFeedByTagId = async (params, initial, refresh = true) => {
    if (refresh && !this.canLoadTagFeed) return
    const skip = initial ? 0 : this.skipTagFeedList
    !skip ? this.dataInitialLoading() : this.dataLoading()
    try {
      const data = await api.getNewsFeedByTagId({ ...params, skip, take: this.perPage })
      runInAction(() => {
        this.tagFeedList = !skip ? data : [...this.tagFeedList, ...data]
        this.canLoadTagFeed = data.length >= this.perPage
      })
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action getFollowedTopics = async ({ mId, initial }) => {
    if (!this.canLoadFollowTopics) return
    const skip = initial ? 0 : this.skipFollowTopicList
    !skip ? this.dataInitialLoading() : this.dataLoading()
    try {
      const data = await api.getFollowedTopics({ skip, take: this.perPage, mId })
      runInAction(() => {
        this.followTopicList = !skip ? data : [...this.followTopicList, ...data]
        this.canLoadFollowTopics = data.length >= this.perPage
      })
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action followTopic = async TagId => {
    try {
      await api.followTag({ TagId })
      runInAction(() => {
        this.followTopicList = this.followTopicList.map(item => (item.Tag?.p8Id === TagId ? { ...item, FollowedByMe: true } : item))
        this.tagDetail.FollowingTag = true
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action unFollowTopic = async TagId => {
    try {
      await api.unFollowTag({ TagId })
      runInAction(() => {
        this.followTopicList = this.followTopicList.map(item => (item.Tag?.p8Id === TagId ? { ...item, FollowedByMe: false } : item))
        this.tagDetail.FollowingTag = false
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action fetchData = async () => {
    if (!this.canLoadMore) {
      return;
    }
    this.skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const data = await api.getH5MainFeed({
        skip: this.skip,
        take: this.perPage
      });
      runInAction(() => {
        this.errorMsg = '';
        // const newData = data.map(item => new FeedItem(this, item));
        this.data = this.skip === 0 ? data : [...this.data, ...data];
        this.canLoadMore = data.length >= this.perPage;
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action fetchHomeData = async take => {
    try {
      this.dataInitialLoading()
      const data = await api.getH5MainFeed({
        skip: 0,
        take,
      });
      runInAction(() => {
        this.errorMsg = '';
        this.homeData = data;
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action async translate(params) {
    try {
      const { FeedItemId, LangCode } = params || {};
      const { ContentTranslate } = await api.translate({
        FeedItemId,
        LangCode
      });
      runInAction(() => {
        const translateSuccess = item => ({
          ...item,
          ContentTranslate:
            item.FeedItemId === FeedItemId
              ? ContentTranslate
              : item.ContentTranslate,
          ContentLang:
            item.FeedItemId === FeedItemId ? LangCode : item.ContentLang
        });
        this.data = this.data.map(translateSuccess);
        this.tagFeedList = this.tagFeedList.map(translateSuccess);
        this.groupNewsFeed = this.groupNewsFeed.map(translateSuccess);
        this.detailData = {
          ...this.detailData,
          ContentLang: LangCode,
          ContentTranslate
        };
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action async shareTranslate(params) {
    try {
      const { ShareId, LangCode } = params;
      const { NoteTranslate } = await api.shareTranslate({
        ShareId,
        LangCode
      });
      runInAction(() => {
        const translateSuccess = item => ({
          ...item,
          NoteTranslate:
            item.ActivityId === ShareId ? NoteTranslate : item.NoteTranslate,
          NoteLang: item.ActivityId === ShareId ? LangCode : item.NoteLang
        });
        this.data = this.data.map(translateSuccess);
        this.groupNewsFeed = this.groupNewsFeed.map(translateSuccess);
        this.detailData = {
          ...this.detailData,
          NoteLang: LangCode,
          NoteTranslate
        };
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action async getFeedItemDetails(fId) {
    try {
      this.dataInitialLoading();
      const data = await api.getFeedItemDetails({ fId });
      runInAction(() => {
        const { FeedItemId } = data
        this.detailData = data
        const { CommentCount, SubjectUser = {} } = data;
        if (SubjectUser?.MemberId) {
          this.getSubjectUser(SubjectUser?.MemberId)
        }
        CommentStore.clearComments();
        if (CommentCount > 0) {
          CommentStore.getFeedComments(FeedItemId);
        }
        this.setWeChatShareInfo(data, SHARE_TYPE.Activity)
        this.dataSuccess();
      });
      this.getFollowByMe(data.Creator)
    } catch (error) {
      this.dataError();
    }
  }

  @action async getFollowByMe(creator) {
    try {
      if (UserStore.currentUser?.Status === 'Tentative') return
      const FollowedByMe = await api.isFollowedByMe({ mId: creator?.MemberId });
      runInAction(() => {
        this.detailData = {
          ...this.detailData,
          Creator: {
            ...creator,
            FollowedByMe,
          }
        };
      });
    } catch (error) {
      this.dataError();
    }
  }

  @action getSubjectUser = async MemberId => {
    try {
      await SubjectUserStore.getSubjectUser(MemberId)
      runInAction(() => {
        const { subjectUserDetail } = SubjectUserStore
        this.detailData.SubjectUser = subjectUserDetail
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action setInitialLikeList = async () => {
    this.canLoadLikeList = true
    this.likeList = []
  }

  @action async getLikes(fId) {
    try {
      if (!this.canLoadLikeList) {
        return;
      }
      this.skipLike ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getLikesGroup({
        skip: this.skipLike,
        take: this.perPage,
        fId
      });
      runInAction(() => {
        this.errorMsg = '';
        this.likeList = this.skipLike === 0 ? data : [...this.likeList, ...data];
        this.canLoadLikeList = data.length >= this.perPage;
      });
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getUserCreatedAndSharedFeeds = async (params, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadActivities
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipActivities
      skip ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getUserCreatedAndSharedFeeds({
        skip,
        take: this.perPage,
        ...params,
      });
      runInAction(() => {
        this.errorMsg = '';
        this.activityList = skip === 0 ? data : [...this.activityList, ...data];
        this.canLoadActivities = data.length >= this.perPage;
      });
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getUserCommentFeeds = async (params, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadCommentFeeds
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipCommentFeeds
      skip ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getUserActivity({
        skip,
        take: this.perPage,
        ...params,
      });
      runInAction(() => {
        this.errorMsg = '';
        this.userCommentActivityList = skip === 0 ? data : [...this.userCommentActivityList, ...data];
        this.canLoadCommentFeeds = data.length >= this.perPage;
      });
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getUserLikeFeeds = async (params, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadLikeFeeds
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipLikeFeeds
      skip ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getUserActivity({
        skip,
        take: this.perPage,
        ...params,
      });
      runInAction(() => {
        this.errorMsg = '';
        this.userLikeActivityList = skip === 0 ? data : [...this.userLikeActivityList, ...data];
        this.canLoadLikeFeeds = data.length >= this.perPage;
      });
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action async getLikeGroups(fId) {
    try {
      const data = await api.getLikesGroup({
        take: this.perPage,
        fId
      });
      runInAction(() => {
        this.likeList = data;
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action like = async fId => {
    try {
      const data = await api.likePost({ FeedItemId: fId });
      runInAction(() => {
        const likeSuccess = item => ({
          ...item,
          LikeCount:
            item.FeedItemId === fId ? item.LikeCount + 1 : item.LikeCount,
          ILiked: item.FeedItemId === fId ? !item.ILiked : item.ILiked
        });
        this.data = this.data.map(likeSuccess);
        this.subjectArticles = this.subjectArticles.map(likeSuccess);
        this.tagFeedList = this.tagFeedList.map(likeSuccess);
        this.homeData = this.homeData.map(likeSuccess);
        this.groupNewsFeed = this.groupNewsFeed.map(likeSuccess);
        this.userLikeActivityList = this.userLikeActivityList.map(likeSuccess);
        this.activityList = this.activityList.map(likeSuccess);
        this.userCommentActivityList = this.userCommentActivityList.map(likeSuccess);
        this.likeList = [data, ...this.likeList];
        if (this.detailData.FeedItemId === fId) {
          this.detailData = {
            ...this.detailData,
            LikeCount: this.detailData.LikeCount + 1,
            ILiked: !this.detailData.ILiked
          };
        }
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  };

  @action.bound async unLike(fId) {
    try {
      await api.unLikePost({ FeedItemId: fId });
      runInAction(() => {
        const unLikeSuccess = item => ({
          ...item,
          LikeCount:
            item.FeedItemId === fId ? item.LikeCount - 1 : item.LikeCount,
          ILiked: item.FeedItemId === fId ? !item.ILiked : item.ILiked
        });
        this.data = this.data.map(unLikeSuccess);
        this.subjectArticles = this.subjectArticles.map(unLikeSuccess);
        this.tagFeedList = this.tagFeedList.map(unLikeSuccess);
        this.homeData = this.homeData.map(unLikeSuccess);
        this.groupNewsFeed = this.groupNewsFeed.map(unLikeSuccess);
        this.userLikeActivityList = this.userLikeActivityList.map(unLikeSuccess);
        this.activityList = this.activityList.map(unLikeSuccess);
        this.userCommentActivityList = this.userCommentActivityList.map(unLikeSuccess);
        this.likeList = this.likeList.filter(
          item => item.UserId !== UserStore.currentUser.p8Id
        );
        if (this.detailData.FeedItemId === fId) {
          this.detailData = {
            ...this.detailData,
            LikeCount: this.detailData.LikeCount - 1,
            ILiked: !this.detailData.ILiked
          };
        }
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action addCommentCount() {
    const { FeedItemId } = this.detailData;
    this.detailData = {
      ...this.detailData,
      CommentCount: this.detailData.CommentCount + 1
    };
    const updateArr = item => ({
      ...item,
      CommentCount:
        item.FeedItemId === FeedItemId
          ? item.CommentCount + 1
          : item.CommentCount
    });
    this.data = this.data.map(updateArr);
    this.groupNewsFeed = this.groupNewsFeed.map(updateArr);
  }

  @action reduceCommentCount() {
    const { FeedItemId } = this.detailData;
    this.detailData = {
      ...this.detailData,
      CommentCount: this.detailData.CommentCount - 1
    };
    const updateArr = item => ({
      ...item,
      CommentCount:
        item.FeedItemId === FeedItemId
          ? item.CommentCount - 1
          : item.CommentCount
    });
    this.data = this.data.map(updateArr);
    this.groupNewsFeed = this.groupNewsFeed.map(updateArr);
  }

  @action clearList() {
    this.data = [];
  }

  @action async getGroupNewsFeed(gId, initial, refresh) {
    if (!refresh && !this.canLoadMoreFeed) {
      return;
    }
    const skip = initial ? 0 : this.skipFeed
    skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const { Feeds, Total } = await api.getGroupNewsFeed({
        skip,
        take: this.perPage,
        gId
      });
      runInAction(() => {
        this.groupNewsFeed = skip === 0 ? Feeds : [...this.groupNewsFeed, ...Feeds];
        this.groupFeedTotal = Total;
        this.canLoadMoreFeed = Feeds?.length >= this.perPage;
      });
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action handleFollowFriend(memberId) {
    if (this.detailData.Creator?.MemberId === memberId) {
      const {
        detailData: {
          Creator: { FollowedByMe }
        }
      } = this;
      this.detailData.Creator.FollowedByMe = !FollowedByMe;
    }
    if (this.detailData.SubjectUser?.MemberId === memberId) {
      const {
        detailData: {
          SubjectUser: { FollowedByMe }
        }
      } = this;
      this.detailData.SubjectUser.FollowedByMe = !FollowedByMe;
    }
  }

  @action feedFollowGroup = async () => {
    this.detailData.Institution.FollowedByMe = true;
  };

  @action feedUnFollowGroup = async () => {
    this.detailData.Institution.FollowedByMe = false;
  };

  @action addBookmark = async (id, EntityType) => {
    try {
      const { p8Id } = await api.addBookmark({
        EntityType,
        EntityId: id,
      })
      runInAction(() => {
        this.detailData.BookmarkId = p8Id
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action unbookmark = async BookmarkId => {
    try {
      await api.unbookmark({
        BookmarkId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = null
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }
}

export default new FeedsStore();
