import { observable, action, runInAction, computed, toJS, configure } from 'mobx';
import { toast } from 'react-toastify';
import BaseStore from './BaseStore';
import api from '@API';
import { SHARE_TYPE } from '@COMMON/constant'

configure({ enforceActions: 'always' })

class CustomAcademyStore extends BaseStore {
  @observable academy = {}

  @observable aId = '';

  @observable articleList = []

  @observable articleSkip = 0

  @observable articleCanLoad = true

  @observable perPage = 10

  @computed get skipArticleList() {
    return this.articleList.length
  }

  @observable courseList = []

  @observable courseCanLoad = true

  @computed get skipCourseList() {
    return this.courseList.length
  }

  @observable guideList = []

  @observable guideCanLoad = true

  @computed get skipGuideList() {
    return this.guideList.length
  }

  @observable specialTitle = {}

  @observable bannerData = [];

  @observable topicData = []

  @observable academyExpertsListCanLoad = true

  @observable academyExpertsList = []

  @observable relatedTags = []

  @computed get skipAcademyExpertList() {
    return this.academyExpertsList.length
  }

  @observable clearParams() {
    this.articleSkip = 0
    this.articleCanLoad = true
    this.articleList = []
  }

  @action getAcademy = async payload => {
    try {
      this.dataInitialLoading()
      const data = await api.getAcademy(payload)
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.CustomAcademy)
        this.academy = data
        const { BannerSpotCode, SpecialTopic, p8Id } = data
        this.aId = p8Id
        const { Title, TopicSpotCode } = SpecialTopic || {}
        this.specialTitle = Title
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getNewsFeedByAllTagIds = async (payload, initial, value) => {
    try {
      if (!(initial || this.articleCanLoad)) return
      if (initial) this.clearParams()

      const skip = initial ? 0 : this.skipArticleList
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getNewsFeedByAllTagIds({ ...payload, Skip: skip, Take: this.perPage, SearchTerm: value })
      runInAction(() => {
        this.articleList = !skip ? data : [...this.articleList, ...data]
        this.articleCanLoad = data?.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getCourseByAllTagIds = async (payload, initial, value) => {
    try {
      if (!(initial || this.courseCanLoad)) return

      const skip = initial ? 0 : this.skipCourseList
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getCourseByAllTagIds({ ...payload, Skip: skip, Take: this.perPage, SearchTerm: value })
      const { Courses } = data
      runInAction(() => {
        this.courseList = !skip ? Courses : [...this.courseList, ...Courses]
        this.courseCanLoad = Courses?.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getGuidesByAllTagIds = async (payload, initial, value) => {
    try {
      if (!(initial || this.guideCanLoad)) return

      const skip = initial ? 0 : this.skipGuideList
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getGuidesByAllTagIds({ ...payload, Skip: skip, Take: this.perPage, SearchTerm: value })
      const { Guides } = data
      runInAction(() => {
        this.guideList = !skip ? Guides : [...this.guideList, ...Guides]
        this.guideCanLoad = Guides?.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getAcademyExperts = async (payload, initial, value) => {
    try {
      if (!(initial || this.academyExpertsListCanLoad)) return

      const skip = initial ? 0 : this.skipAcademyExpertList
      !skip ? this.dataInitialLoading() : this.dataLoading()

      const data = await api.getAcademyExperts({ ...payload, skip, take: this.perPage, st: value })
      runInAction(() => {
        this.academyExpertsList = !skip ? data : [...this.academyExpertsList, ...data]
        this.academyExpertsListCanLoad = data?.length >= this.perPage
      })
    } catch (error) {
      toast.error(error.message || error);
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getEventData = async (code, field) => {
    try {
      const data = await api.getEventsListBySpotCode({ code })
      runInAction(() => {
        this[field] = data
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getBannerData = code => {
    this.getEventData(code, 'bannerData')
  }

  @action getAcademyTopic = code => {
    this.getEventData(code, 'topicData')
  }

  @action clearRelatedTags = () => {
    this.relatedTags = []
  }

  @action getRelatedTags = async tId => {
    try {
      const { Tag: { RelatedTags = [] } = {} } = await api.getTagDetails({ tId })
      runInAction(() => {
        this.relatedTags = RelatedTags
      })
    } catch (e) {
      toast.error(e.message || e)
    }
  }
}

export default new CustomAcademyStore()
