import WechatJSSDK from 'wechat-jssdk/dist/client.umd';
import api from '@API';
import { strings } from '@I18N/i18n'
// import { toast } from 'react-toastify';

const isWeixinBrowser = () => {
  return /micromessenger/.test(navigator.userAgent.toLowerCase())
}

const getSignature = async () => {
  const url = window.location.href.split('#')[0];
  try {
    return await api.getWeChatSignature({ url })
  } catch (error) {
    console.log({ error });
    return null
  }
}

export default async function wxShare(shareFriendsInfo, shareCircleInfo, callback) {
  if (!isWeixinBrowser()) return;
  const data = await getSignature()
  const config = {
    ...data,
    success: jssdkInstance => { console.log('success', jssdkInstance) },
    error: (err, jssdkInstance) => { console.log('failed', jssdkInstance) },
    debug: false,
    jsApiList: [
      'launchApplication',
      'getInstallState',
      'checkJsApi', //检查api
      'onMenuShareTimeline', //1.0分享到朋友圈
      'onMenuShareAppMessage', //1.0 分享到朋友
      'updateAppMessageShareData', //1.4 分享到朋友
      'updateTimelineShareData', //1.4分享到朋友圈
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'onMenuShareQZone',
      'showOptionMenu'
    ],
    customUrl: ''
  }
  const wechatObj = new WechatJSSDK(config);
  try {
    await wechatObj.initialize();
    wechatObj.shareOnChat(shareFriendsInfo);
    wechatObj.shareOnMoment(shareCircleInfo);
  } catch (error) {
    console.log(error);
  }
  try {
    wechatObj.callWechatApi('updateAppMessageShareData', shareFriendsInfo)
    // wechatObj.callWechatApi('updateTimelineShareData', shareCircleInfo)
    // wechatObj.updateTimelineShareData(shareCircleInfo);
    // wechatObj.updateAppMessageShareData(shareFriendsInfo);
  } catch (err) {
    console.log('config version 1.4 api falied', err);
  }
}

export const getDefaultShareInfo = () => {
  return {
    title: strings('common.app_name'),
    desc: strings('common.share_opportunity_title'),
    link: window.location.href,
    imgUrl: 'https://pier-prod.oss-cn-hangzhou.aliyuncs.com/static/img/share/share_card_cover_s.png',
    success: () => console.log('Your opportunity has been share successfully'),
    cancel: error => console.log('share failed', error)
  }
}
