/* eslint-disable */
import {
  APP_ROUTE_IN_H5,
  OpenQQAppUrl,
  OPEN_APP_ROUTE,
  ROUTE_REG,
  WEB_ROUTE,
  DownloadUrl,
  DownAndroidLoadUrl,
} from '@COMMON/constant'
export function getMenuData(routerMap) {
  let routes = routerMap;
  if (routerMap['/']) {
    routes = routerMap['/'].children;
  }
  const menu = Object.keys(routes).map(path => {
    const result = {
      path,
      name: routes[path].name,
      icon: routes[path].icon,
      authority: routes[path].authority,
      hidden: routes[path].hidden
    };
    if (routes[path].children) {
      result.children = getMenuData(routes[path].children);
    }
    return result;
  });
  return menu;
}

export function getWebRouter(data) {
  const { RouteType, RouteData } = data
  const id = Object.values(RouteData)[0]
  const h5Router = APP_ROUTE_IN_H5[RouteType] || RouteType
  return `/${h5Router}/${id}`
}

export function isPC() {
  return window.innerWidth > 768;
}

export function isIosClient() {
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
export function getSystem() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'IOS';
  }
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'Android';
  }
  return 'PC';
}
export function isWeiXin() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') > -1;
}

export function calcWidth(staticWidth) {
  const windowWidth = document.body.clientWidth > 640 ? 640 : document.body.clientWidth
  return staticWidth * windowWidth / 375
}

// only Safari
export function redirectByTimer(path) {
  const { origin } = window.location
  let limit_num = 100
  const openTime = +new Date()
  window.location = `${origin.replace('https', 'medlinc')}${path}`
  const timer = setInterval(function () {
    if(limit_num > 0){
      limit_num--;
    }else{
      if ((new Date()) - openTime < 2200) {
        window.location = `${origin}/downloadApp`
      }
      clearTimeout(timer)
    }
  }, 20)
}

export function openApp() {
  // if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1) {
  //   window.location = OpenQQAppUrl;
  //   return
  // }
  const { pathname } = window.location
  const shortRoute = pathname.match(ROUTE_REG)
  if (!shortRoute) return
  const originalRouteNames = Object.keys(OPEN_APP_ROUTE)
  let linkRouteType = ''
  originalRouteNames.forEach(route => {
    if (route.includes(shortRoute[0])) {
      linkRouteType = OPEN_APP_ROUTE[route]
    }
  })
  const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  let path
  if (shortRoute[0] === `/${WEB_ROUTE.customAcademy.name}`) {
    const { aId = '' } = queryString()
    path = `${linkRouteType}${aId}`
  } else {
    path = pathname.replace(ROUTE_REG, linkRouteType)
  }
  if (isSafari) {
    redirectByTimer(path)
  } else {
    window.location.href = `${path}?open=1`
  }
}

export function getRouterData(routerMap) {
  const flattenRoute = flatten(routerMap);
  const routes = Object.keys(flattenRoute).map(path => flattenRoute[path]);
  return routes;
}

function flatten(routerMap) {
  let routes = {};
  Object.keys(routerMap).forEach(path => {
    routes[path] = {
      path,
      key: path,
      name: routerMap[path].name,
      exact: routerMap[path].exact || true,
      component: routerMap[path].component
    };
    if (routerMap[path].children) {
      routes = { ...routes, ...flatten(routerMap[path].children) };
    }
  });
  return routes;
}

export const queryString = () => {
  const _queryString = {};
  const _query = window.location.search.substr(1);
  const querys = _query.split('&');
  querys.forEach(v => {
    const _pair = v.split('=');
    if (!_queryString.hasOwnProperty(_pair[0])) {
      _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
    } else if (typeof _queryString[_pair[0]] === 'string') {
      const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
      _queryString[_pair[0]] = _arr;
    } else {
      _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
    }
  });
  return _queryString;
};
export const getQueryString = name => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};

let time;
export const lowerRequest = (func, delay) => {
  return (...args) => {
    if (time) {
      clearTimeout(time);
      time = null;
    }
    time = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const caculateTimes = num => {
  // eslint-disable-next-line radix
  const hours = parseInt(num / 3600);
  // eslint-disable-next-line radix
  const residueHour = parseInt(num % 3600);
  // eslint-disable-next-line radix
  const minutes = parseInt(residueHour / 60);
  // eslint-disable-next-line radix
  const seconds = parseInt(num % 60);
  return {
    hours,
    minutes,
    seconds
  };
};

export const caculateTimeLength = (hours, minutes, seconds) => {
  // eslint-disable-next-line no-param-reassign
  !hours && (hours = 0);
  // eslint-disable-next-line no-param-reassign
  !minutes && (minutes = 0);
  // eslint-disable-next-line no-param-reassign
  !seconds && (seconds = 0);
  return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
};

export const secondsToTime = (sec, isDatatime) => {
  const numSec = Number(sec)
  let h = Math.floor(numSec / 3600)
  let m = Math.floor((numSec / 60) % 60)
  let s = Math.floor(numSec % 60)
  if (isDatatime) {
    return {
      hours: h,
      minutes: m,
      seconds: s
    }
  }
  h = h >= 1 ? (h < 10 ? `0${h}时` : `${h}时`) : ''
  m = m >= 1 ? (m < 10 ? `0${m}分` : `${m}分`) : ''
  s = s >= 1 ? (s < 10 ? `0${s}秒` : `${s}秒`) : ''
  return `${h} ${m} ${s}`
}

export function hasClass(elem, cls) {
  cls = cls || '';
  if (cls.replace(/\s/g, '').length == 0) return false; //当cls没有参数时，返回false
  return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ');
}

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) {
    ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
  }
}

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' ';
    while (newClass.indexOf(' ' + cls + ' ') >= 0) {
      newClass = newClass.replace(' ' + cls + ' ', ' ');
    }
    ele.className = newClass.replace(/^\s+|\s+$/g, '');
  }
}

export function getSplitString(string='',splitLen=40){
  const type = Object.prototype.toString.call(string)
  const  isLongString = type === '[object String]' && string.length && string.length>splitLen
  if (isLongString){
    return  string.substring(0, splitLen)+'...'
  }
  return  string
}

export function getByteSplitString(str='',splitLen=60) {
  const isString = typeof str === 'string';
  if (!isString || !str) return '';
  let result = '';
  const  strlen = str.length,
    chrlen = str.replace(/[^\x00-\xff]/g,'**').length;
  if(chrlen<=splitLen){return str;}
  for(let i=0,j=0;i<strlen;i++){
    let chr = str.charAt(i);
    if(/[\x00-\xff]/.test(chr)){
      j++;
    }else{
      j+=2;
    }
    if(j<=splitLen){
      result += chr;
    }else{
      return result;
    }
  }
}

export function getElementToPageTop(el) {
  if (!el) {
    return 0
  }
  if(el.parentElement) {
    return getElementToPageTop(el.parentElement) + el.offsetTop
  }
  return el?.offsetTop
}

export const setOssImageSize = (w, h, type) => {
  return `?x-oss-process=image/resize,w_${w},h_${h}${type ? `/format,${type}` : ''}`
}

export const onClickContent = (e, callback) => {
  e.preventDefault()
  const eventTarget = e.target
  const isLink = eventTarget.nodeName === 'A' || eventTarget.nodeName === 'IMG'
  if (!isLink) { return }
  const href = eventTarget.getAttribute('href') || eventTarget.parentNode.getAttribute('href')
  if (!href) { return }
  if (/^app:/.test(href)) {
    const routeArr = href.split('/')
    const appRouteType = routeArr[1]
    const routeParams = routeArr[2]
    const h5RouteName = APP_ROUTE_IN_H5[appRouteType]
    if (h5RouteName) {
      callback(`/${h5RouteName}/${routeParams}`)
    }
  } else {
    window.location.href = href
  }
}

export const onClickDownload = () => {
  const url = isIosClient() ? DownloadUrl : DownAndroidLoadUrl
  window.location.href = url
}

export function checkIsMobile () {
  var platform = navigator.platform
  if (platform !== 'MacIntel' && platform !== 'win32' && platform !== 'win64') {
    return true
  } else {
    return false
  }
}
