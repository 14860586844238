/* eslint-disable */
const loadPhysicianAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/physician/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/physician/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/physician/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/physician/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/physician/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/physician/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/physician/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/physician/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/physician/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/physician/9@3x.png');
    default:
      return require('@ASSETS/avatar/physician/0@3x.png');
  }
};
const loadPharmacistAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/pharmacist/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/pharmacist/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/pharmacist/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/pharmacist/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/pharmacist/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/pharmacist/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/pharmacist/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/pharmacist/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/pharmacist/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/pharmacist/9@3x.png');
    default:
      return require('@ASSETS/avatar/pharmacist/0@3x.png');
  }
};
const loadStudentAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/student/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/student/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/student/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/student/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/student/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/student/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/student/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/student/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/student/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/student/9@3x.png');
    default:
      return require('@ASSETS/avatar/student/0@3x.png');
  }
};
const loadManagementAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/management/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/management/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/management/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/management/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/management/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/management/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/management/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/management/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/management/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/management/9@3x.png');
    default:
      return require('@ASSETS/avatar/management/0@3x.png');
  }
};
const loadRecruiterAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/recruiter/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/recruiter/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/recruiter/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/recruiter/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/recruiter/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/recruiter/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/recruiter/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/recruiter/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/recruiter/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/recruiter/9@3x.png');
    default:
      return require('@ASSETS/avatar/recruiter/0@3x.png');
  }
};
const loadOtherAvatarImage = index => {
  switch (index) {
    case 0:
      return require('@ASSETS/avatar/other/0@3x.png');
    case 1:
      return require('@ASSETS/avatar/other/1@3x.png');
    case 2:
      return require('@ASSETS/avatar/other/2@3x.png');
    case 3:
      return require('@ASSETS/avatar/other/3@3x.png');
    case 4:
      return require('@ASSETS/avatar/other/4@3x.png');
    case 5:
      return require('@ASSETS/avatar/other/5@3x.png');
    case 6:
      return require('@ASSETS/avatar/other/6@3x.png');
    case 7:
      return require('@ASSETS/avatar/other/7@3x.png');
    case 8:
      return require('@ASSETS/avatar/other/8@3x.png');
    case 9:
      return require('@ASSETS/avatar/other/9@3x.png');
    default:
      return require('@ASSETS/avatar/other/0@3x.png');
  }
};

const getImgNum = avatar => {
  const num = parseInt(avatar.replace(/\D/g, '').substring(0, 2), 10);
  return Math.floor(((num % 100) / 10 + (num % 10)) / 2);
};

const hashCode = str => {
  return +(
    str
      .split('')
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0
      ) + ''
  ).slice(-1);
};

export const loadAvatarImage = (type, avatar) => {
  switch (type) {
    case 'Physician':
      return loadPhysicianAvatarImage(hashCode(avatar));
    case 'HospitalManagement':
      return loadManagementAvatarImage(hashCode(avatar));
    case 'Pharmacist':
      return loadPharmacistAvatarImage(hashCode(avatar));
    case 'Recruiter':
      return loadRecruiterAvatarImage(hashCode(avatar));
    case 'Student':
      return loadStudentAvatarImage(hashCode(avatar));
    case 'Other':
      return loadOtherAvatarImage(hashCode(avatar));
    default:
      return loadOtherAvatarImage(hashCode(avatar));
  }
};
