import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import routingStore from '@MOELS/RoutingStore';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import api from '@API';
import '@STYLES/common.scss';
import '@STYLES/Login.scss';

const LoginModal = WrappedLogin => {
  @inject('authStore')
  class loginView extends Component {
    render() {
      return (
        <WrappedLogin
          onPressSubmit={this.props.authStore.modalBindPhone}
          getAuthCode={this.props.authStore.requestAuthCode}
          emailLogin={this.props.authStore.emailLogin}
          {...this.props}
          isModal
        />
      );
    }
  }
  return loginView;
};
export default LoginModal;
