import React, { PureComponent } from 'react';
import { strings } from '@I18N/i18n';

export const getAddress = ({
  country,
  state,
  city,
  language,
  showNoAddressText,
  data,
  separator
}) => {
  let address = showNoAddressText ? strings('profile.noLocationInfo') : '';
  let countryObj = country || {};
  let stateObj = state || {};
  let cityObj = city || {};
  if (data) {
    const { Country, State, City } = data;
    countryObj = Country || {};
    stateObj = State || {};
    cityObj = City || {};
  }
  if (countryObj[language]) {
    address = strings('common.addressFormat', {
      country: countryObj[language] || '',
      state: stateObj[language] || '',
      city: cityObj[language] || ''
    });
  }
  address = address.replace(/\s{2,}/g, ' ');
  if (separator) {
    return address.trim().replace(/ /g, '·')
  }
  return address;
};

const textStyle = {
  color: '#595959',
  lineHeight: '20px'
};

export default class Address extends PureComponent {
  static defaultProps = {
    country: {},
    state: {},
    city: {},
    showNoAddressText: false
  };

  render() {
    const { customStyles } = this.props;
    return (
      <div style={{ ...textStyle, ...customStyles }}>
        {getAddress(this.props)}
      </div>
    );
  }
}
