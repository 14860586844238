import { observable, action, runInAction, configure, computed } from 'mobx';
import api from '@API';
import BaseStore from './BaseStore';

configure({ enforceActions: 'always' });

class SecondCategoryStore extends BaseStore {
  @observable categories = [];


  @observable canLoadCategories = true;


  @observable perPage = 15;

  @computed get skipCategories() {
    return this.categories.length;
  }


  @action resetCategories = () => {
    this.categories = [];
    this.canLoadCategories = true;
  };

  @action getCategories = async (payload = {}) => {
    try {
      if (!this.canLoadCategories) return;

      const skip = this.skipCategories;
      !skip ? this.dataInitialLoading() : this.dataLoading();

      const data = await api.getSecondMedCategoryByPid({ skip, take: this.perPage, ...payload });
      const { Categories } = data
      runInAction(() => {
        this.categories = !skip ? Categories : [...this.categories, ...Categories];
        this.canLoadCategories = Categories?.length >= this.perPage;
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  };
}

export default new SecondCategoryStore();
