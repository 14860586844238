import { observable, action, runInAction, computed } from 'mobx';
import { toast } from 'react-toastify';
import { SHARE_TYPE } from '@COMMON/constant';
import BaseStore from './BaseStore';
import FeedsStore from './FeedsStore';
import api from '@API';

class ConnectionStore extends BaseStore {
  defaultTake = 10;

  @observable subjectArticleTotal = this.defaultTake;

  @observable subjectCoursesTotal = this.defaultTake;

  @observable subjectGuideTotal = this.defaultTake;

  @observable friendDetail = {};

  @observable featuredExist = {};

  @observable isFeaturedExist = false;

  @observable summary = {};

  @observable subjectArticles = [];

  @observable subjectCourses = [];

  @observable subjectGuides = [];

  @observable professions = [];

  @observable educations = [];

  @observable followers = [];

  @observable followings = [];

  @observable friendList = [];

  @observable friendTotal = this.defaultTake;

  @observable awards = [];

  @observable awardsTotal = 0;

  @observable publications = [];

  @observable pubTotal = 0;

  @observable canLoadProfession = true;

  @observable canLoadEducation = true;

  @observable canLoadFollowers = true;

  @observable canLoadFollowing = true;

  @observable lastTab = '';

  @computed get skipProfession() {
    return this.professions.length;
  }

  @computed get skipEducation() {
    return this.educations.length;
  }

  @computed get skipFollowers() {
    return this.followers.length;
  }

  @computed get skipFollowing() {
    return this.followings.length;
  }

  @computed get skipArticle() {
    return this.subjectArticles.length;
  }

  @computed get canLoadArticles() {
    return this.subjectArticles.length < this.subjectArticleTotal;
  }

  @computed get canLoadFriends() {
    return this.friendList.length < this.friendTotal;
  }

  @computed get skipFriends() {
    return this.friendList.length;
  }

  @computed get skipCourse() {
    return this.subjectCourses.length;
  }

  @computed get canLoadCourses() {
    return this.subjectCourses.length < this.subjectCoursesTotal;
  }

  @computed get skipGuide() {
    return this.subjectGuides.length;
  }

  @computed get canLoadCuides() {
    return this.subjectGuides.length < this.subjectGuideTotal;
  }

  experienceType = {
    getUserProfessions: {
      api: 'getUserProfessions',
      data: 'professions'
    },
    getUserEducations: {
      api: 'getUserEducations',
      data: 'educations'
    }
  };

  @action follow = async MemberIdToFollow => {
    try {
      await api.follow({ MemberIdToFollow });
      runInAction(() => {
        if (this.friendDetail.MemberId === MemberIdToFollow) {
          this.friendDetail.FollowedByMe = true;
        }
        FeedsStore.handleFollowFriend(MemberIdToFollow);
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  };

  @action.bound async unFollow(MemberIdToUnfollow) {
    try {
      await api.unfollow({ MemberIdToUnfollow });
      runInAction(() => {
        if (this.friendDetail.MemberId === MemberIdToUnfollow) {
          this.friendDetail.FollowedByMe = false;
        }
        FeedsStore.handleFollowFriend(MemberIdToUnfollow);
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getUserProfile = async mId => {
    try {
      this.dataInitialLoading();
      const data = await api.getProfile({ mId });
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.Profile)
        this.friendDetail = data;
        const { UserId } = data;
        this.getExperienceByUserId('getUserProfessions', UserId);
        this.getExperienceByUserId('getUserEducations', UserId);
        this.getAwardSummary(UserId);
        this.getPublicationSummary(UserId);
      });
    } catch (error) {
      this.dataError();
    } finally {
      this.dataSuccess();
    }
  }

  @action existFeatured = async mId => {
    try {
      const exists = await api.existFeaturedItems({ mId });
      runInAction(() => {
        const { ExistArticle, ExistCourse, ExistGuide } = exists
        this.isFeaturedExist = ExistArticle || ExistCourse || ExistGuide
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action getSummary = async mId => {
    try {
      const data = await api.getSummary({ mId });
      runInAction(() => {
        this.summary = data;
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action async getExperienceByUserId(type, uId) {
    try {
      const data = await api[this.experienceType[type].api]({ uId });
      runInAction(() => {
        this[this.experienceType[type].data] = data;
      });
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action getUserProfessions = async uId => {
    if (!this.canLoadProfession) {
      return;
    }
    this.skipProfession ? this.dataLoading() : this.dataInitialLoading();
    try {
      const list = await api.getUserProfessions({
        skip: this.skipProfession,
        take: this.defaultTake,
        uId
      });
      runInAction(() => {
        this.professions = this.skipProfession === 0 ? list : [...this.professions, ...list];
        this.canLoadProfession = list.length >= this.defaultTake;
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  };

  @action getUserEducations = async uId => {
    if (!this.canLoadEducation) {
      return;
    }
    this.skipEducation ? this.dataLoading() : this.dataInitialLoading();
    try {
      const list = await api.getUserEducations({
        skip: this.skipEducation,
        take: this.defaultTake,
        uId
      });
      runInAction(() => {
        this.educations = list;
        this.canLoadEducation = list.length >= this.defaultTake;
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  };

  @action async getAwardSummary(uId) {
    try {
      const { Total, Award } = await api.getAwardSummary({ uId });
      runInAction(() => {
        this.awards = Award;
        this.awardsTotal = Total;
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action async getPublicationSummary(uId) {
    try {
      const { Total, Publication } = await api.getPublicationSummary({ uId });
      runInAction(() => {
        this.publications = Publication;
        this.pubTotal = Total;
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action.bound async addFriend(MemberIdToAdd) {
    try {
      await api.addFriend({ MemberIdToAdd });
      runInAction(() => {
        this.friendDetail.RequesterPending = true;
        toast.success('已发送好友请求，打开"医者无界"与好友沟通');
      });
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getFeaturedContent = async mId => {
    try {
      const exist = await api.existFeaturedItems({ mId });
      runInAction(() => {
        const { ExistArticle, ExistCourse, ExistGuide } = exist
        this.featuredExist = { ExistArticle, ExistCourse, ExistGuide }
        this.getInitTabData(mId)
      })
    } catch (error) {
      toast.error(error.message || error);
    }
  }

  @action getInitTabData = mId => {
    const getFeaturedDataMap = {
      ExistArticle: this.getArticlesBySubjectuserMId,
      ExistCourse: this.getCoursesBySubjectuserMId,
      ExistGuide: this.getGuidesBySubjectuserMId,
    }
    const keys = Object.keys(this.featuredExist)
    const values = Object.values(this.featuredExist)
    const firstTrueIndex = values.findIndex(value => value === true)
    if (firstTrueIndex !== -1) {
      getFeaturedDataMap[keys[firstTrueIndex]](mId)
    }
  }

  @action getArticlesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadArticles
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipArticle
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, FeedItems } = await api.getItemsBySubjectuserMId({
        mId,
        take: this.defaultTake,
        skip,
      });
      runInAction(() => {
        this.subjectArticleTotal = Total;
        this.subjectArticles = skip === 0 ? FeedItems : [...this.subjectArticles, ...FeedItems];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action getCoursesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadCourses
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipCourse
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Courses } = await api.getCoursesBySubjectuserMId({
        mId,
        take: this.defaultTake,
        skip,
      });
      runInAction(() => {
        this.subjectCoursesTotal = Total;
        this.subjectCourses = skip === 0 ? Courses : [...this.subjectCourses, ...Courses];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action getGuidesBySubjectuserMId = async (mId, initial) => {
    try {
      const canLoadMore = initial ? true : this.canLoadCuides
      if (!canLoadMore) {
        return;
      }
      const skip = initial ? 0 : this.skipGuide
      skip ? this.dataLoading() : this.dataInitialLoading();
      const { Total, Guides } = await api.getGuidesBySubjectuserMId({
        mId,
        take: this.defaultTake,
        skip,
      });
      runInAction(() => {
        this.subjectGuideTotal = Total;
        this.subjectGuides = skip === 0 ? Guides : [...this.subjectGuides, ...Guides];
      });
    } catch (error) {
      toast.error(error.message || error);
    } finally {
      this.dataSuccess();
    }
  }

  @action setLastTab = type => {
    this.lastTab = type;
  }

  @action getFollowers = async mId => {
    try {
      if (!this.canLoadFollowers) return
      this.skipFollowers ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getFollowers({
        mId,
        take: this.defaultTake,
        skip: this.skipFollowers,
      })
      runInAction(() => {
        this.canLoadFollowers = data.length >= this.defaultTake;
        this.followers = this.skipFollowers === 0 ? data : [...this.followers, ...data];
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getFollowings = async mId => {
    try {
      if (!this.canLoadFollowing) return
      this.skipFollowing ? this.dataLoading() : this.dataInitialLoading();
      const data = await api.getFollowees({
        mId,
        take: this.defaultTake,
        skip: this.skipFollowing,
      })
      runInAction(() => {
        this.canLoadFollowing = data.length >= this.defaultTake;
        this.followings = this.skipFollowing === 0 ? data : [...this.followings, ...data];
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action getFriends = async mId => {
    try {
      if (!this.canLoadFriends) return
      this.skipFriends ? this.dataLoading() : this.dataInitialLoading();
      const { TotalNumber, Friends } = await api.getFriends({
        mId,
        take: this.defaultTake,
        skip: this.skipFriends,
      })
      runInAction(() => {
        this.friendTotal = TotalNumber
        this.friendList = this.skipFriends === 0 ? Friends : [...this.friendList, ...Friends];
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess();
    }
  }

  @action handleFollowFriend = memberId => {
    const handleData = item => ({
      ...item,
      FollowedByMe: item.Id === memberId ? !item.FollowedByMe : item.FollowedByMe,
    })
    this.followers = this.followers.map(handleData)
    this.followings = this.followings.map(handleData)
    this.friendList = this.friendList.map(handleData)
  }
}

export default new ConnectionStore();
