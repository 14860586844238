import { observable, action, runInAction, configure, computed } from 'mobx';
import { SHARE_TYPE } from '@COMMON/constant';
import api from '@API';
import { toast } from 'react-toastify'
import BaseStore from './BaseStore';
import SubjectUserStore from './SubjectUserStore'

configure({ enforceActions: 'always' })

class GuideStore extends BaseStore {
  @observable perPage = 10;

  @observable tagId = '';

  @observable st = '';

  @observable guideList = [];

  @observable guideType = [];

  @observable canLoadMore = true;

  @observable skip = 0;

  @observable detailData = undefined;

  @observable homeData = [];

  @observable tagGuideList = []

  @observable canLoadTagGuideList = true

  @computed get skipTagGuideList() {
    return this.tagGuideList.length
  }

  @action resetGuideTagId = id => {
    this.st = ''
    this.canLoadMore = true
    this.skip = 0
    this.tagId = id
  }

  @action setDeptTagId = id => {
    this.st = ''
    this.canLoadMore = true
    this.skip = 0
    this.tagId = id
    this.getGuidesByTag()
  }

  @action searchGuide = (st, tId) => {
    this.st = st
    this.canLoadMore = true
    this.skip = 0
    this.tagId = tId || ''
    this.getGuidesByTag()
  }

  @action getGuidesByTag = async () => {
    if (!this.canLoadMore) { return }
    this.skip ? this.dataLoading() : this.dataInitialLoading();
    try {
      const params = {}
      if (this.st) {
        params.SearchTermTitle = this.st
        params.SearchTermSetter = this.st
      }
      if (this.tagId) params.TagId = this.tagId
      const { Guides } = await api.getGuidesByTag({
        skip: this.skip,
        take: this.perPage,
        ...params,
      })
      runInAction(() => {
        this.guideList = this.skip === 0 ? Guides : [...this.guideList, ...Guides];
        this.skip = this.guideList.length;
        this.canLoadMore = this.perPage === Guides.length;
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getHomeData = async take => {
    try {
      this.dataInitialLoading()
      const { Guides } = await api.getGuidesByTag({
        skip: 0,
        take,
      })
      runInAction(() => {
        this.homeData = Guides
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }

  @action getPubEventsListBySpotCode = async params => {
    try {
      const list = await api.getPubEventsListBySpotCode(params)
      runInAction(() => {
        this.guideType = list
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action getGuideDetail = async param => {
    try {
      this.dataInitialLoading()
      const data = await api.getGuideDetail(param)
      runInAction(() => {
        this.setWeChatShareInfo(data, SHARE_TYPE.Guide)
        this.detailData = data
        const { SubjectUser } = data
        const { MemberId } = SubjectUser || {}
        if (MemberId) {
          this.getSubjectUser(MemberId)
        }
        this.dataSuccess()
      })
    } catch (error) {
      this.dataError()
    }
  }

  @action getSubjectUser = async MemberId => {
    try {
      await SubjectUserStore.getSubjectUser(MemberId)
      runInAction(() => {
        const { subjectUserDetail } = SubjectUserStore
        this.detailData.SubjectUser = subjectUserDetail
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action handleFollowSubjectUser = memberId => {
    if (this.detailData.SubjectUser?.MemberId === memberId) {
      const {
        detailData: {
          SubjectUser: { FollowedByMe }
        }
      } = this;
      this.detailData.SubjectUser.FollowedByMe = !FollowedByMe;
    }
  }

  @action addBookmark = async EntityId => {
    try {
      const { p8Id } = await api.addBookmark({
        EntityType: 'Guide',
        EntityId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = p8Id
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action unbookmark = async BookmarkId => {
    try {
      await api.unbookmark({
        BookmarkId,
      })
      runInAction(() => {
        this.detailData.BookmarkId = null
      })
    } catch (error) {
      toast.error(error.message || error)
    }
  }

  @action getGuidesByTagId = async (TagId, initial, refresh = true) => {
    if (refresh && !this.canLoadTagGuideList) return
    const skip = initial ? 0 : this.skipTagGuideList
    !skip ? this.dataInitialLoading() : this.dataLoading();
    try {
      const { Guides: data } = await api.getGuidesByTag({ TagId, skip, take: this.perPage })
      runInAction(() => {
        this.tagGuideList = !skip ? data : [...this.tagGuideList, ...data]
        this.canLoadTagGuideList = data.length >= this.perPage
      })
    } catch (error) {
      this.dataError()
    } finally {
      this.dataSuccess()
    }
  }
}

export default new GuideStore()
